import useQueryWithRefresh from 'hooks/helpers/useQueryWithRefresh/useQueryWithRefresh';
import { listOperatorTags } from 'services/ether/case-manager/operator';
import { ListOperatorEP } from 'services/ether/case-manager/operator/types';

const useListOperatorTags = (
    params: ListOperatorEP.Params.Base,
    queryOptions?: {
        enabled?: boolean;
    }
) => {
    const queryKey = ['operator-tags', 'list', params];

    const query = useQueryWithRefresh({
        queryKey,
        queryFn: ({ signal }) =>
            listOperatorTags({
                ...params,
                signal,
            }),
        ...queryOptions,
    });

    return query;
};

export default useListOperatorTags;
