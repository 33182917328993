import NameIdAggregator from 'components/datatable/NameIdAggregator';
import { Column } from 'primereact/column';
import {
    DataTableExpandedRows,
    DataTableProps,
    DataTableValueArray,
} from 'primereact/datatable';
import { ObjectDisplayModal } from 'components/ethercity-primereact';
import { MenuItem } from 'primereact/menuitem';
import { Menu } from 'primereact/menu';
import { useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { useLocalization } from 'hooks/context/useLocalization';
import { CMDataTableProps } from 'components/datatable/CMDataTable/types';
import CMDataTable from 'components/datatable/CMDataTable';
import { getFilterData } from 'utils/datatable';
import { useAuth } from 'hooks/context/useAuth';
import { FilterMatchMode } from 'primereact/api';
import TargetsCompanySummary from 'components/models/Operators/TargetsCompanySummary';
import OperatorsCompanySummary from 'components/models/Operators/OperatorsCompanySummary';

const CompanyMenu: React.FC<{
    company: Ether.CaseManager.Company;
    onShowDetail: (user: Ether.CaseManager.Company) => void;
    onEditCompany?: (company: Ether.CaseManager.Company) => void;
}> = ({ company, onEditCompany, onShowDetail }) => {
    const { permissions } = useAuth();
    const [localization] = useLocalization();

    const menuRef = useRef<Menu>(null);

    const menuItems: MenuItem[] = [
        ...(permissions.upsertCompanies && onEditCompany
            ? [
                  {
                      id: 'edit',
                      label: localization.components.common.button.edit,
                      command: () => onEditCompany(company),
                      //   command: () => navigate('edit-company/' + company._id),
                  },
              ]
            : []),
    ];

    if (menuItems.length <= 0) return null;
    return (
        <>
            <Menu popup ref={menuRef} model={menuItems} />
            <Button
                icon='pi pi-ellipsis-h'
                onClick={(e) => menuRef?.current?.toggle(e)}
            />
        </>
    );
};

type KeyInColumn = keyof CaseManagerApp.ModelColumns['company'];

const columnFields: { [key in KeyInColumn]: key } = {
    fantasy_name: 'fantasy_name',
    cnpj: 'cnpj',
    type: 'type',
    uf: 'uf',
    users_count: 'users_count',
};

export const companyFilters = {
    _cm_name_id: getFilterData(),
    [columnFields['type']]: getFilterData(FilterMatchMode.EQUALS),
};

const CompanyDataTable: React.FC<
    DataTableProps<Ether.CaseManager.Company[]> &
        Omit<CMDataTableProps, 'columnConfigName'> & {
            onEditCompany?: (company: Ether.CaseManager.Company) => void;
            onDeleteCompany?: (company: Ether.CaseManager.Company) => void;
            showOperatorCount?: boolean;
            authorizationConfigId?: string;
            blockOrder?: Ether.CaseManager.BlockOrder.Detailed;
            unblockOrder?: Ether.CaseManager.UnblockOrder.Detailed;
            showExpand?: 'operator' | 'target';
        }
> = ({
    onEditCompany,
    onDeleteCompany,
    showOperatorCount,
    authorizationConfigId,
    blockOrder,
    unblockOrder,
    showExpand,
    ...props
}) => {
    const [localization] = useLocalization();
    const { permissions } = useAuth();

    // TODO: this should be get from API
    const defaultTypeOptions = [
        { label: 'Operadora', value: 'operator' },
        { label: 'Entidade', value: 'entity' },
        { label: 'Parceiro', value: 'partner' },
        { label: 'Labs', value: 'labs' },
    ];

    const [viewCompanyDialogOptions, setViewCompanyDialogOptions] = useState<{
        visible: boolean;
        data: Ether.CaseManager.Company | null;
    }>({
        visible: false,
        data: null,
    });

    const [expandedRows, setExpandedRows] = useState<
        DataTableExpandedRows | DataTableValueArray | undefined
    >(undefined);

    const onCompanyViewModalShow = (data: Ether.CaseManager.Company) =>
        setViewCompanyDialogOptions({
            visible: true,
            data: data,
        });

    const onCompanyViewModalHide = () =>
        setViewCompanyDialogOptions((old) => ({
            ...old,
            visible: false,
        }));

    const datatableLocale = localization.components.models.company.datatable;

    const expandable = permissions.viewOperators;

    return (
        <>
            <ObjectDisplayModal
                header={viewCompanyDialogOptions.data?.fantasy_name}
                visible={viewCompanyDialogOptions.visible}
                displayData={viewCompanyDialogOptions.data}
                onHide={onCompanyViewModalHide}
                sortKeys={false}
            />
            <CMDataTable
                dataKey='_id'
                columnConfigName='company'
                lazy
                emptyMessage={datatableLocale.empty}
                filterOptions={{
                    _cm_name_id: {
                        placeholder: datatableLocale.selectName,
                        type: 'string',
                    },
                    [columnFields['type']]: {
                        label: localization.fields.company.type,
                        placeholder: datatableLocale.selectType,
                        type: 'dropdown',
                        selectOptions: defaultTypeOptions,
                    },
                }}
                expandedRows={showExpand ? expandedRows : undefined}
                onRowToggle={
                    showExpand ? (e) => setExpandedRows(e.data) : undefined
                }
                rowExpansionTemplate={(data: Ether.CaseManager.Company) =>
                    showExpand === 'target' ? (
                        <TargetsCompanySummary
                            companyId={data._id}
                            authorizationConfigId={authorizationConfigId}
                            blockOrder={blockOrder}
                            unblockOrder={unblockOrder}
                        />
                    ) : showExpand === 'operator' ? (
                        <OperatorsCompanySummary
                            companyId={data._id}
                            authorizationConfigId={authorizationConfigId}
                            blockOrderId={blockOrder?._id}
                            unblockOrderId={unblockOrder?._id}
                        />
                    ) : undefined
                }
                {...props}
            >
                {expandable && <Column expander={true} />}
                <Column
                    field='_cm_name_id'
                    body={(rowData: Ether.CaseManager.Company) => (
                        <NameIdAggregator
                            name={rowData.fantasy_name}
                            id={rowData._id}
                        />
                    )}
                />
                {showOperatorCount && (
                    <Column
                        field={columnFields['users_count']}
                        header={localization.fields.company.users}
                        body={(data: Ether.CaseManager.Company.Operator) =>
                            data.users_count != null ? data.users_count : '-'
                        }
                    />
                )}
                <Column
                    field={columnFields['type']}
                    header={localization.fields.company.type}
                    body={(rowData: Ether.CaseManager.Company) =>
                        defaultTypeOptions.find((t) => t.value === rowData.type)
                            ?.label ?? '-'
                    }
                />
                <Column
                    field={columnFields['cnpj']}
                    header={localization.fields.company.cnpj}
                    body={(data: Ether.CaseManager.Company) =>
                        data.cnpj ? data.cnpj : '-'
                    }
                />
                <Column
                    field={columnFields['uf']}
                    header={localization.fields.company.uf}
                    body={(data: Ether.CaseManager.Company) =>
                        data.uf ? data.uf.toLocaleUpperCase() : '-'
                    }
                />
                <Column
                    field='actions'
                    body={(rowData: Ether.CaseManager.Company) => (
                        <CompanyMenu
                            company={rowData}
                            onShowDetail={onCompanyViewModalShow}
                        />
                    )}
                />
            </CMDataTable>
        </>
    );
};

export default CompanyDataTable;
