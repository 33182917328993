import useQueryWithRefresh from 'hooks/helpers/useQueryWithRefresh/useQueryWithRefresh';
import { getTargetHistory } from 'services/ether/case-manager/targets';
import { GetTargetHistoryEP } from 'services/ether/case-manager/targets/types';

const useGetTargetHistory = (
    targetId: string,
    queryOptions?: {
        enabled?: boolean;
    }
) => {
    const queryKey = ['target', 'history', targetId];

    const query = useQueryWithRefresh<
        GetTargetHistoryEP.Result | null,
        Error
    >({
        queryKey,
        queryFn: ({ signal }) =>
            getTargetHistory(
                {
                    target_id: targetId,
                },
                signal
            ),
        ...queryOptions,
    });

    return query;
};

export default useGetTargetHistory;
