import api from 'services/ether/api';
import {
    DetailOperatorCompanyEP,
    ListCompanyEP,
    UpsertCompanyEP,
} from './types';
import {
    mapDevFilters,
    parseDataTableFilterMetaForAPI,
} from 'services/ether/utils';
import { listBase } from 'services/ether/base';
import _ from 'lodash';

export const listCompany = ({
    project_id,
    options,
    signal,
}: ListCompanyEP.Params.Many) => {
    const devFilters = options?.devFilters ?? {};
    const devKeys: ListCompanyEP.Filters.Map = {
        _id: ['_id', devFilters._id],
    };
    const mappedFilters = mapDevFilters(devKeys);
    const filters = _.merge(
        {
            project_id,
        },
        options?.rawFilters,
        mappedFilters
    );
    return listBase<Ether.CaseManager.Company[]>({
        endpoint: '/list-company',
        handleParams: parseDataTableFilterMetaForAPI,
        options: {
            ...options,
            filters,
        },
        signal,
    });
};

export const listOneCompany = async ({
    _id,
    project_id,
    signal,
}: ListCompanyEP.Params.One) => {
    const { payload } = await listBase<Ether.CaseManager.Company[]>({
        endpoint: '/list-company',
        handleParams: parseDataTableFilterMetaForAPI,
        options: {
            filters: {
                _id,
                project_id,
            },
        },
        signal,
    });
    return payload[0] ?? null;
};

export const detailOperatorCompany = async ({
    project_id,
    options,
    signal,
}: DetailOperatorCompanyEP.Params.Many) => {
    const devFilters = options?.devFilters ?? {};
    const devKeys: DetailOperatorCompanyEP.Filters.Map = {
        _id: ['_id', devFilters._id],
        authorization_config_id: [
            'authorization_config_id',
            devFilters.authorization_config_id,
        ],
        block_order_id: ['block_order_id', devFilters.block_order_id],
        unblock_order_id: ['unblock_order_id', devFilters.unblock_order_id],
    };
    const mappedFilters = mapDevFilters(devKeys);
    const filters = _.merge(
        {
            project_id,
        },
        options?.rawFilters,
        mappedFilters
    );
    return listBase<Ether.CaseManager.Company.Operator[]>({
        endpoint: '/detail-operator-company',
        handleParams: parseDataTableFilterMetaForAPI,
        options: {
            ...options,
            filters,
        },
        signal,
    });
};

const createFormData = (data: UpsertCompanyEP.UpsertData) => {
    const formData = new FormData();
    const registration_documents: Record<
        string,
        string | Ether.CaseManager.CompanyFile
    > = {};

    Object.entries(data).forEach(([k, v]) => {
        if (typeof v !== 'string' || v === '') return;
        formData.append(k, v);
    });
    formData.append(
        'financial_responsible',
        JSON.stringify(data.financial_responsible)
    );

    Object.entries(data.registration_documents).forEach(
        ([filename, file], index) => {
            if (file instanceof Blob) {
                const key = `file_${index}`;
                registration_documents[filename] = key;
                formData.append(key, file);
            } else {
                registration_documents[filename] = file;
            }
        }
    );
    formData.append(
        'registration_documents',
        JSON.stringify(registration_documents)
    );
    return formData;
};

export const upsertCompany = (data: UpsertCompanyEP.UpsertData) => {
    const formData = createFormData(data);
    return new Promise<UpsertCompanyEP.Result>((resolve, reject) => {
        api.post<Ether.ApiResponse<UpsertCompanyEP.ApiResponse>>(
            '/upsert-company',
            formData
        )
            .then((res) => {
                const payload = res.data.payload;
                const data = payload[0];
                if (!data)
                    reject(new Error('upsert-company failed to return data'));
                else if ('error' in data) reject(new Error(data.error));
                else resolve(data as UpsertCompanyEP.Result);
            })
            .catch((err) => reject(err));
    });
};
