import { Link } from 'react-router-dom';
import './style.css';

const NameIdAggregator: React.FC<{
    name: string;
    id: string;
    association?: string;
    navigateTo?: string;
    onClick?: () => void;
}> = ({ name, id, association, navigateTo, onClick }) => {
    const BaseElement = () => {
        return (
            <div
                style={{
                    display: 'flex',
                    gap: '4px',
                    flexDirection: 'column',
                }}
                className={[
                    'name-id-aggregator',
                    'flex',
                    'flex-col',
                    'gap-2',
                    onClick ? 'hoverable' : undefined,
                ].join(' ')}
                onClick={onClick}
            >
                <span
                    style={{
                        fontWeight: 'bold',
                        fontSize: '14px',
                        color: 'var(--light-pale-grey)',
                    }}
                >
                    {name}
                </span>
                <span>{id}</span>
                {association && (
                    <span
                        style={{
                            fontSize: '14px',
                            fontWeight: 'bold',
                        }}
                    >
                        {association}
                    </span>
                )}
            </div>
        );
    };

    if (navigateTo)
        return (
            <Link to={navigateTo} target='_blank' rel='noopener noreferrer'>
                <BaseElement />
            </Link>
        );
    return <BaseElement />;
};

export default NameIdAggregator;
