import { DataTableSortMeta } from 'primereact/datatable';
import api from 'services/ether/api';
import {
    mapDevFilters,
    parseDataTableFilterMetaForAPI,
} from 'services/ether/utils';
import {
    InsertUserEP,
    RemoveUser,
    ListUserEP,
    EditUserEP,
    GroupUserProfilesEP,
} from './types';
import { listBase } from 'services/ether/base';
import { ParamFiltersMeta } from 'services/ether/types';
import _ from 'lodash';

const handleDataTableFilterMeta = ({
    filters,
    sort,
}: {
    filters?: ParamFiltersMeta;
    sort?: DataTableSortMeta | null;
}) => {
    const parsedFilters = parseDataTableFilterMetaForAPI({
        filters,
        sort,
        nameField: 'username',
    });
    if (parsedFilters['data.auth.level']) {
        const value = parsedFilters['data.auth.level'];
        if (value === '@no_role') {
            parsedFilters['data.auth.level|isnull'] = 'true';
        } else parsedFilters['data.auth.level'] = value;
    }
    return parsedFilters;
};

export const _listUsers = ({ options, signal }: ListUserEP.Params.Many) => {
    const devFilters = options?.devFilters ?? {};
    const devKeys: ListUserEP.Filters.Map = {
        _id: ['_id', devFilters._id],
        group_id: ['group_id', devFilters.group_id],
    };
    const mappedFilters = mapDevFilters(devKeys);
    const filters = _.merge({}, options?.rawFilters, mappedFilters);
    return listBase<Ether.CaseManager.User[]>({
        endpoint: '/list-users',
        handleParams: handleDataTableFilterMeta,
        options: {
            ...options,
            filters,
        },
        signal,
    });
};

export const listUsers = ({ options, signal }: ListUserEP.Params.Many) => {
    return _listUsers({
        options,
        signal,
    });
};

export const listOneUser = async ({ _id, signal }: ListUserEP.Params.One) => {
    const { payload } = await _listUsers({
        options: {
            devFilters: {
                _id,
            },
        },
        signal,
    });
    return payload[0] ?? null;
};

export const _groupUserProfiles = ({
    options,
    signal,
}: GroupUserProfilesEP.Params.Many) => {
    // const devFilters = options?.devFilters ?? {};
    const devKeys: GroupUserProfilesEP.Filters.Map = {};
    const mappedFilters = mapDevFilters(devKeys);
    const filters = _.merge({}, options?.rawFilters, mappedFilters);
    return listBase<Ether.CaseManager.User.GroupedProfile[]>({
        endpoint: '/group-user-profiles',
        handleParams: handleDataTableFilterMeta,
        options: {
            ...options,
            filters,
        },
        signal,
    });
};

export const groupManyUserProfiles = ({
    options,
    signal,
}: GroupUserProfilesEP.Params.Many) => {
    return _groupUserProfiles({
        options,
        signal,
    });
};

export const updateUserRole = (
    userId: string,
    userRole: Ether.CaseManager.Role | null
) => {
    return new Promise<void>((resolve, reject) => {
        api.post(`/update-user-level/${userId}`, {
            level: userRole,
        })
            .then(() => resolve())
            .catch((err) => reject(err));
    });
};

export const insertUser = (data: InsertUserEP.Data) => {
    return new Promise<InsertUserEP.Result>((resolve, reject) => {
        api.post<Ether.ApiResponse<InsertUserEP.ApiResponse>>(
            '/upsert-user',
            data
        )
            .then((res) => {
                const payload = res.data.payload;
                const data = payload[0];
                if (!data)
                    reject(new Error('upsert-user failed to return data'));
                else if (typeof data !== 'string' && 'error' in data)
                    reject(new Error(data.error));
                else resolve(data);
            })
            .catch((err) => reject(err));
    });
};

export const editUser = (data: EditUserEP.Data) => {
    return new Promise<EditUserEP.Result>((resolve, reject) => {
        api.post<Ether.ApiResponse<EditUserEP.ApiResponse>>(
            '/upsert-user',
            data
        )
            .then((res) => {
                const payload = res.data.payload;
                const data = payload[0];
                if (!data)
                    reject(new Error('upsert-user failed to return data'));
                else if (typeof data !== 'string' && 'error' in data)
                    reject(new Error(data.error));
                else resolve(data);
            })
            .catch((err) => reject(err));
    });
};

export const removeUser = (user_id: string) => {
    return new Promise<RemoveUser.Result>((resolve, reject) => {
        api.post<Ether.ApiResponse<RemoveUser.ApiResponse>>(
            `/remove-user/${user_id}`
        )
            .then((res) => {
                const payload = res.data.payload;
                const data = payload;
                if (data == null)
                    reject(new Error('remove-user failed to return data'));
                else if (typeof data !== 'boolean' && 'error' in data)
                    reject(new Error(data.error));
                else resolve(data);
            })
            .catch((err) => reject(err));
    });
};
