import GoBackButton from 'components/misc/GoBackButton';
import UnblockOrderDataTable, {
    unblockOrderFields,
    unblockOrderFilters,
} from 'components/models/UnblockOrder/UnblockOrderDataTable';
import CMBreadCrumb from 'components/page/CMBreadCrumb';
import CacheControl from 'controller/cache/cacheController';
import useDetailUnblockOrder from 'hooks/queries/unblock-order/useDetailUnblockOrder';
import { useAuth } from 'hooks/context/useAuth';
import useInitDataTableState from 'hooks/helpers/useInitDataTableState';
import { useLocalization } from 'hooks/context/useLocalization';
import { useProject } from 'hooks/context/project/useProject';
import { SortOrder } from 'primereact/api';
import { Button } from 'primereact/button';
import useUpsertUnblockOrderDialog from 'hooks/models/unblockOrder/useUpsertUnblockOrderDialog';

const ListUnblockOrders = () => {
    const { permissions } = useAuth();
    const [localization] = useLocalization();
    const project = useProject();

    const { UpsertDialog, showCreate } = useUpsertUnblockOrderDialog();

    const { filters, setFilters, pageOptions, setPageOptions, sort, setSort } =
        useInitDataTableState({
            filters: unblockOrderFilters,
            sort: {
                field: unblockOrderFields['created_at'],
                order: SortOrder.DESC,
            },
            pageOptions: {
                startingRow:
                    CacheControl.UserConfig.get()['paginatorRows']
                        ?.unblockOrder,
            },
        });

    const unblockOrdersQuery = useDetailUnblockOrder({
        project_id: project._id,
        options: {
            rawFilters: filters,
            limit: pageOptions.rows,
            offset: (pageOptions.page - 1) * pageOptions.rows,
            sort: sort,
        },
    });

    return (
        <section>
            <UpsertDialog />
            <CMBreadCrumb projectLabel={project.name} />
            <GoBackButton />
            <div className='flex flex-row justify-between'>
                <h2>
                    {project.name} - {localization.models.unblockOrder.plural}
                </h2>
                <div className='flex flex-col justify-center'>
                    {permissions.insertUnblockOrders && (
                        <Button
                            className='ml-auto'
                            label={
                                localization.components.models.unblockOrder
                                    .button.new
                            }
                            icon='pi pi-plus'
                            onClick={showCreate}
                        />
                    )}
                </div>
            </div>
            <UnblockOrderDataTable
                loading={unblockOrdersQuery.isFetching}
                value={unblockOrdersQuery.data?.payload}
                filters={filters}
                setFilters={(e) => setFilters(e)}
                sortField={sort?.field}
                sortOrder={sort?.order}
                onSort={(e) =>
                    setSort({
                        field: e.sortField,
                        order: e.sortOrder,
                    })
                }
                paginatorProps={{
                    page: pageOptions.page,
                    rows: pageOptions.rows,
                    onPageChange: (options) => setPageOptions(options),
                    disableNext:
                        (unblockOrdersQuery.data?.payload.length ?? 9999) <
                        pageOptions.rows,
                    onRefresh: unblockOrdersQuery.refresh,
                }}
            />
        </section>
    );
};

export default ListUnblockOrders;
