import useQueryWithRefresh from 'hooks/helpers/useQueryWithRefresh/useQueryWithRefresh';
import { listCompany } from 'services/ether/case-manager/company';
import { ListCompanyEP } from 'services/ether/case-manager/company/types';

const useListCompany = (
    params: ListCompanyEP.Params.Many,
    queryOptions?: {
        enabled?: boolean;
    }
) => {
    const queryKey = ['company', 'list', params];

    const query = useQueryWithRefresh({
        queryKey,
        queryFn: ({ signal }) =>
            listCompany({
                ...params,
                signal,
            }),
        ...queryOptions,
    });

    return query;
};

export default useListCompany;
