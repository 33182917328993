import LightDialog from 'components/display/LightDialog';
import EvidenceMetadataForm from 'components/models/Evidences/EvidenceMetadataForm';
import { EvidenceMetadataFormData } from 'components/models/Evidences/EvidenceMetadataForm/types';
import { useLocalization } from 'hooks/context/useLocalization';
import { Button } from 'primereact/button';
import { useCallback, useMemo, useState } from 'react';

const useFillEvidenceMetadataDialog = ({
    onFormSubmit,
}: {
    onFormSubmit: (data: EvidenceMetadataFormData) => void;
}) => {
    const [localization] = useLocalization();

    const [form, setForm] = useState<EvidenceMetadataFormData>({});

    const [chosenConfig, setChosenConfig] =
        useState<Ether.CaseManager.AuthorizationConfig | null>(null);

    const show = useCallback(
        (config: Ether.CaseManager.AuthorizationConfig) => {
            const form = config.evidences_metadata_form;
            if (form) {
                const newForm: EvidenceMetadataFormData = {};
                form.forEach((item) => {
                    newForm[item.key] = item.type === 'date' ? null : '';
                });
                setForm(newForm);
            }
            setChosenConfig(config);
        },
        []
    );
    const hide = useCallback(() => setChosenConfig(null), []);
    const onSubmit = useCallback(() => {
        onFormSubmit(form);
        hide();
    }, [form, hide, onFormSubmit]);

    const dialog = useMemo(() => {
        const isFormOk = Object.entries(form).every(([key, value]) => {
            if (!chosenConfig) return false;
            const configForm = chosenConfig.evidences_metadata_form;
            const configItem = configForm?.find((item) => item.key === key);
            if (!configItem?.required) return true;
            return configItem.required && !!value && value !== '';
        });
        return (
            chosenConfig && (
                <LightDialog
                    visible={true}
                    onHide={hide}
                    header={
                        localization.components.models.evidence.views
                            .metadataForm.title
                    }
                    footer={
                        <div className='flex flex-row justify-end w-full'>
                            <Button
                                label={
                                    localization.components.common.button.cancel
                                }
                                severity='danger'
                                onClick={hide}
                            />
                            <Button
                                label={
                                    localization.components.common.button
                                        .confirm
                                }
                                severity='success'
                                onClick={onSubmit}
                                disabled={!isFormOk}
                            />
                        </div>
                    }
                >
                    <EvidenceMetadataForm
                        authorizationConfig={chosenConfig}
                        formData={form}
                        setFormData={setForm}
                    />
                </LightDialog>
            )
        );
    }, [form, hide, chosenConfig, onSubmit, localization]);

    return {
        dialog,
        show,
    };
};

export default useFillEvidenceMetadataDialog;
