import { useLocalization } from '../../context/useLocalization';
import { useCallback, useMemo, useState } from 'react';
import useDuplicateAuthorization from 'hooks/mutations/authorization/useDuplicateAuthorization';
import { Button } from 'primereact/button';
import { DuplicateAuthorizationEP } from 'services/ether/case-manager/authorizations/types';
import SelectOneAuthorizationConfig from 'components/models/AuthorizationConfig/SelectOneAuthorizationConfig';
import SelectOneAuthorizationFlow from 'components/models/AuthorizationFlow/SelectOneAuthorizationFlow';
import { InputText } from 'components/ethercity-primereact';
import LightDialog from 'components/display/LightDialog';

type PartialData = { authorization: Ether.CaseManager.Authorization };

const DuplicateAuthorizationDialog: React.FC<{
    data: PartialData;
    isLoading?: boolean;
    onRespond: (data: DuplicateAuthorizationEP.Data) => void;
    onHide: () => void;
}> = ({ data, onRespond, isLoading, onHide }) => {
    const [localization] = useLocalization();

    type Form = {
        name: string;
        selectedFlowId: string | null;
        selectedConfigId: string | null;
    };

    type ValidatedForm = Form & {
        selectedFlowId: string;
        selectedConfigId: string;
    };

    const [formData, setFormData] = useState<Form>({
        name: '',
        selectedFlowId: data?.authorization.authorization_flow_id,
        selectedConfigId: data?.authorization.authorization_config_id,
    });

    const assertNotDuplicated = useCallback(
        (value: string | undefined) => value !== data.authorization.name,
        [data.authorization.name]
    );

    const validateForm = useCallback(
        (form: typeof formData): form is ValidatedForm => {
            if (form.name === '') return false;
            if (!assertNotDuplicated(form.name)) return false;
            if (!form.selectedConfigId) return false;
            if (!form.selectedFlowId) return false;
            return true;
        },
        [assertNotDuplicated]
    );

    const submitData = useCallback(
        (form: typeof formData) => {
            if (!validateForm(form) || !data) return;
            onRespond({
                authorization_id: data.authorization._id,
                project_id: data.authorization.project_id,
                name: form.name,
                authorization_config_id: form.selectedConfigId,
                authorization_flow_id: form.selectedFlowId,
            });
        },
        [data, onRespond, validateForm]
    );

    const isFormOk = validateForm(formData);
    const componentLocale =
        localization.components.models.authorization.views.duplicate;

    return (
        <LightDialog
            visible={true}
            onHide={onHide}
            header={componentLocale.header}
            footer={
                <>
                    <Button
                        label={localization.components.common.button.cancel}
                        severity='danger'
                        onClick={onHide}
                        disabled={isLoading}
                    />
                    <Button
                        label={localization.components.common.button.save}
                        severity='success'
                        onClick={() => submitData(formData)}
                        disabled={isLoading || !isFormOk}
                        loading={isLoading}
                    />
                </>
            }
        >
            <div className='flex flex-col gap-2 items-center'>
                <span>
                    {componentLocale.description.replace(
                        '{name}',
                        data.authorization.name
                    )}
                </span>
                <strong>{localization.common.cannotBeUndone}</strong>
                <div className='w-full'>
                    <InputText
                        wrapperStyle={{ width: '100%' }}
                        required
                        label={localization.fields.authorization.name}
                        value={formData.name}
                        onChange={(e) =>
                            setFormData((old) => ({
                                ...old,
                                name: e.target.value,
                            }))
                        }
                        validations={[
                            {
                                validate: (t) => t !== '',
                                validationError:
                                    localization.validations.generic
                                        .requiredName,
                            },
                            {
                                validate: assertNotDuplicated,
                                validationError:
                                    localization.validations.generic
                                        .nameMustBeDifferent,
                            },
                        ]}
                    />
                    <SelectOneAuthorizationFlow
                        required
                        value={formData.selectedFlowId}
                        onChange={(f) =>
                            setFormData((old) => ({
                                ...old,
                                selectedFlowId: f?._id ?? null,
                            }))
                        }
                    />
                    <SelectOneAuthorizationConfig
                        required
                        value={formData.selectedConfigId}
                        onChange={(c) =>
                            setFormData((old) => ({
                                ...old,
                                selectedConfigId: c?._id ?? null,
                            }))
                        }
                    />
                </div>
            </div>
        </LightDialog>
    );
};

const useDuplicateAuthorizationDialog = (options?: {
    onSuccess?: () => void;
}) => {
    const { onSuccess: onSuccessParam } = options ?? {};

    const [visible, setVisible] = useState<null | PartialData>(null);
    const show = (data: PartialData) => setVisible(data);
    const hide = () => setVisible(null);

    const onSuccess = () => {
        if (onSuccessParam) onSuccessParam();
        hide();
    };

    const { duplicateAuthorization, isLoading, error } =
        useDuplicateAuthorization({
            onSuccess,
        });

    const Dialog = useMemo(
        () =>
            visible && (
                <DuplicateAuthorizationDialog
                    data={visible}
                    onRespond={(data) => duplicateAuthorization(data)}
                    isLoading={isLoading}
                    onHide={hide}
                />
            ),
        [duplicateAuthorization, isLoading, visible]
    );

    return {
        dialog: Dialog,
        show: (data: { authorization: Ether.CaseManager.Authorization }) =>
            show(data),
        isLoading: isLoading,
        error: error,
    };
};

export default useDuplicateAuthorizationDialog;
