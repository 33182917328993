import LightDialog from 'components/display/LightDialog';
import FileViewer, {
    FileViewerProps,
} from 'components/models/Evidences/FileViewer';
import FileViewerWrapper from 'components/models/Evidences/FileViewerWrapper';
import { useMemo, useState } from 'react';
import { getFileModelName } from 'utils/file';

type RelevantProps = Omit<
    FileViewerProps,
    'item' | 'mode' | 'token' | 'showCloseButton' | 'onClose'
>;

type Options =
    | {
          token: string | undefined;
      } & (
          | {
                modelId: string;
                header: string;
            }
          | {
                data:
                    | Ether.CaseManager.Document.Detailed
                    | Ether.CaseManager.Evidence.Detailed;
            }
      );

const useFileViewerDialog = (props?: RelevantProps) => {
    const [options, setOptions] = useState<
        null | (Options & { mode: 'document' | 'evidence' })
    >(null);

    const hide = () => setOptions(null);
    const show = (o: Options & { mode: 'document' | 'evidence' }) =>
        setOptions(o);
    const showDocument = (o: Options) => {
        show({
            ...o,
            mode: 'document',
        });
    };
    const showEvidence = (o: Options) => {
        show({
            ...o,
            mode: 'evidence',
        });
    };

    const imageStyles = useMemo(
        () =>
            props?.imageStyles ?? {
                maxWidth: '70vw',
            },
        [props?.imageStyles]
    );

    const dialog = useMemo(
        () =>
            options && (
                <LightDialog
                    visible={true}
                    onHide={hide}
                    header={
                        'header' in options
                            ? options.header
                            : getFileModelName(options.data)
                    }
                >
                    {'modelId' in options ? (
                        <FileViewerWrapper
                            modelId={options.modelId}
                            mode={options.mode}
                            token={options.token}
                            showCloseButton
                            onClose={hide}
                            {...props}
                            imageStyles={imageStyles}
                        />
                    ) : (
                        <FileViewer
                            item={options.data}
                            mode={options.mode}
                            token={options.token}
                            showCloseButton
                            onClose={hide}
                            {...props}
                            imageStyles={imageStyles}
                        />
                    )}
                </LightDialog>
            ),
        [options, props, imageStyles]
    );

    return {
        dialog,
        showDocument,
        showEvidence,
    };
};

export default useFileViewerDialog;
