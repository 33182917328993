import { useMemo, useRef, useState } from 'react';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import { TabMenu } from 'primereact/tabmenu';
import { SortOrder } from 'primereact/api';
import { Link } from 'react-router-dom';

import DisplayCountBox from 'components/display/DisplayCountBox';
import LoadingMessage from 'components/misc/LoadingMessage';

import { useAuth } from 'hooks/context/useAuth';
import useMetricsQuery from 'hooks/queries/metrics/useMetricsQuery';
import { useProject } from 'hooks/context/project/useProject';
import { useLocalization } from 'hooks/context/useLocalization';
import { getUserDisplayName } from 'utils/user';

import useDetailRecentBlockOrder from 'hooks/queries/block-order/useDetailRecentBlockOrder';
import useDetailRecentAuthorization from 'hooks/queries/authorization/useQueryDetailRecentAuthorization';

import ViewTablesWithTabs from 'components/display/ViewTablesWithTabs';
import { TableWithTabView } from 'components/display/ViewTablesWithTabs/types';

import AuthorizationDataTable from 'components/models/Authorization/AuthorizationDataTable';
import {
    detailManyAuthorization,
    detailManyAuthorizationGroupedByConfig,
} from 'services/ether/case-manager/authorizations';

import AuthorizationConfigDataTable from 'components/models/AuthorizationConfig/AuthorizationConfigDataTable';
import { detailManyAuthorizationConfig } from 'services/ether/case-manager/authorization-config';
import useUpsertAuthorizationDialog from 'hooks/models/authorization/useUpsertAuthorizationDialog';

import BlockOrderDataTable from 'components/models/BlockOrder/BlockOrderDataTable';
import useUpsertBlockOrderDialog from 'hooks/models/blockOrder/useUpsertBlockOrderDialog';

import DocumentDataTable from 'components/models/Document/DocumentDataTable';
import { detailManyDocuments } from 'services/ether/case-manager/documents';
import useInsertDocumentDialog from 'hooks/dialogs/document/useInsertDocumentDialog';

import TagDataTable from 'components/models/Tag/TagDataTable';
import { detailManyTags } from 'services/ether/case-manager/tags';

import TargetsDataTable from 'components/models/Targets/TargetsDataTable';
import { detailManyTargets } from 'services/ether/case-manager/targets';
import useCreateOperationDialog from 'hooks/models/operation/useCreateOperationDialog';

// const ShowChart: React.FC<{
//     title: string;
//     result: string;
//     resultLabel: string;
// }> = ({ title, result, resultLabel }) => {
//     return (
//         <div>
//             <span className='text-sm'>{title.toLocaleUpperCase()}</span>
//             <div className='flex flex-row gap-4 items-center'>
//                 <div className='flex flex-col text-justify items-center gap-4'>
//                     <strong className='text-3xl'>{result}</strong>
//                     <span>{resultLabel.toLocaleUpperCase()}</span>
//                 </div>
//                 <FakeChartSvg />
//             </div>
//         </div>
//     );
// };

// const SimpleBox: React.FC<{
//     topText: string;
//     bottomText: string;
//     lastText?: string;
// }> = ({ topText, bottomText, lastText }) => {
//     return (
//         <div className='flex flex-col justify-start items-center text-center w-full'>
//             <span className='text-base'>{topText.toLocaleUpperCase()}</span>
//             <strong className='text-3xl'>{bottomText}</strong>
//             {lastText && <span className='text-sm'>{lastText}</span>}
//         </div>
//     );
// };

const AuthorizationDashboard: React.FC<{
    hideTitle?: boolean;
    hideCount?: boolean;
}> = ({ hideTitle, hideCount }) => {
    const project = useProject();
    const [localization] = useLocalization();
    const { permissions, user } = useAuth();
    const { element: UpsertDialog, showCreate } =
        useUpsertAuthorizationDialog();

    const refetchAll = () => {
        pendingAuthorizations.refetch();
        approvedAuthorizations.refetch();
        metricsQuery.refetch();
    };

    const viewDraft = !user.role?.startsWith('authorizer');

    const [selectedMenu, setSelectedMenu] = useState<
        'pending' | 'approved' | 'draft'
    >(viewDraft ? 'draft' : 'pending');

    const pendingAuthorizations = useDetailRecentAuthorization(
        project._id,
        'pending'
    );
    const approvedAuthorizations = useDetailRecentAuthorization(
        project._id,
        'approved'
    );
    const draftAuthorizations = useDetailRecentAuthorization(
        project._id,
        'draft',
        {
            enabled: viewDraft,
        }
    );

    const metricsQuery = useMetricsQuery(project._id);

    const pageLocale = localization.components.views.homeDashboard;
    const authorizationBadge =
        localization.components.models.authorization.badge.status;

    return (
        <section>
            <UpsertDialog />
            {!hideTitle && <h3>{localization.models.authorization.plural}</h3>}
            {!hideCount && (
                <div>
                    {metricsQuery.isLoading ? (
                        <h4>Loading metrics...</h4>
                    ) : (
                        <div className='flex flex-start gap-2'>
                            <DisplayCountBox
                                label={localization.models.authorization.plural}
                                count={
                                    metricsQuery.data?.total_authorizations ?? 0
                                }
                            />
                            {viewDraft && (
                                <DisplayCountBox
                                    label={authorizationBadge.draft}
                                    count={
                                        metricsQuery.data
                                            ?.authorization_status_draft ?? 0
                                    }
                                />
                            )}
                            <DisplayCountBox
                                label={authorizationBadge.pending}
                                count={
                                    metricsQuery.data
                                        ?.authorization_status_pending ?? 0
                                }
                            />
                            <DisplayCountBox
                                label={authorizationBadge.approved}
                                count={
                                    metricsQuery.data
                                        ?.authorization_status_approved ?? 0
                                }
                            />
                        </div>
                    )}
                </div>
            )}
            <div className='flex justify-between items-center gap-2'>
                <h3>{pageLocale.recentAuthorizations}</h3>
                {permissions.insertAuthorizations && (
                    <Button
                        label={
                            localization.components.models.authorization.button
                                .new
                        }
                        icon='pi pi-plus'
                        onClick={showCreate}
                        style={{ marginLeft: 'auto' }}
                    />
                )}
                <Link to='/authorizations'>
                    <Button
                        label={
                            localization.components.models.authorization.button
                                .viewAll
                        }
                    />
                </Link>
            </div>
            <TabMenu
                model={[
                    ...(viewDraft
                        ? [
                              {
                                  label:
                                      authorizationBadge.draft +
                                      (metricsQuery.data
                                          ? ` (${
                                                metricsQuery.data
                                                    ?.authorization_status_draft ??
                                                0
                                            })`
                                          : ''),
                                  command: () => setSelectedMenu('draft'),
                              },
                          ]
                        : []),
                    {
                        label:
                            authorizationBadge.pending +
                            (metricsQuery.data
                                ? ` (${
                                      metricsQuery.data
                                          ?.authorization_status_pending ?? 0
                                  })`
                                : ''),
                        command: () => setSelectedMenu('pending'),
                    },
                    {
                        label:
                            localization.components.models.authorization.badge
                                .status.approved +
                            (metricsQuery.data
                                ? ` (${
                                      metricsQuery.data
                                          ?.authorization_status_approved ?? 0
                                  })`
                                : ''),
                        command: () => setSelectedMenu('approved'),
                    },
                ]}
            />
            <AuthorizationDataTable
                value={
                    (selectedMenu === 'pending'
                        ? pendingAuthorizations.data
                        : selectedMenu === 'draft'
                        ? draftAuthorizations.data
                        : approvedAuthorizations.data
                    )?.payload
                }
                onRespond={refetchAll}
                showApproved={selectedMenu === 'approved'}
                loading={
                    pendingAuthorizations.isLoading ||
                    draftAuthorizations.isLoading ||
                    approvedAuthorizations.isLoading
                }
            />
        </section>
    );
};

const BlockOrderDashboard: React.FC<{
    hideTitle?: boolean;
    hideCount?: boolean;
}> = ({ hideTitle, hideCount }) => {
    const project = useProject();
    const { permissions, user } = useAuth();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [localization] = useLocalization();
    const { UpsertDialog, showCreate } = useUpsertBlockOrderDialog();
    const { element: CreateOperationDialog, show: showCreateOperationDialog } =
        useCreateOperationDialog();

    const showDraft = user.role !== 'operator';

    const doneBlockOrders = useDetailRecentBlockOrder(project._id, 'done');
    const draftBlockOrders = useDetailRecentBlockOrder(
        project._id,
        'draft',
        showDraft
    );

    const metricsQuery = useMetricsQuery(project._id);

    const pageLocale = localization.components.views.homeDashboard;

    return (
        <section>
            <CreateOperationDialog />
            <UpsertDialog />
            {!hideTitle && <h3>{localization.models.blockOrder.plural}</h3>}
            {!hideCount && (
                <div>
                    {metricsQuery.isLoading ? (
                        <h4>{pageLocale.metricsLoading}</h4>
                    ) : (
                        <div className='flex items-start gap-2'>
                            <DisplayCountBox
                                label={localization.models.blockOrder.plural.toLocaleUpperCase()}
                                count={
                                    metricsQuery.data?.total_block_orders ?? 0
                                }
                            />
                            <DisplayCountBox
                                label={localization.components.models.blockOrder.badge.status.doneWithName.toLocaleUpperCase()}
                                count={
                                    metricsQuery.data
                                        ?.block_order_status_done ?? 0
                                }
                            />
                            {showDraft && (
                                <DisplayCountBox
                                    label={localization.components.models.blockOrder.badge.status.draftWithName.toLocaleUpperCase()}
                                    count={
                                        metricsQuery.data
                                            ?.block_order_status_draft ?? 0
                                    }
                                />
                            )}
                        </div>
                    )}
                </div>
            )}
            <div className='flex gap-2 justify-between items-center'>
                <h3>{pageLocale.recentBlockOrders}</h3>
                {permissions.insertBlockOrders && (
                    <Button
                        label={
                            localization.components.models.blockOrder.button.new
                        }
                        icon='pi pi-plus'
                        onClick={() => showCreate()}
                        style={{ marginLeft: 'auto' }}
                    />
                )}
                {permissions.insertOperation &&
                    user.role === 'authorizer-user' && (
                        <Button
                            icon='pi pi-plus'
                            label={
                                localization.components.models.operation.button
                                    .new
                            }
                            onClick={showCreateOperationDialog}
                        />
                    )}
                <Link to='/block-orders'>
                    <Button
                        label={
                            localization.components.models.blockOrder.button
                                .viewAll
                        }
                    />
                </Link>
            </div>
            <TabMenu
                activeIndex={selectedIndex}
                onTabChange={(e) => setSelectedIndex(e.index)}
                model={[
                    {
                        label:
                            localization.components.models.blockOrder.badge
                                .status.doneWithName +
                            (metricsQuery.data && !hideCount
                                ? ` (${
                                      metricsQuery.data
                                          ?.block_order_status_done ?? 0
                                  })`
                                : ''),
                    },
                    ...(showDraft
                        ? [
                              {
                                  label:
                                      localization.components.models.blockOrder
                                          .badge.status.draftWithName +
                                      (metricsQuery.data && !hideCount
                                          ? ` (${
                                                metricsQuery.data
                                                    ?.block_order_status_draft ??
                                                0
                                            })`
                                          : ''),
                              },
                          ]
                        : []),
                ]}
            />
            <BlockOrderDataTable
                value={
                    (selectedIndex === 0
                        ? doneBlockOrders.data
                        : draftBlockOrders.data
                    )?.payload
                }
                loading={
                    draftBlockOrders.isFetching || doneBlockOrders.isFetching
                }
            />
        </section>
    );
};

const AdminHome = () => {
    const [localization] = useLocalization();
    const project = useProject();
    const menuRef = useRef<Menu>(null);
    const metricsQuery = useMetricsQuery(project._id);

    const {
        dialog: insertDocumentDialog,
        showInsert: showInsertDocument,
        showEdit: showEditDocument,
    } = useInsertDocumentDialog({
        type: 'document',
    });

    const {
        dialog: insertOfficialDocDialog,
        showInsert: showInsertOfficialDoc,
        showEdit: showEditOfficialDoc,
    } = useInsertDocumentDialog({
        type: 'official_document',
    });

    const views: TableWithTabView[] = useMemo(
        () => [
            {
                fetchData: (params) =>
                    detailManyAuthorizationConfig({
                        project_id: project._id,
                        options: {
                            ...params.options,
                        },
                        signal: params.signal,
                    }),
                label: localization.models.authorizationConfig.plural,
                totalCount: metricsQuery.data?.total_authorization_configs ?? 0,
                tableElement: AuthorizationConfigDataTable,
                model: 'authorizationConfig',
            },
            {
                fetchData: (params) =>
                    detailManyDocuments({
                        project_id: project._id,
                        options: {
                            ...params.options,
                            devFilters: {
                                category: 'official_document',
                            },
                        },
                        signal: params.signal,
                    }),
                label: localization.models.oficio.plural,
                totalCount: metricsQuery.data?.total_official_documents ?? 0,
                tableElement: (props) => (
                    <DocumentDataTable
                        onStartEdit={(doc) => showEditOfficialDoc(doc._id)}
                        {...props}
                    />
                ),
                model: 'document',
            },
            {
                fetchData: (params) =>
                    detailManyTags({
                        project_id: project._id,
                        options: {
                            ...params.options,
                            devFilters: {
                                category: 'pirate-brand',
                            },
                        },
                        signal: params.signal,
                    }),
                label: localization.models.pirateBrands.plural,
                totalCount: metricsQuery.data?.total_pirate_brands ?? 0,
                tableElement: TagDataTable,
                model: 'tag',
            },
            {
                fetchData: (params) =>
                    detailManyTargets({
                        project_id: project._id,
                        options: params.options,
                        signal: params.signal,
                    }),
                label: localization.models.target.plural,
                totalCount: metricsQuery.data?.total_targets ?? 0,
                tableElement: (props) => (
                    <TargetsDataTable
                        projectId={project._id}
                        showTags={false}
                        {...props}
                    />
                ),
                model: 'targets',
            },
            {
                fetchData: (params) =>
                    detailManyDocuments({
                        project_id: project._id,
                        ...params,
                    }),
                label: localization.models.document.plural,
                totalCount: metricsQuery.data?.total_documents ?? 0,
                tableElement: (props) => (
                    <DocumentDataTable
                        onStartEdit={(doc) => showEditDocument(doc._id)}
                        {...props}
                    />
                ),
                model: 'document',
            },
        ],
        [
            localization,
            metricsQuery.data,
            project._id,
            showEditDocument,
            showEditOfficialDoc,
        ]
    );

    if (metricsQuery.isLoading)
        return <LoadingMessage>{localization.common.loading}</LoadingMessage>;

    return (
        <section>
            {insertDocumentDialog}
            {insertOfficialDocDialog}
            <div className='flex flex-row w-full'>
                <Button
                    icon='pi pi-plus'
                    className='ml-auto'
                    label={localization.components.common.button.add}
                    onClick={(e) => menuRef.current?.toggle(e)}
                />
            </div>
            <Menu
                popup
                popupAlignment='right'
                ref={menuRef}
                model={[
                    {
                        label: localization.models.document.singular,
                        command: () => showInsertDocument(),
                    },
                    {
                        label: localization.models.oficio.singular,
                        command: () => showInsertOfficialDoc(),
                    },
                ]}
            />
            <ViewTablesWithTabs
                mainModel={null}
                uniqueQueryKey='admin-home'
                views={views}
            />
        </section>
    );
};

const AnalystHome = () => {
    const { permissions } = useAuth();
    const [localization] = useLocalization();

    return (
        <>
            <p>
                {localization.components.views.homeDashboard.analystDescription}
            </p>
            {permissions.viewAuthorizations && <AuthorizationDashboard />}
        </>
    );
};

const AuthorizerHome = () => {
    const { permissions } = useAuth();
    const [localization] = useLocalization();

    return (
        <>
            <p>
                {
                    localization.components.views.homeDashboard
                        .authorizerDescription
                }
            </p>
            <section className='flex flex-col gap-4'>
                {permissions.viewBlockOrders && <BlockOrderDashboard />}
                {permissions.viewAuthorizations && <AuthorizationDashboard />}
            </section>
        </>
    );
};

const AuthorityHome = () => {
    const [localization] = useLocalization();
    const project = useProject();
    const { user, permissions } = useAuth();
    const metricsQuery = useMetricsQuery(project._id);

    const { relatedCompany: company } = user;
    const viewApprovedGrouped =
        !!company?.view_setup?.['authorizer-strict']
            ?.approved_complaints_grouped;
    const viewPendingGrouped =
        !!company?.view_setup?.['authorizer-strict']
            ?.pending_complaints_grouped;

    if (metricsQuery.isLoading)
        return <LoadingMessage>{localization.common.loading}</LoadingMessage>;

    const data = metricsQuery.data;

    if (!data) return <h3>{localization.validations.generic.unhandled}</h3>;

    const authorizationBadge =
        localization.components.models.authorization.badge.status;

    // const { status: _, ...blockOrderFiltersWithoutStatus } = blockOrderFilters;

    const views: TableWithTabView[] = [];
    if (permissions.viewAuthorizations) {
        views.push(
            {
                fetchData: (params) =>
                    (viewPendingGrouped
                        ? detailManyAuthorizationGroupedByConfig
                        : detailManyAuthorization)({
                        ...params,
                        project_id: project._id,
                        options: {
                            ...params.options,
                            sort: {
                                field: 'created_at',
                                order: SortOrder.ASC,
                            },
                            devFilters: {
                                authority_data_status: 'pending',
                            },
                        },
                    }).then((query) => ({
                        ...query,
                        payload: query.payload.map((a) => {
                            a.status = 'pending';
                            return a;
                        }),
                    })),
                label: authorizationBadge.pendingWithName,
                totalCount: null,
                tableElement: (props) => {
                    const { sortField, paginatorProps, ...rest } = props;
                    if (viewPendingGrouped)
                        return (
                            <AuthorizationDataTable
                                {...paginatorProps}
                                {...rest}
                            />
                        );
                    return <AuthorizationDataTable {...rest} />;
                },
                model: 'authorization',
                overrideFilters: {},
            },
            {
                fetchData: (params) =>
                    (viewApprovedGrouped
                        ? detailManyAuthorizationGroupedByConfig
                        : detailManyAuthorization)({
                        project_id: project._id,
                        ...params,
                        options: {
                            ...params.options,
                            sort: {
                                field: 'created_at',
                                order: SortOrder.DESC,
                            },
                            devFilters: {
                                authority_data_status: 'done',
                            },
                        },
                    }),
                label: authorizationBadge.finishedWithName,
                totalCount: viewApprovedGrouped
                    ? null
                    : metricsQuery.data.authority_data_status_done,
                tableElement: (props) => {
                    const { sortField, ...noSortProps } = props;
                    return <AuthorizationDataTable {...noSortProps} />;
                },
                model: 'authorization',
                overrideFilters: {},
            },
            {
                fetchData: (params) =>
                    detailManyDocuments({
                        project_id: project._id,
                        options: {
                            ...params.options,
                            devFilters: {
                                category: 'official_document',
                            },
                        },
                        signal: params.signal,
                    }),
                label: localization.models.oficio.plural,
                totalCount: metricsQuery.data?.total_official_documents ?? 0,
                tableElement: (props) => (
                    <DocumentDataTable
                        // onStartEdit={(doc) => showEditOfficialDoc(doc._id)}
                        {...props}
                    />
                ),
                model: 'document',
            }
        );
    }

    return (
        <section>
            <ViewTablesWithTabs
                mainModel={null}
                uniqueQueryKey='authorizer-strict-home'
                views={views}
            />
        </section>
    );
};

const OperatorHome = () => {
    // const { permissions } = useAuth();
    const [localization] = useLocalization();

    return (
        <>
            <p>
                {
                    localization.components.views.homeDashboard
                        .operatorDescription
                }
            </p>
            <BlockOrderDashboard hideCount />
        </>
    );
};

const Home = () => {
    const { user } = useAuth();
    const [localization] = useLocalization();
    return (
        <>
            <h2>
                {localization.components.views.homeDashboard.welcome.replace(
                    '{username}',
                    getUserDisplayName(user.data)
                )}
            </h2>
            {user.role === 'admin' && <AdminHome />}
            {user.role === 'analyst' && <AnalystHome />}
            {user.role === 'authorizer-user' && <AuthorizerHome />}
            {user.role === 'authorizer-strict' && <AuthorityHome />}
            {user.role === 'operator' && <OperatorHome />}
        </>
    );
};

export default Home;
