import useQueryWithRefresh from 'hooks/helpers/useQueryWithRefresh/useQueryWithRefresh';
import { detailManyEvidences } from 'services/ether/case-manager/evidences';
import { DetailEvidenceEP } from 'services/ether/case-manager/evidences/types';

const useDetailManyEvidences = (
    params: DetailEvidenceEP.Params.Many,
    queryOptions?: {
        enabled?: boolean;
    }
) => {
    const queryKey = ['evidence', 'detail', params];

    const query = useQueryWithRefresh({
        queryKey,
        queryFn: async ({ signal }) =>
            detailManyEvidences({
                ...params,
                signal,
            }),
        ...queryOptions,
    });

    return query;
};

export default useDetailManyEvidences;
