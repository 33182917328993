import { Dialog, DialogProps } from 'primereact/dialog';
import './style.css';

const LightDialog: React.FC<DialogProps> = ({ children, ...props }) => {
    return (
        <Dialog
            {...props}
            className={['cm-light-dialog', props.className].join(' ')}
        >
            {children}
        </Dialog>
    );
};

export default LightDialog;
