import {
    exportTargetFromAuthorization,
    exportTargetFromBlockOrder,
} from 'services/ether/case-manager/targets';
import useBaseFileExport from '../useBaseFileExport';
import { DetailTargetEP } from 'services/ether/case-manager/targets/types';
import { useState } from 'react';

type Params =
    | {
          authorizationId: string;
          isRemoved: boolean;
      }
    | {
          blockOrderId: string;
      };

const useExportTarget = () => {
    const [params, setParams] = useState<Params | null>(null);

    const { exportFile, isExporting } = useBaseFileExport<{
        options?: DetailTargetEP.Filters.Options;
        fields: Params;
    }>('export-targets-file', [], ({ params, signal }) => {
        setParams(params.fields);
        if ('authorizationId' in params.fields) {
            return exportTargetFromAuthorization(
                params.fields.authorizationId,
                params.fields.isRemoved,
                params.options,
                signal
            );
        }
        return exportTargetFromBlockOrder(
            params.fields.blockOrderId,
            params.options,
            signal
        );
    });

    return { exportFile, isExporting, params };
};

export default useExportTarget;
