import { useMutation } from '@tanstack/react-query';
import useQueryRefresh from 'hooks/queries/useQueryRefresh';
import { useLocalization } from 'hooks/context/useLocalization';
import { useToast } from 'hooks/context/useToast';
import { changeAuthorizationFlow } from 'services/ether/case-manager/authorizations';
import { ChangeAuthorizationFlowEP } from 'services/ether/case-manager/authorizations/types';
import { getErrorToast } from 'utils/errorHandler';

const useChangeAuthorizationFlow = (options?: { onChange?: () => void }) => {
    const [localization] = useLocalization();
    const toast = useToast();

    const { hardRefresh, softRefresh } = useQueryRefresh();

    const handleUpdate = ({
        data,
        params,
    }: {
        data: ChangeAuthorizationFlowEP.Result;
        params: ChangeAuthorizationFlowEP.Data;
    }) => {
        hardRefresh(['authorization']);
        softRefresh(['metrics']);
        toast.show({
            summary:
                localization.endpoints.authorization.change_authorization_flow
                    .success,
            severity: 'success',
            life: 10000,
        });
        if (options?.onChange) options.onChange();
    };

    const changeAuthorizationMutation = useMutation<
        ChangeAuthorizationFlowEP.Result,
        Error,
        ChangeAuthorizationFlowEP.Data
    >({
        mutationFn: (data) => changeAuthorizationFlow(data),
        onSuccess: (data, params) => handleUpdate({ data, params }),
        onError: (err) => toast.show(getErrorToast(err.message, localization)),
    });

    const changeFlow = (data: ChangeAuthorizationFlowEP.Data) => {
        changeAuthorizationMutation.mutate(data);
    };

    return {
        changeFlow,
        error: changeAuthorizationMutation.error,
        isLoading: changeAuthorizationMutation.isPending,
    };
};

export default useChangeAuthorizationFlow;
