import { useLocalization } from 'hooks/context/useLocalization';
import useOneDetailDocument from 'hooks/queries/document/useDetailOneDocument';
import { useProject } from 'hooks/context/project/useProject';
import useDetailOneEvidence from 'hooks/queries/evidence/useDetailOneEvidence';
import LoadingMessage from 'components/misc/LoadingMessage';
import FileViewer, { FileViewerProps } from '../FileViewer';

type RelevantProps = { modelId: string } & Omit<FileViewerProps, 'item'>;

const FileViewerWrapper: React.FC<RelevantProps> = ({ modelId, ...props }) => {
    const [localization] = useLocalization();
    const project = useProject();

    const documentQuery = useOneDetailDocument(
        {
            project_id: project._id,
            _id: modelId,
        },
        {
            enabled: props.mode === 'document',
        }
    );
    const evidenceQuery = useDetailOneEvidence(
        {
            project_id: project._id,
            _id: modelId,
        },
        {
            enabled: props.mode === 'evidence',
        }
    );

    const itemQuery = props.mode === 'document' ? documentQuery : evidenceQuery;
    const itemData = itemQuery.data;

    if (itemQuery.isLoading)
        return <LoadingMessage>{localization.common.loading}</LoadingMessage>;

    if (!itemData) return <h2>{localization.validations.generic.notFound}</h2>;

    return <FileViewer item={itemData} {...props} />;
};

export default FileViewerWrapper;
