import { useLocalization } from 'hooks/context/useLocalization';
import { useToast } from 'hooks/context/useToast';
import { useState } from 'react';
import { downloadDocumentFile } from 'services/ether/case-manager/documents';
import { downloadEvidenceFile } from 'services/ether/case-manager/evidences';
import { getErrorToast } from 'utils/errorHandler';
import { handleFileModelDownload } from 'utils/file';

const useDownloadModelFile = ({ mode }: { mode: 'document' | 'evidence' }) => {
    const [localization] = useLocalization();
    const [isDownloading, setIsDownloading] = useState(false);
    const toast = useToast();

    const downloadItem = (
        item: Ether.CaseManager.Document | Ether.CaseManager.Evidence,
        token?: string
    ) => {
        if (!item) return new Promise<null>((resolve) => resolve(null));
        if (mode === 'document')
            return downloadDocumentFile(item._id, { token });
        else
            return downloadEvidenceFile(item._id, item.file.id, {
                token,
            });
    };

    const downloadWrapper = (
        item: Ether.CaseManager.Document | Ether.CaseManager.Evidence,
        token?: string
    ) => {
        setIsDownloading(true);
        downloadItem(item, token)
            .then((data) => {
                if (!data) return;
                handleFileModelDownload({
                    file: data.file,
                    item: item,
                    extension: data.extension,
                });
            })
            .catch((err) =>
                toast.show(getErrorToast(err.message, localization))
            )
            .finally(() => setIsDownloading(false));
    };

    const downloadEvidence = (
        evidence: Ether.CaseManager.Evidence,
        token?: string
    ) => downloadWrapper(evidence, token);

    const downloadDocument = (document: Ether.CaseManager.Document) =>
        downloadWrapper(document);

    return {
        isDownloading,
        downloadItem,
        downloadDocument,
        downloadEvidence,
    };
};

export default useDownloadModelFile;
