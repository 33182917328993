import LightDialog from 'components/display/LightDialog';
import LoadingMessage from 'components/misc/LoadingMessage';
import { useLocalization } from 'hooks/context/useLocalization';
import useGetAuthorizationStats from 'hooks/queries/authorization/useGetAuthorizationStats';
import { SortOrder } from 'primereact/api';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Divider } from 'primereact/divider';
import { useCallback, useMemo, useState } from 'react';
import { GetAuthorizationStatsEP } from 'services/ether/case-manager/authorizations/types';

export const AuthorizationStatsDialog: React.FC<{
    header: string;
    visible: boolean;
    data: null | GetAuthorizationStatsEP.Result;
    isLoading: boolean;
    onHide: () => void;
}> = ({ header, visible, data, isLoading, onHide }) => {
    const [localization] = useLocalization();
    const statsLocalization = localization.components.common.stats;
    return (
        <LightDialog
            visible={visible}
            onHide={onHide}
            header={header}
            footer={
                <Button
                    onClick={onHide}
                    label={localization.components.common.button.close}
                />
            }
            className='min-w-[50vw]'
        >
            {isLoading ? (
                <LoadingMessage>{localization.common.loading}</LoadingMessage>
            ) : data ? (
                <>
                    <h2>
                        {statsLocalization.totalTargets}: {data.targets.total}
                    </h2>
                    <Divider />
                    {data.targets.total > 0 &&
                        Object.entries(data.targets.by_type)
                            .filter(([_, value]) => value.total > 0)
                            .map(([key, value]) => {
                                return (
                                    <div key={key}>
                                        <h2>{key.toLocaleUpperCase()}</h2>
                                        <p>Total: {value.total}</p>
                                        {Object.entries(value)
                                            .filter(
                                                ([_, value2]) =>
                                                    typeof value2 ===
                                                        'object' &&
                                                    Object.keys(value2).length >
                                                        0
                                            )
                                            .map(([key2, value2]) => {
                                                if (typeof value2 !== 'object')
                                                    return null;
                                                return (
                                                    <div
                                                        className='ml-8'
                                                        key={key + key2}
                                                    >
                                                        <h4>
                                                            {key2 in
                                                            statsLocalization.fields
                                                                ? statsLocalization
                                                                      .fields[
                                                                      key2 as keyof typeof statsLocalization.fields
                                                                  ]
                                                                : key2}
                                                        </h4>
                                                        <DataTable
                                                            showHeaders={false}
                                                            sortField='value'
                                                            sortOrder={
                                                                SortOrder.DESC
                                                            }
                                                            scrollable
                                                            scrollHeight='30vh'
                                                            value={Object.entries(
                                                                value2
                                                            ).map(
                                                                ([
                                                                    key3,
                                                                    value3,
                                                                ]) => ({
                                                                    name: key3,
                                                                    value: value3,
                                                                })
                                                            )}
                                                        >
                                                            <Column field='name' />
                                                            <Column field='value' />
                                                        </DataTable>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                );
                            })}
                </>
            ) : (
                <p>{localization.endpoints.general.get_stats.error}</p>
            )}
        </LightDialog>
    );
};

const useShowAuthorizationStats = () => {
    const [localization] = useLocalization();
    const [item, setItem] = useState<{
        id: string;
        name: string;
    } | null>(null);
    const { data: stats, isLoading } = useGetAuthorizationStats(
        {
            authorization_id: item?.id ?? '',
        },
        {
            enabled: !!item,
        }
    );

    const show = useCallback(
        (data: NonNullable<typeof item>) => setItem(data),
        []
    );
    const hide = () => setItem(null);

    const dialog = useMemo(
        () =>
            item && (
                <AuthorizationStatsDialog
                    header={localization.components.models.authorization.views.stats.header.replace(
                        '{name}',
                        item.name
                    )}
                    visible
                    isLoading={isLoading}
                    data={stats ?? null}
                    onHide={hide}
                />
            ),
        [stats, isLoading, item, localization]
    );

    return {
        dialog: dialog,
        show: show,
    };
};

export default useShowAuthorizationStats;
