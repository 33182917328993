import { useLocalization } from 'hooks/context/useLocalization';
import { Badge, BadgeProps } from 'primereact/badge';

const AuthoritySignStatusBadge: React.FC<{
    authorization: Ether.CaseManager.Authorization.Detailed;
    badgeProps?: BadgeProps;
}> = ({ authorization, badgeProps }) => {
    const [localization] = useLocalization();
    const authorizationBadge =
        localization.components.models.authorization.badge.authorityStatus;
    const status = authorization.authority_data?.status;
    if (!status) return '-';
    let value : string = status;
    badgeProps = {
        ...badgeProps,
    };
    switch (status) {
        case 'pending':
            badgeProps.severity = 'warning';
            value = authorizationBadge.pending;
            break;
        case 'available':
            badgeProps.severity = 'info';
            value = authorizationBadge.available;
            break;
        case 'new':
            value = authorizationBadge.new;
            break;
        case 'done':
            badgeProps.severity = 'success';
            value = authorizationBadge.done;
            break;
    }
    badgeProps.value = value.toLocaleUpperCase();
    return (
        <Badge
            {...badgeProps}
            style={{
                ...badgeProps.style,
                whiteSpace: 'nowrap',
            }}
        />
    );
};

export default AuthoritySignStatusBadge;
