import useQueryWithRefresh from 'hooks/helpers/useQueryWithRefresh/useQueryWithRefresh';
import { getOneAuthorizationConfig } from 'services/ether/case-manager/authorization-config';
import { DetailAuthorizationConfigEP } from 'services/ether/case-manager/authorization-config/types';

const useGetOneAuthorizationConfig = (
    params: DetailAuthorizationConfigEP.Params.One,
    queryOptions?: {
        enabled?: boolean;
    }
) => {
    const queryKey = ['authorization-config', 'detail', params];

    const query = useQueryWithRefresh({
        queryKey,
        queryFn: ({ signal }) =>
            getOneAuthorizationConfig({
                ...params,
                signal,
            }),
        ...queryOptions,
    });

    return query;
};

export default useGetOneAuthorizationConfig;
