import NameIdAggregator from 'components/datatable/NameIdAggregator';
import { DateBadge } from 'components/ethercity-primereact';
import { Column } from 'primereact/column';
import { DataTableProps } from 'primereact/datatable';
import { getUserDisplayName } from 'utils/user';
import BlockOrderStatusBadge from '../BlockOrderStatusBadge';
import { useAuth } from 'hooks/context/useAuth';
import { useRef } from 'react';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { Button } from 'primereact/button';
import { useLocalization } from 'hooks/context/useLocalization';
import CMDataTable from 'components/datatable/CMDataTable';
import { CMDataTableProps } from 'components/datatable/CMDataTable/types';
import { isBlockOrderRespondable } from 'utils/blockOrder';
import { useBlockOrderRespond } from 'hooks/models/blockOrder/useBlockOrderRespond';
import useExportBlockOrderFiles from 'hooks/queries/block-order/useExportBlockOrderFiles';
import { getFilterData } from 'utils/datatable';
import BlockOrderValidationStatusBadge from 'components/models/BlockOrder/BlockOrderValidationStatusBadge';
import { FilterMatchMode } from 'primereact/api';
import useShowBlockOrderStats from 'hooks/dialogs/blockOrder/useShowBlockOrderStats';
import useShowObjectDisplayModal from 'hooks/dialogs/general/useShowObjectDisplayModal';

type DetailedModel = Ether.CaseManager.BlockOrder.Detailed;

const RowMenu: React.FC<{
    item: DetailedModel;
    onShowDetail: (target: Ether.CaseManager.BlockOrder) => void;
    onRespond?: () => void;
    onShowEdit?: (id: string) => void;
    onShowStats?: (item: { id: string; name: string }) => void;
}> = ({ item, onShowDetail, onRespond, onShowEdit, onShowStats }) => {
    const { permissions, user } = useAuth();
    const [localization] = useLocalization();

    const menuRef = useRef<Menu>(null);

    const [respondOrder] = useBlockOrderRespond({
        onRespond,
    });

    const isRespondable = isBlockOrderRespondable({
        permissions: permissions,
        blockOrder: item,
        userId: user.data?._id,
    });

    const { exportFile, isExporting } = useExportBlockOrderFiles(item._id);

    const menuItems: MenuItem[] = [
        ...(permissions.getBlockOrderStats && onShowStats
            ? [
                  {
                      id: 'stats',
                      icon: 'pi pi-chart-bar',
                      label: localization.components.common.stats.header,
                      command: () =>
                          onShowStats({
                              id: item._id,
                              name: item.name,
                          }),
                  },
              ]
            : []),
        ...(permissions.debugBlockOrders
            ? [
                  {
                      id: 'debug',
                      label: 'Detail data',
                      command: () => onShowDetail(item),
                  },
              ]
            : []),
        ...(permissions.insertBlockOrders &&
        onShowEdit &&
        item.status === 'draft'
            ? [
                  {
                      id: 'edit',
                      label: localization.components.common.button.edit,
                      command: () => onShowEdit(item._id),
                      icon: 'pi pi-pencil',
                  },
              ]
            : []),
        ...(isRespondable
            ? [
                  {
                      id: 'accept',
                      label: localization.components.common.button.confirm,
                      command: () => respondOrder(item, true),
                      icon: 'pi pi-check',
                  },
              ]
            : []),
        // TODO: DUMMY REMOVE
        ...(isRespondable
            ? [
                  {
                      id: 'reject',
                      label: localization.components.common.button.reject,
                      icon: 'pi pi-times',
                      disabled: true,
                  },
              ]
            : []),
        ...(isRespondable
            ? [
                  {
                      id: 'contest',
                      label: localization.components.common.button.contest,
                      icon: 'pi pi-question-circle',
                      disabled: true,
                  },
              ]
            : []),
        ...(permissions.exportBlockOrderFiles
            ? [
                  {
                      id: 'export-files',
                      icon: 'pi pi-download',
                      label: localization.components.common.button.exportFiles,
                      command: exportFile,
                      disabled: isExporting,
                  },
              ]
            : []),
    ];

    if (menuItems.length <= 0) return null;

    return (
        <>
            <Menu popup ref={menuRef} model={menuItems} />
            <Button
                icon='pi pi-ellipsis-h'
                onClick={(e) => menuRef?.current?.toggle(e)}
            />
        </>
    );
};

type KeyInColumn = keyof CaseManagerApp.ModelColumns['blockOrder'];

const columnFields: { [key in KeyInColumn]: key } = {
    authorizations_count: 'authorizations_count',
    status: 'status',
    validation_status: 'validation_status',
    'block_checker.synced_at': 'block_checker.synced_at',
    created_at: 'created_at',
    created_by: 'created_by',
    targets_count: 'targets_count',
    registered_at: 'registered_at',
};

export const blockOrderFilters = {
    _cm_name_id: getFilterData(),
    status: getFilterData(FilterMatchMode.EQUALS),
    created_at: getFilterData(FilterMatchMode.DATE_IS),
};

const BlockOrderDataTable: React.FC<
    DataTableProps<Ether.CaseManager.BlockOrder[]> &
        Omit<CMDataTableProps, 'columnConfigName'> & {
            onRespond?: () => void;
            onShowEdit?: (id: string) => void;
        }
> = ({ onRespond, onShowEdit, ...props }) => {
    const [localization] = useLocalization();
    const { user } = useAuth();

    const { dialog: statsDialog, show: showStats } = useShowBlockOrderStats();
    const { dialog: debugDialog, show: showDebug } =
        useShowObjectDisplayModal();

    const datatableLocale = localization.components.models.blockOrder.datatable;

    const hideStatusColumn = user.role === 'operator';
    const showValidationStatusColumn = user.role === 'operator';

    return (
        <>
            {debugDialog}
            {statsDialog}
            <CMDataTable
                lazy
                emptyMessage={datatableLocale.empty}
                columnConfigName='blockOrder'
                filterOptions={{
                    _cm_name_id: {
                        placeholder: datatableLocale.selectName,
                        type: 'string',
                    },
                    status: {
                        label: localization.fields.blockOrder.status,
                        type: 'dropdown',
                        selectOptions: [
                            {
                                label: localization.components.models.blockOrder
                                    .badge.status.done,
                                value: 'done',
                            },
                            {
                                label: localization.components.models.blockOrder
                                    .badge.status.draft,
                                value: 'draft',
                            },
                        ],
                    },
                    created_at: {
                        label: localization.fields.blockOrder.created,
                        type: 'date',
                    },
                }}
                {...props}
            >
                <Column
                    field='_cm_name_id'
                    body={(rowData: Ether.CaseManager.BlockOrder) => (
                        <NameIdAggregator
                            name={rowData.name}
                            id={rowData._id}
                            navigateTo={`/block-orders/${rowData._id}`}
                        />
                    )}
                />
                {!hideStatusColumn && (
                    <Column
                        field={columnFields['status']}
                        header={localization.fields.blockOrder.status}
                        body={(rowData: Ether.CaseManager.BlockOrder) => (
                            <BlockOrderStatusBadge blockOrder={rowData} />
                        )}
                    />
                )}
                {showValidationStatusColumn && (
                    <Column
                        field={columnFields['validation_status']}
                        header={localization.fields.blockOrder.validationStatus}
                        body={(rowData: DetailedModel) => (
                            <BlockOrderValidationStatusBadge
                                blockOrder={rowData}
                            />
                        )}
                    />
                )}
                <Column
                    field={columnFields['authorizations_count']}
                    header={localization.models.authorization.plural}
                    body={(rowData: DetailedModel) =>
                        rowData.total_authorizations
                    }
                />
                <Column
                    field={columnFields['targets_count']}
                    header={localization.models.target.plural}
                    body={(rowData: DetailedModel) => rowData.total_targets}
                />
                <Column
                    field={columnFields['created_at']}
                    header={localization.fields.blockOrder.created}
                    dataType='date'
                    body={(rowData: Ether.CaseManager.BlockOrder) => (
                        <DateBadge value={rowData.created_at} />
                    )}
                />
                <Column
                    field={columnFields['created_by']}
                    header={localization.fields.blockOrder.createdBy}
                    body={(rowData: DetailedModel) =>
                        getUserDisplayName(rowData.created_by_data?.[0])
                    }
                />
                <Column
                    field={columnFields['registered_at']}
                    header={localization.fields.blockOrder.registeredAt}
                    dataType='date'
                    body={(rowData: Ether.CaseManager.BlockOrder) =>
                        rowData.registered_at ? (
                            <DateBadge value={rowData.registered_at} />
                        ) : (
                            '-'
                        )
                    }
                />
                <Column
                    field='actions'
                    body={(data: DetailedModel) => (
                        <RowMenu
                            item={data}
                            onShowDetail={(item) =>
                                showDebug({
                                    data: item,
                                    header: item.name,
                                })
                            }
                            onRespond={onRespond}
                            onShowEdit={onShowEdit}
                            onShowStats={showStats}
                        />
                    )}
                />
            </CMDataTable>
        </>
    );
};

export default BlockOrderDataTable;
