import { FilterMatchMode } from 'primereact/api';
import {
    DataTableFilterMetaData,
    DataTableSortMeta,
} from 'primereact/datatable';
import { stringify } from 'csv-stringify/browser/esm/sync';
import downloadFromUrl from 'utils/downloadFromUrl';
import api from 'services/ether/api';
import isValidOid from 'utils/isValidOid';
import mimeDatabase from 'mime-db';
import { CMLocalization } from 'static/language';
import { handleSort } from 'utils/datatable';
import { FilterType, MapFilterKeys } from './types';
import { sanitizeRegex } from 'utils/sanitizeRegex';

export const getMatchModeRegex = (
    value: string,
    mode: DataTableFilterMetaData['matchMode']
) => {
    switch (mode) {
        case FilterMatchMode.STARTS_WITH:
            return '^' + sanitizeRegex(value);
        case FilterMatchMode.CONTAINS:
            return sanitizeRegex(value);
        case FilterMatchMode.ENDS_WITH:
            return sanitizeRegex(value) + '$';
        case FilterMatchMode.NOT_CONTAINS:
            return `^(?!.*${sanitizeRegex(value)}).*$`;
        default:
            throw new Error('not elligible for regex');
    }
};

export const handleFilterMatchMode = (
    key: string,
    value: string | Date,
    mode: DataTableFilterMetaData['matchMode']
) => {
    if (value == null) return {};

    const isArray = Array.isArray(value);
    const stringified =
        value instanceof Date
            ? value.toISOString()
            : isArray
            ? value.join(',')
            : value;

    switch (mode) {
        case FilterMatchMode.STARTS_WITH:
            return {
                [key + '|regex']: '^' + sanitizeRegex(stringified),
            };
        case FilterMatchMode.CONTAINS:
            return { [key + '|regex']: sanitizeRegex(stringified) };
        case FilterMatchMode.NOT_CONTAINS:
            if (isArray)
                return {
                    [key + '|nin']: stringified,
                };
            return {
                [key + '|regex']: `^(?!.*${sanitizeRegex(stringified)}).*$`,
            };
        case FilterMatchMode.ENDS_WITH:
            return {
                [key + '|regex']: sanitizeRegex(stringified) + '$',
            };
        case FilterMatchMode.EQUALS:
            return { [key]: stringified };
        case FilterMatchMode.NOT_EQUALS:
            return { [key + '|ne']: stringified };
        case FilterMatchMode.LESS_THAN:
            return { [key + '|lt']: stringified };
        case FilterMatchMode.LESS_THAN_OR_EQUAL_TO:
            return { [key + '|lte']: stringified };
        case FilterMatchMode.GREATER_THAN:
            return { [key + '|gt']: stringified };
        case FilterMatchMode.GREATER_THAN_OR_EQUAL_TO:
            return { [key + '|gte']: stringified };
        case FilterMatchMode.IN:
            return { [key + '|in']: stringified };
        case FilterMatchMode.DATE_IS: {
            const tomorrow = new Date(stringified);
            tomorrow.setDate(tomorrow.getDate() + 1);
            return {
                [key + '|range']: `${stringified},${tomorrow.toISOString()}`,
            };
        }
        case FilterMatchMode.DATE_IS_NOT: {
            const tomorrow = new Date(stringified);
            tomorrow.setDate(tomorrow.getDate() + 1);
            return {
                [key +
                '|not,range']: `${stringified},${tomorrow.toISOString()}`,
            };
        }
        case FilterMatchMode.DATE_AFTER: {
            const tomorrow = new Date(stringified);
            tomorrow.setDate(tomorrow.getDate() + 1);
            return { [key + '|gte']: tomorrow.toISOString() };
        }
        case FilterMatchMode.DATE_BEFORE: {
            return { [key + '|lt']: stringified };
        }
        default:
            throw new Error('match mode not implemented');
    }
};

export const getFilterMeta = (filterMeta: FilterType) => {
    if (filterMeta == null)
        return {
            value: undefined,
            matchMode: undefined,
        };
    if (filterMeta instanceof Date) {
        return {
            value: filterMeta.toISOString(),
            matchMode: FilterMatchMode.DATE_IS,
        };
    }
    if (Array.isArray(filterMeta)) {
        return {
            value: filterMeta.join(','),
            matchMode: FilterMatchMode.IN,
        };
    }
    if (typeof filterMeta === 'string')
        return {
            value: filterMeta,
            matchMode: FilterMatchMode.EQUALS,
        };
    if (typeof filterMeta === 'number')
        return {
            value: filterMeta.toString(),
            matchMode: FilterMatchMode.EQUALS,
        };
    if (typeof filterMeta === 'boolean')
        return {
            value: filterMeta ? 'true' : 'false',
            matchMode: FilterMatchMode.EQUALS,
        };
    if ('constraints' in filterMeta)
        return (
            filterMeta.constraints[0] ?? {
                value: undefined,
                matchMode: undefined,
            }
        );
    return filterMeta;
};

const parseOidFilter = (
    key: string,
    filterMeta: FilterType | undefined,
    paramFilters: { [key: string]: string }
) => {
    if (!filterMeta) return;
    let { value } = getFilterMeta(filterMeta);
    paramFilters[key + '@oid'] = value;
};

export type ParseDataTableParams = {
    filters?: {
        [key: string]: FilterType;
    };
    sort?: DataTableSortMeta | null;
    idFields?: string[];
    dateFields?: string[];
    ignoreInFields?: string[];
    nameField?: string;
};

export const parseDataTableFilterMetaForAPI = ({
    filters,
    sort,
    idFields,
    dateFields,
    nameField,
    ignoreInFields,
}: ParseDataTableParams) => {
    if (!filters && !sort) return {};

    if (!nameField) nameField = 'name';

    const baseIdFields = ['_id'];
    idFields = idFields ? [...idFields, ...baseIdFields] : baseIdFields;

    const reservedFields = [...idFields, '_cm_name_id'];

    const baseIgnoreInFields = ['additional_fields'];
    ignoreInFields = ignoreInFields
        ? [...ignoreInFields, ...baseIgnoreInFields]
        : baseIgnoreInFields;

    let paramFilters: Record<string, string> = {};

    if (!filters) filters = {};
    Object.entries(filters).forEach(([key, filter]) => {
        if (filter == null) return;
        if (reservedFields.includes(key)) return;
        let { value, matchMode } = getFilterMeta(filter);
        if (ignoreInFields?.includes(key) && matchMode === 'in')
            matchMode = 'equals';
        paramFilters = {
            ...paramFilters,
            ...handleFilterMatchMode(key, value, matchMode),
        };
    });

    idFields.forEach((key) =>
        parseOidFilter(key, filters?.[key], paramFilters)
    );

    Object.entries(paramFilters).forEach(([key, value]) => {
        dateFields?.forEach((dateKey) => {
            if (value && (key === dateKey || key?.startsWith(dateKey + '|'))) {
                paramFilters[`${key}@datetime`] = value;
                delete paramFilters[key];
            }
        });
    });

    const keyName = '_cm_name_id';

    if (filters[keyName]) {
        const { value, matchMode } = getFilterMeta(filters[keyName]);
        if (isValidOid(value)) paramFilters['_id'] = value;
        else {
            paramFilters = {
                ...paramFilters,
                ...handleFilterMatchMode(nameField, value, matchMode),
            };
        }
    }

    const order = handleSort(sort, {
        [keyName]: nameField,
    });

    if (order) paramFilters['order'] = order;

    return paramFilters;
};

export const getFileExtension = ({
    filename,
    contentDisposition,
    contentType,
}: {
    filename?: string;
    contentDisposition?: string;
    contentType?: string;
}) => {
    try {
        if (filename) if (filename.includes('.')) return filename.split('.')[1];
        if (contentDisposition) {
            const regex = /filename="([^"]+)"/;
            const match = contentDisposition.match(regex);
            if (match && match[1]) {
                const filename = match[1];
                return filename.split('.')[1];
            }
        }
        if (contentType) {
            if (contentType.includes(';'))
                contentType = contentType.split(';')[0] as string;
            const entry = mimeDatabase[contentType];
            if (entry?.extensions) return entry.extensions[0];
        }
    } catch (err) {
        console.error('Failed to get extension');
        console.error(err);
    }
    return null;
};

interface GenericEtherItem {
    _id: string;
    [key: string]: any;
}

export async function exportAsCsv(options: {
    filename: string;
    filters: { [key: string]: string | number };
    csvHeaders: (
        | string
        | {
              field: string;
              name?: string;
              parseFunction?(value: any): string;
          }
    )[];
    fetchFn(filters: {
        [key: string]: string | number;
    }): Promise<GenericEtherItem[]>;
    crossFetchItem?: (item: GenericEtherItem) => Promise<GenericEtherItem>;
}) {
    const LIMIT = 500;

    const csvData: any[] = [];

    const { filename, filters, fetchFn, csvHeaders, crossFetchItem } = options;
    filters['order'] = '_id';
    filters['limit'] = LIMIT;

    let continueRequests = true;
    let requestsMade = 0;

    const finalCsvHeaders: string[] = csvHeaders.map((header) => {
        if (typeof header !== 'string') return header.name ?? header.field;
        return header;
    });
    while (continueRequests) {
        requestsMade += 1;
        if (requestsMade > 3000) throw new Error('exceeded amount of requests');

        const fetchItems = async () => {
            const items = await fetchFn(filters);
            if (!crossFetchItem) return items;
            const promises = items.map((i) => crossFetchItem(i));
            return await Promise.all(promises);
        };

        const items = await fetchItems();

        if (items.length > 0) {
            if (items.length < LIMIT) continueRequests = false;

            items.forEach((item) => {
                const itemData: any[] = [];
                csvHeaders.forEach((field) => {
                    if (typeof field === 'string') {
                        let value: any = item;
                        const splittedFields = field.split('.');
                        splittedFields.every((subField) => {
                            if (value[subField] instanceof Date) {
                                value = value[subField].toISOString();
                            } else value = value[subField];
                            if (value == null) return false;
                            return true;
                        });
                        itemData.push(value);
                    } else {
                        let value: any = item;
                        const splittedFields = field.field.split('.');
                        // parse value until its null or reached end
                        splittedFields.every((subField) => {
                            value = value[subField];
                            if (value == null) return false;
                            return true;
                        });
                        itemData.push(
                            field.parseFunction
                                ? field.parseFunction(value)
                                : value instanceof Date
                                ? value.toISOString()
                                : value?.toString()
                        );
                    }
                });
                csvData.push(itemData);
            });

            const lastId = items[items.length - 1];
            if (lastId) filters['_id|gt'] = lastId._id;
        } else {
            continueRequests = false;
        }
    }

    const output = stringify(csvData, {
        header: true,
        columns: finalCsvHeaders,
    });
    const blob = new Blob([output]);
    const fileDownloadUrl = URL.createObjectURL(blob);
    downloadFromUrl(fileDownloadUrl, filename + '.csv');
    URL.revokeObjectURL(fileDownloadUrl);
}

export async function exportAsCsvNoHeaders(options: {
    filename: string;
    filters: { [key: string]: string | number };
    fetchFn(filters: {
        [key: string]: string | number;
    }): Promise<GenericEtherItem[]>;
    crossFetchItem?: (item: GenericEtherItem) => Promise<GenericEtherItem>;
}) {
    const LIMIT = 500;

    const csvData: any[] = [];

    const { filename, filters, fetchFn, crossFetchItem } = options;
    filters['order'] = '_id';
    filters['limit'] = LIMIT;

    let continueRequests = true;
    let requestsMade = 0;

    while (continueRequests) {
        requestsMade += 1;
        if (requestsMade > 3000) throw new Error('exceeded amount of requests');

        const fetchItems = async () => {
            const items = await fetchFn(filters);
            if (!crossFetchItem) return items;
            const promises = items.map((i) => crossFetchItem(i));
            return await Promise.all(promises);
        };

        const items = await fetchItems();

        if (items.length > 0) {
            if (items.length < LIMIT) continueRequests = false;

            items.forEach((item) => csvData.push(item));

            const lastId = items[items.length - 1];
            if (lastId) filters['_id|gt'] = lastId._id;
        } else {
            continueRequests = false;
        }
    }

    const output = stringify(csvData, {
        header: true,
    });
    const blob = new Blob([output]);
    const fileDownloadUrl = URL.createObjectURL(blob);
    downloadFromUrl(fileDownloadUrl, filename + '.csv');
    URL.revokeObjectURL(fileDownloadUrl);
}

export const baseExportCsv = (
    endpoint: string,
    options?: {
        filename?: string;
        signal?: AbortSignal;
        filters?: { [key: string]: string | number | boolean };
        sort?: DataTableSortMeta;
        onCountUpdate?(count: number, total: number): void;
    }
) => {
    return new Promise<void>((resolve, reject) => {
        let { filters, onCountUpdate, filename } = options ?? {};
        if (!filters) filters = {};

        let finalOrder: string | null = null;
        if (options?.sort && options.sort.field) {
            const { order, field } = options.sort;
            if (order === 1) {
                finalOrder = field;
            } else if (order === -1) {
                finalOrder = '-' + field;
            }
        }
        if (finalOrder) filters['order'] = finalOrder;

        const headers: { [key: string]: string } = {};
        Object.entries(api.defaults.headers.common).forEach(([key, value]) => {
            headers[key] = value as string;
        });

        let queryString: string[] = [];
        filters['limit'] = 0;
        filters['return_count'] = 'true';
        Object.entries(filters).forEach(([key, value]) => {
            if (value == null) return;
            queryString.push(`${key}=${value}`);
        });

        const textDecoder = new TextDecoder();
        const textEncoder = new TextEncoder();
        let isFirstStream = true;
        let total: number | null = null;
        let sum = 0;
        let lastTime = Date.now();

        const updateCount = (count: number, total: number) => {
            lastTime = Date.now();
            if (onCountUpdate) onCountUpdate(count, total);
        };

        updateCount(0, 1);

        if (!endpoint.startsWith('/')) endpoint = '/' + endpoint;
        fetch(`${endpoint}?${queryString.join('&')}`, {
            method: 'get',
            headers: headers,
            signal: options?.signal,
        })
            .then((response) => {
                if (!response.body) {
                    reject(new Error('failed to obtain stream'));
                    return;
                }
                const reader = response.body.getReader();
                return new ReadableStream({
                    start(controller) {
                        return pump();
                        function pump(): any {
                            return reader.read().then(({ done, value }) => {
                                const text = textDecoder.decode(value);
                                const rows = text.split('\n');
                                sum += rows.length;
                                if (rows[rows.length - 1] === '') sum -= 1;
                                if (isFirstStream) {
                                    total = Number(rows.splice(0, 1));
                                    sum -= 2;
                                    isFirstStream = false;
                                    value = textEncoder.encode(rows.join('\n'));
                                }
                                if (
                                    total != null &&
                                    Date.now() - lastTime > 1000
                                )
                                    updateCount(sum, total);
                                if (done) {
                                    controller.close();
                                    return;
                                }
                                controller.enqueue(value);
                                return pump();
                            });
                        }
                    },
                });
            })
            .then((stream) => new Response(stream))
            .then((response) => response.text())
            .then((data) => {
                // const csvData = data.split(/_(.*)/s)[1];
                const blob = new Blob([data], { type: 'text/csv' });
                const fileDownloadUrl = window.URL.createObjectURL(blob);
                downloadFromUrl(
                    fileDownloadUrl,
                    (filename ?? 'unnamed_nomia_export') + '.csv'
                );
                resolve();
            })
            .catch((err) => reject(err));
    });
};

export const handleXHRResponseError = (
    status: string,
    localization: CMLocalization.Localization
) => {
    if (status === 'Not Found')
        return localization.validations.generic.notFound;
    else return localization.validations.generic.unhandled;
};

export const mapDevFilters = (filters: MapFilterKeys<any>) => {
    const devKeys: Record<string, FilterType> = {};
    Object.values(filters).forEach(([key, value]) => {
        devKeys[key] = value;
    });
    return devKeys;
};
