import { Dialog, DialogProps } from 'primereact/dialog';
import ObjectDisplay, { IObjectDisplayProps } from '../ObjectDisplay';

interface IObjectDisplayModalProps extends DialogProps, IObjectDisplayProps {}

export const ObjectDisplayModal: React.FC<IObjectDisplayModalProps> = ({
    displayData,
    sortKeys,
    style,
    ...rest
}) => {

    return (
        <Dialog style={{ width: '70vw', ...style }} {...rest}>
            {displayData && <ObjectDisplay displayData={displayData} sortKeys={sortKeys} />}
        </Dialog>
    );
};

export default ObjectDisplayModal;
