import { useLocalization } from 'hooks/context/useLocalization';
import { InsertMonitorDialogProps } from './types';
import { Button } from 'primereact/button';
import MonitorForm from '../MonitorForm';
import { useState } from 'react';
import { useProject } from 'hooks/context/project/useProject';
import { useMutation } from '@tanstack/react-query';
import { InsertMonitoring } from 'services/ether/case-manager/monitoring/types';
import { insertMonitoring } from 'services/ether/case-manager/monitoring';
import { useToast } from 'hooks/context/useToast';
import { getErrorToast } from 'utils/errorHandler';
import LightDialog from 'components/display/LightDialog';

const InsertMonitorDialog: React.FC<InsertMonitorDialogProps> = ({
    afterInsert,
    ...props
}) => {
    const project = useProject();
    const toast = useToast();
    const [localization] = useLocalization();

    const [name, setName] = useState('');
    const [networkUrl, setNetworkUrl] = useState('');
    const [videoUrl, setVideoUrl] = useState('');

    const insertMutation = useMutation<string, Error, InsertMonitoring.Data>({
        mutationFn: (data) => insertMonitoring(data),
        onSuccess: (createdId) => {
            toast.show({
                summary:
                    localization.endpoints.monitoring.upsert_monitor
                        .createSuccess,
                severity: 'success',
                life: 10000,
            });
            if (afterInsert) afterInsert(createdId);
        },
        onError: (err) => toast.show(getErrorToast(err.message, localization)),
    });

    const onSave = () => {
        const postData = {
            project_id: project._id,
            name: name,
            data: {
                network_url: networkUrl,
                video_url: videoUrl,
            },
        };
        insertMutation.mutate(postData);
    };

    const formValid = networkUrl !== '' && videoUrl !== '' && name !== '';

    return (
        <LightDialog
            className='w-1/3'
            header={localization.components.models.monitoring.button.new}
            footer={
                <div className='flex flex-row justify-end w-full'>
                    <Button
                        label={localization.components.common.button.cancel}
                        severity='danger'
                        onClick={props.onHide}
                    />
                    <Button
                        label={localization.components.common.button.save}
                        severity='success'
                        disabled={!formValid}
                        onClick={onSave}
                        loading={insertMutation.isPending}
                    />
                </div>
            }
            {...props}
        >
            <MonitorForm
                name={name}
                setName={setName}
                networkURL={networkUrl}
                videoURL={videoUrl}
                setNetworkURL={setNetworkUrl}
                setVideoURL={setVideoUrl}
                disableAll={insertMutation.isPending}
            />
        </LightDialog>
    );
};

export default InsertMonitorDialog;
