import { useLocalization } from 'hooks/context/useLocalization';
import { TargetGroupProps, TargetSimpleTableProps } from './types';
import { useProject } from 'hooks/context/project/useProject';
import { useState } from 'react';
import CMFileUpload, { CMFileType } from 'components/form/CMFileUpload';
import useInsertEvidence from 'hooks/mutations/evidence/useInsertEvidence';
import { Column } from 'primereact/column';
import { ParseTargetFormatted } from 'services/ether/case-manager/targets/types';
import useInitDataTableState from 'hooks/helpers/useInitDataTableState';
import CMPaginator from 'components/datatable/CMPaginator';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import TargetsUpload from '../TargetsUpload';
import TargetsMultiInsert from '../TargetsMultiInsert';
import { ObjectId } from 'bson';
import useFillEvidenceMetadataDialog from 'hooks/dialogs/evidence/useFillEvidenceMetadataDialog';
import { EvidenceMetadataFormData } from 'components/models/Evidences/EvidenceMetadataForm/types';
import { useAuth } from 'hooks/context/useAuth';
import LoadingMessage from 'components/misc/LoadingMessage';
import useGetOneAuthorizationConfig from 'hooks/queries/authorization-config/useGetOneAuthorizationConfig';

const TargetsSimpleTable: React.FC<TargetSimpleTableProps> = ({ targets }) => {
    const [localization] = useLocalization();

    const {
        pageOptions: { page, rows },
        setPageOptions,
    } = useInitDataTableState({
        pageOptions: {
            startingRow: 25,
        },
    });
    const offset = (page - 1) * rows;

    const sortedTargets = targets.sort((a, b) =>
        a.value > b.value ? 1 : b.value < a.value ? -1 : 0
    );

    const finalTargets = sortedTargets.slice(offset, offset + rows);

    return (
        <>
            <CMPaginator
                page={page}
                rows={rows}
                onPageChange={(e) => setPageOptions(e)}
                maxPages={Math.ceil(targets.length / rows)}
                hideRowsPerPage
                showRefresh={false}
            />
            <DataTable value={finalTargets} className='mb-2'>
                <Column
                    header={localization.models.target.singular}
                    field='value'
                    body={(data: ParseTargetFormatted) => {
                        return (
                            <div>
                                <span>{data.value}</span>
                                {data.warnings && data.warnings.length > 0 && (
                                    <div className='flex flex-col gap-2 text-red-400'>
                                        {data.warnings
                                            .map((w) => {
                                                const messages =
                                                    localization.endpoints
                                                        .target
                                                        .parse_target_file
                                                        .warning;
                                                switch (w) {
                                                    case 'duplicated':
                                                        return messages.duplicatedTarget;
                                                    case 'authority_accepted':
                                                        return messages.authorityAccepted;
                                                    case 'blocked':
                                                        return messages.blocked;
                                                }
                                                return w;
                                            })
                                            .map((m) => (
                                                <span key={m}>{m}</span>
                                            ))}
                                    </div>
                                )}
                            </div>
                        );
                    }}
                />
                <Column
                    header={localization.models.tag.plural}
                    body={(data: ParseTargetFormatted) => {
                        return (
                            <pre>
                                {Object.keys(data.tags).length <= 0 && '-'}
                                {Object.entries(data.tags)
                                    .map(
                                        ([category, value]) =>
                                            `${category}: ${value}`
                                    )
                                    .join('\n')}
                                {data.errors && data.errors.length > 0 && (
                                    <div className='flex flex-col text-red-400'>
                                        <span>
                                            {
                                                localization.components.models
                                                    .authorization.views.insert
                                                    .invalidTags
                                            }
                                        </span>
                                        {data.errors.map((e, i) => (
                                            <span key={e.error + i}>
                                                {e.name
                                                    ? `${e.category}/${e.name} - `
                                                    : `${localization.fields.tag.category} ${e.category} - `}
                                                {e.error}
                                            </span>
                                        ))}
                                    </div>
                                )}
                            </pre>
                        );
                    }}
                />
                <Column
                    header={localization.common.metadata}
                    body={(data: ParseTargetFormatted) => {
                        const entries = Object.entries(data.meta);
                        if (entries.length <= 0) return '-';
                        return (
                            <div className='flex flex-col gap-1'>
                                {Object.entries(data.meta).map(
                                    ([key, value]) => (
                                        <span key={`${key}/${value}`}>
                                            {key}:{' '}
                                            {value instanceof Date
                                                ? value.toISOString()
                                                : value.toString()}
                                        </span>
                                    )
                                )}
                            </div>
                        );
                    }}
                />
            </DataTable>
        </>
    );
};

const TargetGroup: React.FC<TargetGroupProps> = ({
    targets,
    evidences,
    setTargets,
    addEvidences,
    removeEvidence,
    showEvidenceMetadataSelection,
    authorizationConfigId,
    disabled,
    onUploadStart,
    onUploadEnd,
    hideUpload,
}) => {
    const [localization] = useLocalization();
    const project = useProject();
    const { permissions } = useAuth();

    const [mode, setMode] = useState<string | null>(null);

    const [internalFiles, setInternalFiles] = useState<CMFileType[]>([]);
    const [filesPreMetadata, setFilesPreMetadata] = useState<File[] | null>(
        null
    );

    const { data: authorizationConfig, status: authorizationConfigStatus } =
        useGetOneAuthorizationConfig({
            _id: authorizationConfigId,
            project_id: project._id,
        });

    const { cancelUpload, uploadEvidences, uploadStatus } = useInsertEvidence({
        onSettled: () => {
            if (onUploadEnd) onUploadEnd();
            setInternalFiles([]);
        },
        onUpload: ({ okEvidences }) => {
            const evidencesToAppend = okEvidences
                .filter((e) =>
                    internalFiles.find(
                        (f) =>
                            f.status === 'uploading' &&
                            f.custom_identifier === e.custom_identifier
                    )
                )
                .map((e) => ({
                    _id: e.data._id,
                    status: 'done' as 'done',
                    meta: {
                        ...e.data.file,
                        name: e.data.file.name ?? '',
                        size: e.data.file.size,
                        filename: e.data.file.name,
                    },
                    custom_identifier: e.custom_identifier,
                }));
            if (addEvidences) addEvidences(evidencesToAppend);
        },
    });

    const onFileRemove = (file: CaseManagerApp.LocalFile.BaseDetails) => {
        const indexToRemove = evidences.findIndex(
            (e) => e.custom_identifier === file.custom_identifier
        );
        if (indexToRemove != null && removeEvidence)
            removeEvidence(indexToRemove);
        setInternalFiles((old) => {
            const newInternalFiles = old.filter(
                (f) => f.custom_identifier !== file.custom_identifier
            );
            if (newInternalFiles.length <= 0) {
                if (onUploadEnd) onUploadEnd();
                cancelUpload();
            }
            return newInternalFiles;
        });
    };

    const onTargetsChoice = (targets: ParseTargetFormatted[]) => {
        setMode(null);
        // const appendedTargets: Record<string, true> = {};
        // // dedup targets, just in case
        if (setTargets)
            setTargets(
                // targets.filter((t) => {
                //     if (!(t.value in appendedTargets)) {
                //         appendedTargets[t.value] = true;
                //         return true;
                //     }
                //     return false;
                // }),
                targets
            );
    };

    const handleEvidencesUpload = ({
        files,
        metadata,
    }: {
        files: File[];
        metadata?: EvidenceMetadataFormData;
    }) => {
        const filesToUpload = files.map((f) => ({
            _id: null,
            custom_identifier: new ObjectId().toHexString(),
            status: 'uploading' as 'uploading',
            file: f,
            meta: {
                name: f.name,
                size: f.size,
                type: f.type,
                ...(metadata ?? {}),
            },
        }));
        uploadEvidences({
            project_id: project._id,
            files: filesToUpload,
        });
        setInternalFiles((old) => {
            const newFiles = [...old, ...filesToUpload];
            return newFiles;
        });
        if (onUploadStart) onUploadStart();
    };

    const { dialog: evidenceMetadataDialog, show: showEvidenceMetadataDialog } =
        useFillEvidenceMetadataDialog({
            onFormSubmit: (data) => {
                if (!filesPreMetadata) return;
                handleEvidencesUpload({
                    files: filesPreMetadata,
                    metadata: data,
                });
            },
        });

    if (authorizationConfigStatus !== 'success')
        return <LoadingMessage>{localization.common.loading}</LoadingMessage>;

    if (!authorizationConfig)
        throw new Error('expected valid authorization config');

    return (
        <>
            {evidenceMetadataDialog}
            <h3>
                {localization.components.models.authorization.views.insert.totalTargets.replace(
                    '{count}',
                    targets.length.toString()
                )}
            </h3>
            <TargetsSimpleTable targets={targets} />
            {!!setTargets && !mode && (
                <>
                    <p>
                        {
                            localization.components.models.authorization.views
                                .insert.targetModeSelection
                        }
                    </p>
                    <div className='flex flex-row gap-2'>
                        <Button
                            label={
                                localization.components.models.authorization
                                    .views.insert.targetsButtonUpload
                            }
                            onClick={() => setMode('upload')}
                        />
                        {permissions.parseTargetList && (
                            <Button
                                label={
                                    localization.components.models.authorization
                                        .views.insert.targetsButtonManual
                                }
                                onClick={() => setMode('text')}
                            />
                        )}
                    </div>
                </>
            )}
            {!!mode && (
                <Button
                    className='mb-2'
                    label={localization.components.common.button.back}
                    onClick={() => setMode(null)}
                />
            )}
            {mode === 'upload' && (
                <TargetsUpload
                    setTargets={onTargetsChoice}
                    authorizationConfigId={authorizationConfigId}
                    disabled={disabled}
                />
            )}
            {mode === 'text' && (
                <TargetsMultiInsert
                    appendTargets={(t) => onTargetsChoice([...targets, ...t])}
                    setTargets={(t) => onTargetsChoice(t)}
                    authorizationConfigId={authorizationConfigId}
                    disabled={disabled}
                />
            )}
            <h3>{localization.models.evidence.plural}</h3>
            <p>
                {
                    localization.components.models.authorization.views.insert
                        .helpInsertEvidences
                }
            </p>
            <CMFileUpload
                value={[...evidences, ...internalFiles]}
                multiple
                removeFile={removeEvidence ? onFileRemove : undefined}
                customUpload
                hideUploadButton={hideUpload}
                hideDragAndDrop={hideUpload}
                onFileSelection={({ files }) => {
                    setFilesPreMetadata(files);
                    if (showEvidenceMetadataSelection)
                        showEvidenceMetadataDialog(authorizationConfig);
                    else
                        handleEvidencesUpload({
                            files,
                        });
                }}
                disabled={
                    disabled || uploadStatus === 'pending' || !addEvidences
                }
            />
        </>
    );
};

export default TargetGroup;
export { TargetsSimpleTable };
