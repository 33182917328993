import useListAuthorizationFlow from 'hooks/queries/authorization-flow/useListAuthorizationFlow';
import { useLocalization } from 'hooks/context/useLocalization';
import { useProject } from 'hooks/context/project/useProject';
import SelectOneItem from 'components/form/SelectItems/SelectOneItem';
import { SelectOneItemProps } from 'components/form/SelectItems/types';
import { ListAuthorizationFlowEP } from 'services/ether/case-manager/authorization-flow/types';

const SelectOneAuthorizationFlow: React.FC<
    SelectOneItemProps<
        Ether.CaseManager.AuthorizationFlow,
        ListAuthorizationFlowEP.Filters.Options
    >
> = (props) => {
    const [localization] = useLocalization();
    const project = useProject();

    const query = useListAuthorizationFlow({
        project_id: project._id,
        options: { ...props.queryOptions, limit: 9999 },
    }, {
        enabled : !('overrideItems' in props),
    });

    return (
        <SelectOneItem
            title={localization.models.authorizationFlow.singular}
            placeholder={
                localization.components.models.authorizationFlow.form
                    .selectOnePlaceholder
            }
            label={localization.models.authorizationFlow.singular}
            items={query.data?.payload}
            itemsLoading={query.isFetching}
            {...props}
        />
    );
};

export default SelectOneAuthorizationFlow;
