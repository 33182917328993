import { DataTableSortMeta } from 'primereact/datatable';
import { DirectApiFilters, FilterType } from './types';
import api from 'services/ether/api';
import downloadFromUrl from 'utils/downloadFromUrl';
import { getFileExtension } from './utils';

export namespace FileDownload {
    type BaseParams = {
        endpoint: string;
        params: Record<string, string | undefined> | undefined;
        signal?: AbortSignal;
    };

    export namespace Blob {
        export type Item = {
            file: Blob;
            filename: string | null;
            extension: string | null;
            contentDisposition: string | null;
            contentType: string | null;
            size: number;
            internalBlobUrl: string;
        };
        export type FetchParams = BaseParams;
        export type DownloadParams = FetchParams & {
            filename: string;
            fallbackExtension: string;
        };
    }

    export namespace Stream {
        export type ValidOperations = 'init' | 'generating' | 'uploading';
        export type Item = {
            url: string | null;
            error: string | null;
        };
        export type FetchParams = BaseParams & {
            onProgressUpdate?: (data: {
                operation: ValidOperations;
                progress: number;
            }) => void;
        };
        export type DownloadParams = FetchParams & {
            filename: string;
        };
    }
}

export function fileDownloadBlobBase({
    endpoint,
    params,
    signal,
}: FileDownload.Blob.FetchParams) {
    return new Promise<FileDownload.Blob.Item>((resolve, reject) => {
        api.get<string>(endpoint, {
            params: params,
            responseType: 'arraybuffer',
            signal,
        })
            .then((result) => {
                // console.log('Header que consigo ver!');
                // Object.entries(result.headers).forEach(([k, v]) =>
                //     console.log(`${k} : ${v}`)
                // );
                const filename = result.headers['x-filename'] as string;
                const contentType = result.headers['content-type'] as string;
                const contentDisposition = result.headers[
                    'content-disposition'
                ] as string;
                const fileExtension = getFileExtension({
                    filename: filename,
                    contentDisposition,
                    contentType,
                });
                const blob = new Blob([result.data], { type: contentType });
                const internalBlobUrl = URL.createObjectURL(blob);
                resolve({
                    file: blob,
                    filename: filename ?? null,
                    extension: fileExtension ?? null,
                    contentDisposition: contentDisposition ?? null,
                    contentType: contentType ?? null,
                    size: blob.size,
                    internalBlobUrl,
                });
            })
            .catch((err) => reject(err));
    });
}

export async function fileStream({
    endpoint,
    params,
    signal,
    onProgressUpdate,
}: FileDownload.Stream.FetchParams) {
    const handleProgress: NonNullable<
        FileDownload.Stream.DownloadParams['onProgressUpdate']
    > = (data) => {
        if (!onProgressUpdate) return;
        onProgressUpdate(data);
    };
    return new Promise<FileDownload.Stream.Item>((resolve, reject) => {
        const handleAnswer = (
            data: string
        ): FileDownload.Stream.Item | null => {
            const regexData = /([^\n]*\n[^\n]*$)/.exec(data);
            if (!regexData) throw new Error('[fileStream] missing regexData or Unhandled Error');
            const jsonData = JSON.parse(regexData[0]);
            const { op } = jsonData;
            switch (op) {
                case 'init':
                    handleProgress({
                        operation: 'init',
                        progress: jsonData.total_progress,
                    });
                    return null;
                case 'generating':
                    handleProgress({
                        operation: 'generating',
                        progress: jsonData.total_progress,
                    });
                    return null;
                case 'uploading':
                    handleProgress({
                        operation: 'uploading',
                        progress: jsonData.total_progress,
                    });
                    return null;
                case 'done':
                    return {
                        url: jsonData.url,
                        error: null,
                    };
                case 'error':
                    return {
                        error: jsonData.error,
                        url: null,
                    };
                default:
                    throw new Error('unhandled op on fileDownloadStreaming');
            }
        };
        api.get<string>(endpoint, {
            params: params,
            responseType: 'text',
            onDownloadProgress: (data) => {
                const event = data.event as any;
                const text = event.target.responseText as string;
                handleAnswer(text);
            },
            timeout: 30 * 60 * 1000,
            signal,
        })
            .then((result) => {
                const text = result.data;
                const res = handleAnswer(text);
                if (!res) reject(new Error('[fileStream] unexpected end'));
                else resolve(res);
            })
            .catch((err) => reject(err));
    });
}

export async function fileDownloadBase({
    endpoint,
    params,
    signal,
    filename,
    fallbackExtension,
}: FileDownload.Blob.DownloadParams) {
    const data = await fileDownloadBlobBase({
        endpoint,
        params,
        signal,
    });
    filename = data.filename ?? filename;
    let filenameWithoutExtension: string;
    if (filename.includes('.')) {
        const split = filename.split('.');
        split.pop();
        filenameWithoutExtension = split.join('.');
    } else {
        filenameWithoutExtension = filename;
    }
    downloadFromUrl(
        data.internalBlobUrl,
        filenameWithoutExtension + '.' + (data.extension ?? fallbackExtension)
    );
    return true;
}

export async function fileStreamingDownload({
    endpoint,
    params,
    signal,
    filename,
    onProgressUpdate,
}: FileDownload.Stream.DownloadParams) {
    const data = await fileStream({
        endpoint,
        params: {
            ...params,
            _filename: filename,
        },
        signal,
        onProgressUpdate,
    });
    if (data.error) throw new Error(data.error);
    if (data.url) downloadFromUrl(data.url);
    return true;
}

export function listBase<Payload, Meta extends Object = {}>({
    endpoint,
    options,
    handleParams,
    signal,
}: {
    endpoint: string;
    options: DirectApiFilters | undefined;
    handleParams: (options: {
        filters?: {
            [key: string]: FilterType;
        };
        sort?: DataTableSortMeta | null;
    }) => Record<string, string>;
    signal?: AbortSignal;
}) {
    const filters = handleParams({
        filters: options?.filters,
        sort: options?.sort,
    });
    
    return new Promise<{
        payload : Payload,
        meta : Meta
    }>((resolve, reject) => {
        api.get<Ether.ApiResponse<Payload, Meta>>(endpoint, {
            signal: signal,
            params: {
                limit: options?.limit ?? 10,
                offset: options?.offset ?? 0,
                ...filters,
            },
        })
            .then((res) => {
                resolve({
                    payload : res.data.payload,
                    meta : res.data.meta,
                });
            })
            .catch((err) => reject(err));
    });
}

export function getApiBase<ReturnType>({
    endpoint,
    params,
    errorKey,
    signal,
}: {
    endpoint: string;
    params?: Record<string, string | number | boolean | null | undefined>;
    errorKey?: string;
    signal?: AbortSignal;
}) {
    const key = errorKey ?? 'error';
    return new Promise<ReturnType>((resolve, reject) => {
        api.get<Ether.ApiResponse<any>>(endpoint, {
            params: params,
            signal,
        })
            .then((res) => {
                const data = res.data.payload;
                if (typeof data === 'object' && key in data && !!data[key]) {
                    reject(new Error(data.error));
                    return;
                }
                resolve(data);
            })
            .catch((err) => reject(err));
    });
}

export function postApiBase<ReturnType>({
    endpoint,
    data,
    params,
    errorKey,
    dontExtractArray,
}: {
    endpoint: string;
    data: any;
    params?: Record<string, string | number | boolean>;
    errorKey?: string;
    dontExtractArray?: boolean;
}) {
    const key = errorKey ?? 'error';
    return new Promise<ReturnType>((resolve, reject) => {
        api.post<Ether.ApiResponse<any>>(endpoint, data, {
            params: params,
        })
            .then((res) => {
                const data = dontExtractArray
                    ? res.data.payload
                    : res.data.payload?.[0];
                if (typeof data === 'object' && key in data && !!data[key]) {
                    reject(new Error(data.error));
                    return;
                }
                resolve(data);
            })
            .catch((err) => reject(err));
    });
}
