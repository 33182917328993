import { useState } from 'react';
import SelectOneSubtag from '../SelectOneSubtag';
import { DetailTagEP } from 'services/ether/case-manager/tags/types';
import _ from 'lodash';

export type SubtagsSelectTag = {
    category: string;
    dependsOnCategory: Record<string, boolean>;
    tags: Ether.CaseManager.Subtag.Detailed[] | undefined;
};

const TagSubtagsSelect: React.FC<{
    tags: (Ether.CaseManager.Subtag.Detailed | null)[];
    setTags: React.Dispatch<
        React.SetStateAction<(Ether.CaseManager.Subtag.Detailed | null)[]>
    >;
    initialQueryOptions: DetailTagEP.Filters.Options;
    initialLabel: string;
}> = ({ tags, setTags, initialQueryOptions, initialLabel }) => {
    const [internalSubtags, setInternalSubtags] = useState<SubtagsSelectTag[]>([
        {
            category: 'initial',
            dependsOnCategory: {},
            tags: undefined,
        },
    ]);

    const handleRemoveTag = (index: number) => {
        const removedTag = tags[index];
        if (!removedTag) return;
        const removedIndexes: Record<number, boolean> = {};
        setInternalSubtags((old) => {
            const newSubtags = old.filter((subtag, index) => {
                const depends = subtag.dependsOnCategory[removedTag.category];
                if (depends) removedIndexes[index] = true;
                return !depends;
            });
            return newSubtags;
        });
        setTags((old) => {
            const newTags = old
                .map((tag) => {
                    if (!tag) return null;
                    if (tag._id === removedTag._id) return null;
                    return tag;
                })
                .filter((_, index) => !removedIndexes[index]);
            return newTags;
        });
    };

    const handleSetTag = (
        tag: Ether.CaseManager.Subtag.Detailed,
        index: number
    ) => {
        let changedTag: Ether.CaseManager.Subtag.Detailed | null = null;
        const removedIndexes: Record<number, boolean> = {};
        setTags((oldTags) => {
            changedTag = oldTags[index] ?? null;
            setInternalSubtags((oldSubtags) => {
                const newSubtags = oldSubtags.filter((subtag, index) => {
                    if (!changedTag) return true;
                    const depends =
                        subtag.dependsOnCategory[changedTag.category];
                    if (depends) removedIndexes[index] = true;
                    return !depends;
                });
                const updatedTag = oldSubtags[index];
                if (!updatedTag)
                    throw new Error('unhandled: expected tag to exist');
                tag.subtags.forEach((subtag) => {
                    newSubtags.push({
                        dependsOnCategory: {
                            [tag.category]: true,
                            ...updatedTag.dependsOnCategory,
                        },
                        category: subtag.category,
                        tags: subtag.tags,
                    });
                    oldTags.push(null);
                });
                return newSubtags;
            });
            oldTags[index] = tag;
            const newTags = oldTags.filter(
                (_, index) => !removedIndexes[index]
            );
            return newTags;
        });
    };

    const followingSubtags = internalSubtags.map((tag, index) => (
        <SelectOneSubtag
            key={tag.category}
            label={index !== 0 ? _.upperFirst(tag.category) : initialLabel}
            value={tags[index]?._id}
            onChange={(t) => {
                if (!t) handleRemoveTag(index);
                else handleSetTag(t, index);
            }}
            overrideItems={tag.tags}
            queryOptions={index === 0 ? initialQueryOptions : undefined}
            hideTitle={index !== 0}
            showClear
        />
    ));

    return <>{followingSubtags}</>;
};

export default TagSubtagsSelect;
