import { ReactNode, useCallback, useMemo, useState } from 'react';
import { DateBadge } from '../../resources/DateBadge';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

export interface IObjectDisplayProps {
    displayData: any;
    sortKeys?: boolean;
}

export const ObjectDisplay: React.FC<IObjectDisplayProps> = ({
    displayData,
    sortKeys = true,
}) => {
    const LargeObjectView: React.FC<{
        children?: React.ReactNode;
    }> = ({ children }) => {
        const [expand, setExpand] = useState(false);
        const content = JSON.stringify(children, null, 4);

        return (
            <div>
                {expand && (
                    <Button
                        style={{ fontSize: '14px' }}
                        label='Hide'
                        icon='pi pi-minus'
                        onClick={() => setExpand(false)}
                    />
                )}
                <pre
                    style={{
                        fontSize: '12px',
                        lineHeight: '16px',
                        maxHeight: expand ? '100%' : '160px',
                        overflowY: expand ? 'auto' : 'hidden',
                    }}
                >
                    {content}
                </pre>
                {content.split('\n').length > 10 && !expand && (
                    <Button
                        style={{ fontSize: '14px' }}
                        label='Show all'
                        icon='pi pi-plus'
                        onClick={() => setExpand(true)}
                    />
                )}
            </div>
        );
    };

    const getFieldBody = useCallback((value: unknown): unknown => {
        if (value == null) return <Badge value='null' />;
        switch (typeof value) {
            case 'string':
                try {
                    JSON.parse(value);
                    return (
                        <LargeObjectView>{value as ReactNode}</LargeObjectView>
                    );
                } catch {
                    return value;
                }
            case 'object':
                if (value instanceof Date)
                    return <DateBadge value={value as Date} />;
                return <LargeObjectView>{value as ReactNode}</LargeObjectView>;
            case 'boolean':
                return (
                    <Badge
                        value={
                            value ? 'true' : value == null ? 'null' : 'false'
                        }
                        severity={
                            value
                                ? 'success'
                                : value != null
                                ? 'danger'
                                : undefined
                        }
                    />
                );
            default:
                return value;
        }
    }, []);

    const rowData: { field: string; value: unknown }[] = useMemo(() => {
        if (!displayData) return [];
        const newRowData: {
            field: string;
            value: unknown;
        }[] = [];
        Object.keys(displayData).forEach((key) => {
            newRowData.push({
                field: key,
                value: getFieldBody(displayData[key]),
            });
        });
        if (sortKeys) {
            newRowData.sort((a, b) => {
                if (a.field > b.field) return 1;
                if (b.field > a.field) return -1;
                return 0;
            });
        }
        return newRowData;
    }, [displayData, getFieldBody, sortKeys]);

    return (
        <DataTable value={rowData}>
            <Column
                style={{ maxWidth: '10ch', wordWrap: 'break-word' }}
                field='field'
                header='Field'
            />
            <Column style={{ maxWidth: '20ch' }} field='value' header='Value' />
        </DataTable>
    );
};

export default ObjectDisplay;
