import useQueryWithRefresh from 'hooks/helpers/useQueryWithRefresh/useQueryWithRefresh';
import { detailManyDocuments } from 'services/ether/case-manager/documents';
import { DetailDocumentEP } from 'services/ether/case-manager/documents/types';

const useDetailDocument = (
    params: DetailDocumentEP.Params.Many,
    queryOptions?: {
        enabled?: boolean;
    }
) => {
    const queryKey = ['document', 'detail', params];

    const query = useQueryWithRefresh({
        queryKey,
        queryFn: ({ signal }) =>
            detailManyDocuments({
                ...params,
                signal,
            }),
        ...queryOptions,
    });

    return query;
};

export default useDetailDocument;
