import LightDialog from 'components/display/LightDialog';
import SelectOneAuthorizationFlow from 'components/models/AuthorizationFlow/SelectOneAuthorizationFlow';
import { useProject } from 'hooks/context/project/useProject';
import { useLocalization } from 'hooks/context/useLocalization';
import useChangeAuthorizationFlow from 'hooks/mutations/authorization/useChangeAuthorizationFlow';
import useDetailOneAuthorizationConfig from 'hooks/queries/authorization-config/useDetailOneAuthorizationConfig';
import { Button } from 'primereact/button';
import {
    Dispatch,
    SetStateAction,
    useCallback,
    useMemo,
    useState,
} from 'react';

type ChangeAuthorizationFlowFormProps = {
    authorizationConfigId: string;
    selectedFlow: string;
    setSelectedFlow: Dispatch<SetStateAction<string>>;
};

const ChangeAuthorizationFlowForm: React.FC<
    ChangeAuthorizationFlowFormProps
> = ({ authorizationConfigId, selectedFlow, setSelectedFlow }) => {
    const project = useProject();
    const { data: authorizationConfig, isLoading } =
        useDetailOneAuthorizationConfig({
            _id: authorizationConfigId,
            project_id: project._id,
            options: {
                includeFlows: true,
            },
        });

    return (
        <SelectOneAuthorizationFlow
            required
            value={selectedFlow}
            onChange={(a) => {
                if (!a) return;
                setSelectedFlow(a._id);
            }}
            overrideItems={authorizationConfig?.authorization_flows_data}
            loading={isLoading}
            queryOptions={{
                devFilters: {
                    can_join_authorization: true,
                },
            }}
        />
    );
};

type Options = {
    authorizationId: string;
    authorizationName: string;
    authorizationConfigId: string;
    startingFlow: string;
};

const useChangeAuthorizationFlowDialog = () => {
    const [localization] = useLocalization();
    
    const [selectedFlow, setSelectedFlow] = useState<string>('');
    const [options, setOptions] = useState<Options | null>(null);
    const show = (options: Options) => {
        setOptions(options)
        setSelectedFlow(options.startingFlow)
    };
    const hide = () => setOptions(null);

    const onSuccess = () => {
        hide();
    };

    const { changeFlow, isLoading } = useChangeAuthorizationFlow({
        onChange: onSuccess,
    });


    const handleChange = useCallback(() => {
        if (!selectedFlow || !options?.authorizationId) return;
        changeFlow({
            authorization_flow_id: selectedFlow,
            authorization_id: options.authorizationId,
        });
    }, [changeFlow, options?.authorizationId, selectedFlow]);

    const Dialog = useMemo(
        () =>
            !!options && (
                <LightDialog
                    visible={true}
                    onHide={hide}
                    header={options.authorizationName}
                    className='w-[50vw]'
                    footer={
                        <div>
                            <Button
                                label={
                                    localization.components.common.button.cancel
                                }
                                onClick={hide}
                            />
                            <Button
                                label={
                                    localization.components.common.button.save
                                }
                                disabled={!selectedFlow}
                                loading={isLoading}
                                onClick={handleChange}
                                severity='success'
                            />
                        </div>
                    }
                >
                    <ChangeAuthorizationFlowForm
                        authorizationConfigId={options.authorizationConfigId}
                        selectedFlow={selectedFlow}
                        setSelectedFlow={setSelectedFlow}
                    />
                </LightDialog>
            ),
        [handleChange, isLoading, localization, options, selectedFlow]
    );

    return {
        dialog: Dialog,
        show,
        isLoading: isLoading,
    };
};

export default useChangeAuthorizationFlowDialog;
