import useQueryWithRefresh from 'hooks/helpers/useQueryWithRefresh/useQueryWithRefresh';
import {
    detailManySubtags,
} from 'services/ether/case-manager/tags';
import { DetailSubtagEP } from 'services/ether/case-manager/tags/types';

const useDetailSubtag = (
    params: DetailSubtagEP.Params.Many,
    queryOptions?: {
        enabled?: boolean;
    }
) => {
    const queryKey = ['tag', 'detail', params];

    const query = useQueryWithRefresh({
        queryKey,
        queryFn: ({ signal }) => detailManySubtags({ ...params, signal }),
        ...queryOptions,
    });

    return query;
};

export default useDetailSubtag;
