import { useLocalization } from 'hooks/context/useLocalization';
import { useProject } from 'hooks/context/project/useProject';
import useDetailBlockOrder from 'hooks/queries/block-order/useDetailBlockOrder';
import SelectOneItem from 'components/form/SelectItems/SelectOneItem';
import { DetailBlockOrderEP } from 'services/ether/case-manager/block-orders/types';
import { SelectOneItemProps } from 'components/form/SelectItems/types';

const SelectOneBlockOrder: React.FC<
    SelectOneItemProps<
        Ether.CaseManager.BlockOrder.Detailed,
        DetailBlockOrderEP.Filters.Options
    >
> = (props) => {
    const [localization] = useLocalization();
    const project = useProject();
    const query = useDetailBlockOrder(
        {
            project_id: project._id,
            options: { ...props.queryOptions, limit: 9999 },
        },
        'list',
        { enabled: !props.overrideItems }
    );

    return (
        <SelectOneItem
            title={localization.models.blockOrder.singular}
            placeholder={
                localization.components.models.blockOrder.form
                    .selectOnePlaceholder
            }
            label={localization.models.blockOrder.singular}
            items={query.data?.payload}
            itemsLoading={query.isFetching}
            {...props}
        />
    );
};

export default SelectOneBlockOrder;
