import { Button } from 'primereact/button';
import SelectOneOperation from '../SelectOneOperation';
import SetAsDefaultCheckbox from 'components/form/SetAsDefaultCheckbox';
import { useLocalization } from 'hooks/context/useLocalization';
import { SelectOrCreateOperationProps } from './types';
import useCreateOperationDialog from 'hooks/models/operation/useCreateOperationDialog';
import { useAuth } from 'hooks/context/useAuth';

const SelectOrCreateOperation: React.FC<SelectOrCreateOperationProps> = ({
    operationId,
    setOperationId,
    isDefaultOperation,
    setIsDefaultOperation,
    ignoreDefaultInit,
    required,
    disabled,
}) => {
    const [localization] = useLocalization();
    const { user, permissions } = useAuth();

    const { element: UpsertDialog, show } = useCreateOperationDialog({
        onCreate: (id) => setOperationId(id),
    });

    return (
        <div className='flex flex-col gap-2'>
            <UpsertDialog />
            <SelectOneOperation
                value={operationId}
                onChange={(op) => setOperationId(op?._id ?? null)}
                onLoad={(operations) => {
                    if (ignoreDefaultInit) return;
                    const userOpId = user.data?.data?.default_operation_id;
                    if (!!operationId || !userOpId) return;
                    const targetOp = operations.find(
                        (op) => op._id === userOpId
                    );
                    if (targetOp) setOperationId(userOpId);
                }}
                showClear
                required={required}
                disabled={disabled}
            />
            {permissions.insertOperation && (
                <Button
                    icon='pi pi-plus'
                    label={localization.components.models.operation.button.new}
                    onClick={show}
                    disabled={disabled}
                />
            )}
            {setIsDefaultOperation && isDefaultOperation !== undefined && (
                <SetAsDefaultCheckbox
                    checked={isDefaultOperation}
                    onChange={(e) => setIsDefaultOperation(!!e.target.checked)}
                    disabled={disabled}
                />
            )}
        </div>
    );
};

export default SelectOrCreateOperation;
