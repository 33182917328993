import { useState } from 'react';

import { Button } from 'primereact/button';
import { Datepicker, InputText } from 'components/ethercity-primereact';
import { useProject } from 'hooks/context/project/useProject';
import { useLocalization } from 'hooks/context/useLocalization';
import { Divider } from 'primereact/divider';
import { InsertBlockOrderForm, InsertBlockOrderModalProps } from './types';
import SelectManyOperatorTags from 'components/models/Operators/SelectManyOperatorTags';
import useUpsertBlockOrder from 'hooks/mutations/blockOrder/useUpsertBlockOrder';
import SelectManyAuthorizations from 'components/models/Authorization/SelectManyAuthorizations';
import SelectOneAuthorizationFlow from 'components/models/AuthorizationFlow/SelectOneAuthorizationFlow';
import { UpsertBlockOrderEP } from 'services/ether/case-manager/block-orders/types';
import SelectManyAuthorizationConfigs from 'components/models/AuthorizationConfig/SelectManyAuthorizationConfigs';
import OrderDocTypeDropdown from 'components/form/OrderDocTypeDropdown';
import { updateUserDefaultOperation } from 'services/ether/case-manager/operation';
import SelectOrCreateOperation from 'components/models/Operation/SelectOrCreateOperation';
import { CheckActionTargetWarningEP } from 'services/ether/case-manager/targets/types';
import { checkActionTargetWarning } from 'services/ether/case-manager/targets';
import useShowTargetCheckWarning from 'hooks/dialogs/target/useShowTargetCheckWarning';
import { useToast } from 'hooks/context/useToast';
import { getErrorToast } from 'utils/errorHandler';
import LightDialog from 'components/display/LightDialog';

const InsertBlockOrderModal: React.FC<InsertBlockOrderModalProps> = ({
    blockOrderToEdit,
    onInsert,
    refAuthorization,
    ...props
}) => {
    const project = useProject();
    const toast = useToast();
    const [localization] = useLocalization();
    const [loadingReviewTargets, setLoadingReviewTargets] = useState(false);

    const isEdit = !!blockOrderToEdit;

    const [formData, setFormData] = useState<InsertBlockOrderForm>({
        _id: blockOrderToEdit?._id ?? null,
        authorization_flow_id:
            blockOrderToEdit?.authorization_flow_id ??
            refAuthorization?.authorization_flow_id ??
            null,
        authorization_config_ids:
            blockOrderToEdit?.authorization_config_ids ?? [
                ...(refAuthorization?.authorization_config_id
                    ? [refAuthorization.authorization_config_id]
                    : []),
            ],
        name: blockOrderToEdit?.name ?? refAuthorization?.name ?? '',
        type: blockOrderToEdit?.type ?? null,
        authorizations: blockOrderToEdit?.authorizations_data
            ? blockOrderToEdit.authorizations_data.map((a) => ({
                  _id: a._id,
                  name: a.name,
                  config_id: a.authorization_config_id,
              }))
            : [
                  ...(refAuthorization
                      ? [
                            {
                                _id: refAuthorization._id,
                                name: refAuthorization.name,
                                config_id:
                                    refAuthorization.authorization_config_id,
                            },
                        ]
                      : []),
              ],
        operator_tags: blockOrderToEdit?.operator_tags ?? [],
        start_date: blockOrderToEdit?.start_date ?? null,
        start_hour: blockOrderToEdit?.start_date ?? null,
        end_hour: blockOrderToEdit?.complete_date ?? null,
        end_date: blockOrderToEdit?.end_date ?? null,
        operation_id: blockOrderToEdit?.operation_id ?? null,
    });

    const onUpsertOrder = () => {
        if (onInsert) onInsert();
        props.onHide();
    };

    const {
        insertBlockOrder,
        updateBlockOrder,
        status: upsertStatus,
    } = useUpsertBlockOrder({ onInsert: onUpsertOrder });

    const { dialog: reviewTargetsDialog, show: showReviewTargetsWarning } =
        useShowTargetCheckWarning();

    const [selectedAuthorizationFlow, setSelectedAuthorizationFlow] =
        useState<Ether.CaseManager.AuthorizationFlow | null>(null);
    const allowHourSelection =
        !!selectedAuthorizationFlow &&
        !selectedAuthorizationFlow.block_template_config?.complete_time &&
        !selectedAuthorizationFlow.block_template_config?.start_time;

    const validateInsert = async (
        data: InsertBlockOrderForm,
        status: 'draft' | 'done'
    ) => {
        const shouldVerifyReview =
            status === 'done' &&
            (blockOrderToEdit?.total_targets_review == null ||
                blockOrderToEdit.total_targets_review > 0);
        if (shouldVerifyReview) {
            setLoadingReviewTargets(true);

            const filters: CheckActionTargetWarningEP.Data =
                blockOrderToEdit?._id
                    ? {
                          block_order_id: blockOrderToEdit._id,
                          action: 'finish_block_order',
                      }
                    : {
                          authorization_ids: data.authorizations.map(
                              (a) => a._id
                          ),
                          operation_id: data.operation_id,
                          action: 'finish_block_order',
                      };

            try {
                const targets = await checkActionTargetWarning({
                    ...filters,
                });
                setLoadingReviewTargets(false);
                if (targets.length > 0) {
                    showReviewTargetsWarning({
                        targets,
                        onContinue: () => handleOnInsert(data, status),
                        action: 'finishBlockOrder',
                    });
                    return;
                } else handleOnInsert(data, status);
            } catch (err) {
                toast.show(getErrorToast((err as Error).message, localization));
                setLoadingReviewTargets(false);
            }
        } else {
            handleOnInsert(data, status);
        }
    };

    const handleOnInsert = (
        data: InsertBlockOrderForm,
        status: 'draft' | 'done'
    ) => {
        if (!data.type || !data.authorization_flow_id) return;
        if (
            data.authorization_config_ids.length <= 0 ||
            data.authorizations.length <= 0
        )
            return;
        if (!formData.start_date) return;

        if (isDefaultOperation && formData.operation_id)
            updateUserDefaultOperation({
                operation_id: formData.operation_id,
            });

        const start_date = new Date(formData.start_date);
        let complete_date = null;
        if (allowHourSelection) {
            const { start_hour, end_hour } = formData;
            if (!start_hour || !end_hour) return;
            complete_date = new Date(formData.start_date);
            start_date.setHours(
                start_hour.getHours(),
                start_hour.getMinutes(),
                0,
                0
            );
            complete_date.setHours(
                end_hour.getHours(),
                end_hour.getMinutes(),
                0,
                0
            );
        }

        const uploadData: UpsertBlockOrderEP.InsertData = {
            authorization_flow_id: data.authorization_flow_id,
            authorization_config_ids: data.authorization_config_ids,
            project_id: project._id,
            name: data.name,
            type: data.type,
            authorization_ids: formData.authorizations.map((a) => a._id),
            operator_tags: formData.operator_tags,
            status: status,
            start_date: start_date,
            complete_date: complete_date,
            end_date: formData.end_date,
            operation_id: formData.operation_id,
        };
        if (data._id) {
            updateBlockOrder({
                _id: data._id,
                ...uploadData,
            });
        } else insertBlockOrder(uploadData);
    };

    const [isDefaultOperation, setIsDefaultOperation] = useState(false);

    const invalidTime =
        !!formData.start_hour &&
        !!formData.end_hour &&
        formData.start_hour > formData.end_hour;
    const isFormValid =
        !invalidTime &&
        !!formData.authorization_flow_id &&
        formData.authorization_config_ids.length > 0 &&
        formData.name !== '' &&
        formData.authorizations.length > 0 &&
        !!formData.type &&
        !!formData.start_date &&
        (allowHourSelection
            ? !!formData.start_hour && !!formData.end_hour
            : true);

    const upsertLoading = upsertStatus === 'pending' || loadingReviewTargets;

    return (
        <LightDialog
            {...props}
            header={
                !!blockOrderToEdit
                    ? localization.components.models.blockOrder.button.edit
                    : localization.components.models.blockOrder.button.new
            }
            style={{ minWidth: '50%' }}
            footer={
                <>
                    <Button
                        label={
                            localization.components.common.button.saveAndSubmit
                        }
                        severity='success'
                        onClick={() => validateInsert(formData, 'done')}
                        disabled={!isFormValid || upsertLoading}
                    />
                    <Button
                        label={
                            localization.components.common.button.saveAndDraft
                        }
                        onClick={() => handleOnInsert(formData, 'draft')}
                        disabled={!isFormValid || upsertLoading}
                    />
                    <Button
                        label={localization.components.common.button.cancel}
                        severity='danger'
                        onClick={props.onHide}
                    />
                </>
            }
        >
            <div className='grid gap-2 grid-cols-1'>
                {reviewTargetsDialog}
                <h3>{localization.models.blockOrder.singular}</h3>
                <InputText
                    placeholder={
                        localization.components.models.blockOrder.views.insert
                            .inputBlockOrderName
                    }
                    required
                    label={localization.fields.blockOrder.name}
                    value={formData.name}
                    onChange={(e) =>
                        setFormData((old) => ({
                            ...old,
                            name: e.target.value,
                        }))
                    }
                    validations={[
                        {
                            validationError:
                                localization.validations.generic.requiredName,
                            validate: (value) => {
                                if (!value || value === '') return false;
                                return true;
                            },
                        },
                    ]}
                    disabled={upsertLoading}
                />
                {!refAuthorization && <Divider />}
                <SelectOneAuthorizationFlow
                    required
                    disabled={upsertLoading}
                    value={formData.authorization_flow_id}
                    onLoad={(flows) =>
                        setSelectedAuthorizationFlow(
                            flows.find(
                                (f) => f._id === formData.authorization_flow_id
                            ) ?? null
                        )
                    }
                    onChange={(flow) => {
                        setSelectedAuthorizationFlow(flow);
                        setFormData((old) => ({
                            ...old,
                            authorization_flow_id: flow?._id ?? null,
                            authorization_config_ids: [],
                            authorizations: [],
                        }));
                    }}
                    hidden={!!refAuthorization}
                />
                {!!selectedAuthorizationFlow && (
                    <>
                        <Divider />
                        <SelectOrCreateOperation
                            operationId={formData.operation_id}
                            setOperationId={(id) =>
                                setFormData((old) => ({
                                    ...old,
                                    operation_id: id,
                                    // authorizations: [],
                                }))
                            }
                            isDefaultOperation={isDefaultOperation}
                            setIsDefaultOperation={(def) =>
                                setIsDefaultOperation(def)
                            }
                            ignoreDefaultInit={isEdit}
                        />
                        <Divider />
                        <span>
                            {
                                localization.components.models.blockOrder.views
                                    .insert.selectDates
                            }
                        </span>
                        <h3>
                            {localization.fields.blockOrder.operationStartDate}
                        </h3>
                        <div className='flex flex-row gap-2'>
                            <Datepicker
                                label={
                                    localization.fields.blockOrder.operationDay
                                }
                                value={formData.start_date}
                                onChange={(value) =>
                                    setFormData((old) => ({
                                        ...old,
                                        start_date: value,
                                    }))
                                }
                                type='date'
                                required
                            />
                            {allowHourSelection && (
                                <>
                                    <Datepicker
                                        label={
                                            localization.fields.blockOrder
                                                .operationStartHour
                                        }
                                        value={formData.start_hour}
                                        onChange={(value) =>
                                            setFormData((old) => ({
                                                ...old,
                                                start_hour: value,
                                            }))
                                        }
                                        type='time'
                                        required
                                        error={invalidTime}
                                    />
                                    <span>-</span>
                                    <Datepicker
                                        label={
                                            localization.fields.blockOrder
                                                .operationEndHour
                                        }
                                        value={formData.end_hour}
                                        onChange={(value) =>
                                            setFormData((old) => ({
                                                ...old,
                                                end_hour: value,
                                            }))
                                        }
                                        type='time'
                                        required
                                        error={invalidTime}
                                    />
                                </>
                            )}
                        </div>
                        {invalidTime && (
                            <span className='text-red-500'>
                                {
                                    localization.components.models.blockOrder
                                        .views.insert.invalidDate
                                }
                            </span>
                        )}
                        {!allowHourSelection && (
                            <span>
                                {localization.components.models.blockOrder.views.insert.noDateToSelect.replace(
                                    '{name}',
                                    selectedAuthorizationFlow?.name ?? ''
                                )}
                            </span>
                        )}
                        <h3>
                            {localization.fields.blockOrder.operationEndDate}
                        </h3>
                        <Datepicker
                            label={localization.fields.blockOrder.operationDay}
                            value={formData.end_date}
                            onChange={(value) =>
                                setFormData((old) => ({
                                    ...old,
                                    end_date: value,
                                }))
                            }
                            type='datetime-local'
                            required={false}
                        />
                        {!refAuthorization && <Divider />}
                        <SelectManyAuthorizationConfigs
                            value={formData.authorization_config_ids}
                            onChange={(configs) => {
                                const authorizations =
                                    formData.authorizations.filter(
                                        (a) =>
                                            !!configs.find(
                                                (c) => c._id === a.config_id
                                            )
                                    );
                                setFormData((old) => ({
                                    ...old,
                                    authorization_config_ids: configs.map(
                                        (c) => c._id
                                    ),
                                    authorizations: authorizations,
                                }));
                            }}
                            disabled={upsertLoading}
                            queryOptions={{
                                devFilters: {
                                    authorization_flow_ids:
                                        formData.authorization_flow_id,
                                },
                            }}
                            required
                            hidden={!!refAuthorization}
                        />
                    </>
                )}
                {!!selectedAuthorizationFlow &&
                    formData.authorization_config_ids.length > 0 && (
                        <>
                            <Divider />
                            <h3>{localization.common.type}</h3>
                            <OrderDocTypeDropdown
                                placeholder={
                                    localization.components.models.blockOrder
                                        .views.insert.selectTypePlaceholder
                                }
                                value={formData.type}
                                onChange={(e) =>
                                    setFormData((old) => ({
                                        ...old,
                                        type: e.target.value,
                                    }))
                                }
                                disabled={upsertLoading}
                            />
                            <Divider />
                            <SelectManyAuthorizations
                                value={formData.authorizations.map(
                                    (a) => a._id
                                )}
                                onChange={(authorizations) =>
                                    setFormData((old) => ({
                                        ...old,
                                        authorizations: authorizations.map(
                                            (a) => ({
                                                _id: a._id,
                                                name: a.name,
                                                config_id:
                                                    a.authorization_config_id,
                                            })
                                        ),
                                    }))
                                }
                                queryOptions={{
                                    devFilters: {
                                        authorization_config_id:
                                            formData.authorization_config_ids,
                                        authorization_flow_id:
                                            selectedAuthorizationFlow._id,
                                    },
                                    withoutBlockOrder: true,
                                }}
                                disabled={upsertLoading}
                                required
                                additionalItems={blockOrderToEdit?.authorizations_data?.filter(
                                    (a) =>
                                        !!formData.authorization_config_ids.find(
                                            (config) =>
                                                config ===
                                                a.authorization_config_id
                                        )
                                )}
                                hidden={!!refAuthorization}
                            />
                            {!refAuthorization && <Divider />}
                            <SelectManyOperatorTags
                                value={formData.operator_tags}
                                onChange={(tags) =>
                                    setFormData((old) => ({
                                        ...old,
                                        operator_tags: tags.map((t) => t.value),
                                    }))
                                }
                                disabled={upsertLoading}
                            />
                        </>
                    )}
            </div>
        </LightDialog>
    );
};

export default InsertBlockOrderModal;
