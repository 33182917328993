import { DataTableSortMeta } from 'primereact/datatable';
import {
    mapDevFilters,
    parseDataTableFilterMetaForAPI,
} from 'services/ether/utils';

import api from 'services/ether/api';
import { ImportOperatorEP, ListOperatorEP, DetailOperatorEP } from './types';
import { listBase } from 'services/ether/base';
import { ParamFiltersMeta } from 'services/ether/types';
import _ from 'lodash';

const handleOperatorFilters = ({
    filters,
    sort,
}: {
    filters?: ParamFiltersMeta;
    sort?: DataTableSortMeta | null;
}) => {
    return parseDataTableFilterMetaForAPI({
        filters,
        sort,
        nameField: 'contact.email',
    });
};

export const listOperatorTags = ({
    project_id,
    signal,
}: ListOperatorEP.Params.Base) => {
    return listBase<string[]>({
        endpoint: '/list-unique-operator-tags',
        handleParams: handleOperatorFilters,
        options: {
            filters: {
                project_id,
            },
        },
        signal,
    });
};

const _detailOperators = ({
    project_id,
    options,
    signal,
}: DetailOperatorEP.Params.Restricted) => {
    const devFilters = options?.devFilters ?? {};
    const devKeys: DetailOperatorEP.Filters.Map = {
        _id: ['_id', devFilters._id],
        block_order_id: ['block_order_id', devFilters.block_order_id],
        unblock_order_id: ['unblock_order_id', devFilters.unblock_order_id],
        authorization_config_id: [
            'authorization_config_id',
            devFilters.authorization_config_id,
        ],
        target_value: ['target_value', devFilters.target_value],
        company_id: ['company_id', devFilters.company_id],
        additional_fields: ['additional_fields', devFilters.additional_fields],
    };
    const mappedFilters = mapDevFilters(devKeys);
    const filters = _.merge(
        {
            project_id,
        },
        options?.rawFilters,
        mappedFilters
    );
    return listBase<Ether.CaseManager.Operator.Detailed[]>({
        endpoint: '/detail-operator',
        handleParams: handleOperatorFilters,
        options: {
            ...options,
            filters,
        },
        signal,
    });
};

export const detailManyOperators = ({
    project_id,
    options,
    signal,
}: DetailOperatorEP.Params.Many) => {
    return _detailOperators({
        project_id,
        options: {
            ...options,
            devFilters: {
                ...options?.devFilters,
                additional_fields: ['users_data'],
            },
        },
        signal,
    });
};

export const detailOneOperator = async ({
    _id,
    project_id,
    signal,
}: DetailOperatorEP.Params.One) => {
    const { payload } = await _detailOperators({
        project_id,
        options: {
            devFilters: {
                _id: _id,
            },
        },
        signal,
    });
    return payload[0] ?? null;
};

export const importOperator = async ({
    project_id,
    file,
}: ImportOperatorEP.Data) => {
    const formData = new FormData();
    formData.append('project_id', project_id);
    formData.append('file', file);

    const result = await api.post<Ether.ApiResponse<ImportOperatorEP.Result[]>>(
        '/import-operator',
        formData
    );

    const data = result.data.payload[0];
    if (!data) throw new Error('import-operator failed to retrieve result');
    return data;
};
