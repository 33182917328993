import { useQuery } from '@tanstack/react-query';
import { Dropdown } from 'components/ethercity-primereact';
import { useAuth } from 'hooks/context/useAuth';
import { useLocalization } from 'hooks/context/useLocalization';
import { Button } from 'primereact/button';
import { useState } from 'react';
import { listProjects } from 'services/ether/case-manager/projects';

const SelectProject: React.FC<{
    startingProject: string | null;
    onProjectSelection: (id: string) => void;
}> = ({ startingProject, onProjectSelection }) => {
    const { signOut } = useAuth();

    const [selectedProject, setSelectedProject] = useState<string | null>(
        startingProject
    );
    const [localization] = useLocalization();

    const projectsQuery = useQuery({
        queryKey: ['list-project-all'],
        queryFn: ({ signal }) =>
            listProjects({
                options: {
                    limit: 99999,
                },
                signal,
            }),
    });

    const handleProjectSave = (projectId: string) =>
        onProjectSelection(projectId);

    const projectsLength = projectsQuery.data?.payload?.length ?? -1;

    if (projectsLength === 1) {
        handleProjectSave(
            (projectsQuery.data?.payload[0] as Ether.CaseManager.Project)._id
        );
    }

    const pageLocale = localization.components.models.project.views.select;

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
            }}
        >
            <section
                style={{
                    border: '1px solid var(--alternative-grey-blacked-dark)',
                    borderRadius: '12px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '320px',
                    padding: '12px',
                }}
            >
                {projectsQuery.isLoading && (
                    <h3>{pageLocale.projectsLoading}</h3>
                )}
                {projectsQuery.isError && (
                    <h3>
                        {pageLocale.projectsError}:{' '}
                        {projectsQuery.error.message}
                    </h3>
                )}
                {projectsLength === 0 && (
                    <>
                        <h3 className='text-center'>
                            {pageLocale.noProjectsHeader}
                        </h3>
                        <span>{pageLocale.noProjectsDetails}</span>
                    </>
                )}
                {projectsLength === 1 && (
                    <p>If you're seeing this message, contact support.</p>
                )}
                {projectsLength > 1 && (
                    <>
                        <h2>{pageLocale.selectProject}</h2>
                        <Dropdown
                            label={localization.models.project.singular}
                            options={projectsQuery.data?.payload}
                            optionLabel='name'
                            optionValue='_id'
                            value={selectedProject}
                            onChange={(e) => setSelectedProject(e.target.value)}
                            wrapperStyle={{ width: '100%' }}
                            style={{ width: '100%' }}
                        />
                        <div
                            style={{
                                display: 'flex',
                                gap: '8px',
                                width: '100%',
                                marginTop: '8px',
                            }}
                        >
                            <Button
                                icon='pi pi-save'
                                label={
                                    localization.components.common.button.save
                                }
                                disabled={
                                    !selectedProject || projectsQuery.isLoading
                                }
                                style={{ width: '100%' }}
                                onClick={() => {
                                    if (!selectedProject) return;
                                    handleProjectSave(selectedProject);
                                }}
                            />
                            {startingProject && (
                                <Button
                                    icon='pi pi-times-circle'
                                    label={
                                        localization.components.common.button
                                            .cancel
                                    }
                                    style={{ width: '100%' }}
                                    onClick={() =>
                                        handleProjectSave(startingProject)
                                    }
                                />
                            )}
                        </div>
                    </>
                )}
            </section>
            <Button
                label={localization.components.common.button.signOut}
                style={{ position: 'absolute', top: '16px', right: '16px' }}
                onClick={signOut}
            />
        </div>
    );
};

export default SelectProject;
