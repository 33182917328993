import useQueryWithRefresh from 'hooks/helpers/useQueryWithRefresh/useQueryWithRefresh';
import { detailManyOperators } from 'services/ether/case-manager/operator';
import { DetailOperatorEP } from 'services/ether/case-manager/operator/types';

const useDetailOperators = (
    params: DetailOperatorEP.Params.Many,
    queryOptions?: {
        enabled?: boolean;
    }
) => {
    const queryKey = ['operator', 'detail', params];

    const query = useQueryWithRefresh({
        queryKey,
        queryFn: ({ signal }) =>
            detailManyOperators({
                ...params,
                signal,
            }),
        ...queryOptions,
    });

    return query;
};

export default useDetailOperators;
