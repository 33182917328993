import { useAuth } from 'hooks/context/useAuth';
import { Button } from 'primereact/button';
import { DateBadge } from 'components/ethercity-primereact';
import { Link } from 'react-router-dom';
import { useProjectMaybe } from 'hooks/context/project/useProject';
import { useLocalization } from 'hooks/context/useLocalization';
import { getUserDisplayName } from 'utils/user';
import CellGroup from 'components/display/CellGroup';
import LoadingMessage from 'components/misc/LoadingMessage';
import GoBackButton from 'components/misc/GoBackButton';
import { TableWithTabView } from 'components/display/ViewTablesWithTabs/types';
import { isUnblockOrderRespondable } from 'utils/unblockOrder';
import { useUnblockOrderRespond } from 'hooks/models/unblockOrder/useUnblockOrderRespond';
import UnblockOrderStatusBadge from '../UnblockOrderStatusBadge';
import { detailManyTargets } from 'services/ether/case-manager/targets';
import TargetsDataTable from 'components/models/Targets/TargetsDataTable';
import ViewTablesWithTabs from 'components/display/ViewTablesWithTabs';
import { detailManyDocuments } from 'services/ether/case-manager/documents';
import DocumentDataTable from 'components/models/Document/DocumentDataTable';
import ShowOperation from 'components/models/Operation/ShowOperation';
import useUpsertUnblockOrderDialog from 'hooks/models/unblockOrder/useUpsertUnblockOrderDialog';
import { detailOperatorCompany } from 'services/ether/case-manager/company';
import CompanyDataTable from 'components/models/Company/CompanyDataTable';
import useUpdateOperationDialog from 'hooks/dialogs/operation/useUpdateOperationDialog';

const DetailedUnblockOrderView: React.FC<{
    unblockOrder?: Ether.CaseManager.UnblockOrder.Detailed | null;
    isLoading: boolean;
    error: Error | null;

    onRefresh: () => void;
    hideBackButton?: boolean;
    hideViewAllUnblockOrderButton?: boolean;

    tokenData?: CaseManagerApp.TokenInfo;
}> = ({
    unblockOrder,
    isLoading,
    error,

    onRefresh,
    hideBackButton,
    hideViewAllUnblockOrderButton,

    tokenData,
}) => {
    const { user, permissions } = useAuth();
    const [localization] = useLocalization();

    const { UpsertDialog, showEdit } = useUpsertUnblockOrderDialog();

    const projectContext = useProjectMaybe();
    const project = tokenData?.project ?? projectContext;
    if (!project) throw new Error('missing project');

    const [respondUnblockOrder, respondStatus] = useUnblockOrderRespond({
        onRespond: onRefresh,
    });

    const {
        dialog: updateOperationDialog,
        show: showUpdateOperation,
    } = useUpdateOperationDialog();

    if (isLoading)
        return <LoadingMessage>{localization.common.loading}</LoadingMessage>;
    if (error) return <h2>{localization.validations.generic.unhandled}</h2>;
    if (!unblockOrder)
        return <h2>{localization.validations.generic.notFound}</h2>;

    const isRespondable = isUnblockOrderRespondable({
        permissions: permissions,
        unblockOrder: unblockOrder,
        userId: tokenData?.userId ?? user.data?._id,
    });
    const isEditable =
        unblockOrder.status === 'draft' && permissions.insertUnblockOrders;

    const blockOrderViews: TableWithTabView[] = [];
    if (permissions.viewTargets)
        blockOrderViews.push({
            label: localization.models.target.plural,
            fetchData: (params) =>
                detailManyTargets({
                    project_id: project._id,
                    options: {
                        ...params.options,
                        devFilters: {
                            unblock_order_id: unblockOrder._id,
                            get_evidence_parsed: false,
                        },
                    },
                    signal: params.signal,
                }),
            totalCount: unblockOrder.total_targets ?? -1,
            tableElement: (props) => (
                <TargetsDataTable
                    projectId={project._id}
                    showTags
                    refUnblockOrder={unblockOrder}
                    onValidation={onRefresh}
                    tokenData={tokenData}
                    {...props}
                />
            ),
            model: 'targets',
        });

    if (permissions.detailOperatorCompany)
        blockOrderViews.push({
            label: localization.models.operator.plural,
            fetchData: (params) =>
                detailOperatorCompany({
                    project_id: project._id,
                    options: {
                        ...params.options,
                        devFilters: {
                            unblock_order_id: unblockOrder._id,
                        },
                    },
                    signal: params.signal,
                }),
            totalCount: unblockOrder.total_operators ?? -1,
            tableElement: (props) => (
                <CompanyDataTable
                    {...props}
                    showOperatorCount
                    unblockOrder={unblockOrder}
                    showExpand='target'
                />
            ),
            overrideFilters: {},
            model: 'company',
        });

    if (permissions.viewDocuments)
        blockOrderViews.push({
            label: localization.models.document.plural,
            fetchData: (params) =>
                detailManyDocuments({
                    project_id: project._id,
                    options: {
                        ...params.options,
                        devFilters: {
                            unblock_order_id: unblockOrder._id,
                        },
                    },
                    signal: params.signal,
                }),
            totalCount: unblockOrder.total_documents ?? -1,
            tableElement: (props) => <DocumentDataTable {...props} />,
            model: 'document',
        });

    return (
        <section className='w-full'>
            <UpsertDialog />
            {updateOperationDialog}
            <div style={{ display: 'flex', marginTop: '8px' }}>
                {!hideBackButton && <GoBackButton />}
                <div
                    style={{ display: 'flex', marginLeft: 'auto', gap: '8px' }}
                >
                    {isRespondable && (
                        <>
                            <Button
                                icon='pi pi-check'
                                onClick={() =>
                                    respondUnblockOrder(unblockOrder, true)
                                }
                                severity='success'
                                label={
                                    localization.components.common.button
                                        .confirm
                                }
                                disabled={respondStatus.isLoading}
                            />
                        </>
                    )}
                    {isEditable && (
                        <Button
                            onClick={() => showEdit(unblockOrder._id)}
                            icon='pi pi-pencil'
                            label={localization.components.common.button.edit}
                        />
                    )}
                    {permissions.updateOperationOrder && (
                        <Button
                            label={
                                localization.components.models.operation.views
                                    .updateOrder.title
                            }
                            onClick={() =>
                                showUpdateOperation({
                                    data: {
                                        order_id: unblockOrder._id,
                                        order_type: 'unblock_order',
                                    },
                                    startingOperationId:
                                        unblockOrder.operation_id ?? null,
                                })
                            }
                        />
                    )}
                    {!hideViewAllUnblockOrderButton && (
                        <Link to='..'>
                            <Button
                                label={
                                    localization.components.models.authorization
                                        .button.viewAll
                                }
                            />
                        </Link>
                    )}
                </div>
            </div>
            <section className='grid grid-cols-3 gap-16 items-start my-5'>
                <div>
                    <span>{project.name}</span>
                    <h2 style={{ marginTop: '4px' }}>{unblockOrder.name}</h2>
                    <ShowOperation operation={unblockOrder.operation_data} />
                    {/* <div style={{ display: 'flex', gap: '8px' }}>
                        {blockOrder.tags.map((t) => (
                            <Badge
                                key={`${t.category}/${t.name}`}
                                value={
                                    t.name
                                        ? `${t.category}/${t.name}`
                                        : t.category
                                }
                            />
                        ))}
                    </div> */}
                    <div className='grid grid-cols-3 gap-2 text-sm font-bold mt-2'>
                        <CellGroup
                            element1={
                                <span>
                                    {localization.fields.blockOrder.status}:
                                </span>
                            }
                            element2={
                                <UnblockOrderStatusBadge
                                    unblockOrder={unblockOrder}
                                />
                            }
                            span={3}
                        />
                        <CellGroup
                            element1={
                                <span>
                                    {localization.fields.blockOrder.created}:
                                </span>
                            }
                            element2={
                                <DateBadge value={unblockOrder.created_at} />
                            }
                        />
                        <CellGroup
                            element1={
                                <span>
                                    {localization.fields.blockOrder.createdBy}:
                                </span>
                            }
                            element2={
                                <span>
                                    {getUserDisplayName(
                                        unblockOrder.created_by_data?.[0]
                                    )}
                                </span>
                            }
                            span={2}
                        />
                    </div>
                </div>
            </section>
            <ViewTablesWithTabs
                mainModel='unblock-order'
                uniqueQueryKey={unblockOrder._id}
                views={blockOrderViews}
            />
        </section>
    );
};

export default DetailedUnblockOrderView;
