import { useLocalization } from '../../context/useLocalization';
import { useCallback, useState } from 'react';
import { Button } from 'primereact/button';
import LightDialog from 'components/display/LightDialog';

type DialogData =
    | {
          targets: string[];
      }
    | {
          is_all: true;
      };

type DataWithRevert = { data: DialogData; onRevert: () => void };

const RevertDialog: React.FC<{
    data: DialogData | null;
    onHide: () => void;
    onRevert: () => void;
}> = ({ data, onRevert, onHide }) => {
    const [localization] = useLocalization();

    const hide = () => onHide();

    const confirm = () => {
        onRevert();
        hide();
    };

    if (!data) return null;

    return (
        <LightDialog
            footer={
                <>
                    <Button
                        label={localization.components.common.button.cancel}
                        severity='danger'
                        onClick={onHide}
                    />
                    <Button
                        label={localization.components.common.button.confirm}
                        severity='success'
                        onClick={confirm}
                    />
                </>
            }
            visible={true}
            header={
                localization.components.models.target.views.revertTarget.title
            }
            onHide={hide}
        >
            {'is_all' in data ? (
                <div className='flex flex-col items-center'>
                    <span>
                        {
                            localization.components.models.target.views
                                .revertTarget.allDescription
                        }
                    </span>
                </div>
            ) : (
                <>
                    <div className='flex flex-col items-center'>
                        <span>
                            {
                                localization.components.models.target.views
                                    .revertTarget.description
                            }
                        </span>
                    </div>
                    <div className='max-h-[40vh] overflow-scroll'>
                        <ul>
                            {data.targets.map((t) => (
                                <li key={t}>{t}</li>
                            ))}
                        </ul>
                    </div>
                </>
            )}
        </LightDialog>
    );
};

const useRemoveTargetValidationDialog = () => {
    const [visible, setVisible] = useState<null | DataWithRevert>(null);

    const show = (data: DataWithRevert) => setVisible(data);
    const hide = () => setVisible(null);

    const Dialog = useCallback(
        () =>
            visible && (
                <RevertDialog
                    data={visible.data}
                    onRevert={visible.onRevert}
                    onHide={hide}
                />
            ),
        [visible]
    );

    const confirmRevert = (data: DataWithRevert) => show(data);

    return {
        ConfirmRevertDialog: Dialog,
        confirmRevert,
    };
};

export default useRemoveTargetValidationDialog;
