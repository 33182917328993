import { useLocalization } from 'hooks/context/useLocalization';
import { useProject } from 'hooks/context/project/useProject';
import useListCompany from 'hooks/queries/company/useListCompany';
import SelectOneItem from 'components/form/SelectItems/SelectOneItem';
import { SelectOneItemProps } from 'components/form/SelectItems/types';
import { ListCompanyEP } from 'services/ether/case-manager/company/types';

const SelectOneCompany: React.FC<
    SelectOneItemProps<Ether.CaseManager.Company, ListCompanyEP.Filters.Options>
> = (props) => {
    const [localization] = useLocalization();
    const project = useProject();

    const query = useListCompany({
        project_id: project._id,
        options: { ...props.queryOptions, limit: 9999 },
    });

    return (
        <SelectOneItem
            title={localization.models.company.singular}
            placeholder={
                localization.components.models.company.form.selectOnePlaceholder
            }
            label={localization.models.company.singular}
            items={query.data?.payload}
            itemsLoading={query.isFetching}
            optionLabel='fantasy_name'
            {...props}
        />
    );
};

export default SelectOneCompany;
