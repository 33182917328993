import useQueryWithRefresh from 'hooks/helpers/useQueryWithRefresh/useQueryWithRefresh';
import { detailOneEvidence } from 'services/ether/case-manager/evidences';
import { DetailEvidenceEP } from 'services/ether/case-manager/evidences/types';

const useDetailOneEvidence = (
    params: DetailEvidenceEP.Params.One,
    queryOptions?: {
        enabled?: boolean;
    }
) => {
    const queryKey = ['evidence', 'detail', params];

    const query = useQueryWithRefresh<
        Ether.CaseManager.Evidence.Detailed | null,
        Error
    >({
        queryKey,
        queryFn: async ({ signal }) =>
            detailOneEvidence({
                ...params,
                signal,
            }),
        ...queryOptions,
    });

    return query;
};

export default useDetailOneEvidence;
