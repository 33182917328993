import NameIdAggregator from 'components/datatable/NameIdAggregator';
import { DateBadge, ObjectDisplayModal } from 'components/ethercity-primereact';
import { Column } from 'primereact/column';
import { DataTableProps } from 'primereact/datatable';
import { useAuth } from 'hooks/context/useAuth';
import { useRef, useState } from 'react';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { Button } from 'primereact/button';
import { useLocalization } from 'hooks/context/useLocalization';
import CMDataTable from 'components/datatable/CMDataTable';
import { CMDataTableProps } from 'components/datatable/CMDataTable/types';
import { getUserDisplayName } from 'utils/user';
import UnblockOrderStatusBadge from '../UnblockOrderStatusBadge';
import { getFilterData } from 'utils/datatable';
import { FilterMatchMode } from 'primereact/api';
import useUpsertUnblockOrderDialog from 'hooks/models/unblockOrder/useUpsertUnblockOrderDialog';
import UnblockOrderValidationStatusBadge from '../UnblockOrderValidationStatusBadge';

const UnblockOrderMenu: React.FC<{
    unblockOrder: Ether.CaseManager.UnblockOrder.Detailed;
    onShowDetail: (
        unblockOrder: Ether.CaseManager.UnblockOrder.Detailed
    ) => void;
    onShowEdit?: (id: string) => void;
}> = ({ unblockOrder, onShowDetail, onShowEdit }) => {
    const { permissions } = useAuth();
    const [localization] = useLocalization();

    const menuRef = useRef<Menu>(null);

    const menuItems: MenuItem[] = [
        ...(permissions.insertUnblockOrders &&
        unblockOrder.status === 'draft' &&
        onShowEdit
            ? [
                  {
                      id: 'edit',
                      label: localization.components.common.button.edit,
                      command: () => onShowEdit(unblockOrder._id),
                      icon: 'pi pi-pencil',
                  },
              ]
            : []),
        ...(permissions.debugUnblockOrders
            ? [
                  {
                      id: 'debug',
                      label: 'Detail data',
                      command: () => onShowDetail(unblockOrder),
                  },
              ]
            : []),
    ];
    if (menuItems.length <= 0) return null;
    return (
        <>
            <Menu popup ref={menuRef} model={menuItems} />
            <Button
                icon='pi pi-ellipsis-h'
                onClick={(e) => menuRef?.current?.toggle(e)}
            />
        </>
    );
};

type KeyInColumn =
    | keyof CaseManagerApp.ModelColumns['unblockOrder']
    | CaseManagerApp.DefaultNameColumn;

export const unblockOrderFields: { [key in KeyInColumn]: key } = {
    _cm_name_id: '_cm_name_id',
    status: 'status',
    created_at: 'created_at',
    created_by: 'created_by',
    targets_count: 'targets_count',
    validation_status : 'validation_status'
};

export const unblockOrderFilters = {
    [unblockOrderFields['_cm_name_id']]: getFilterData(),
    [unblockOrderFields['status']]: getFilterData(FilterMatchMode.EQUALS),
    [unblockOrderFields['created_at']]: getFilterData(FilterMatchMode.DATE_IS),
};

const UnblockOrderDataTable: React.FC<
    DataTableProps<Ether.CaseManager.UnblockOrder.Detailed[]> &
        Omit<CMDataTableProps, 'columnConfigName'>
> = ({ ...props }) => {
    const [localization] = useLocalization();
    const { user } = useAuth();

    const { UpsertDialog, showEdit } = useUpsertUnblockOrderDialog();

    const [viewUnblockOrderDialogOptions, setViewUnblockOrderDialogOptions] =
        useState<{
            mode: 'detail' | null;
            data?: Ether.CaseManager.UnblockOrder.Detailed;
        }>({
            mode: null,
        });

    const onUnblockOrderViewModalShow = (
        data: Ether.CaseManager.UnblockOrder.Detailed
    ) =>
        setViewUnblockOrderDialogOptions({
            mode: 'detail',
            data: data,
        });

    const onUnblockOrderViewModalHide = () =>
        setViewUnblockOrderDialogOptions({
            mode: null,
        });

    const sortable = 'sortField' in props;
    const datatableLocale = localization.components.models.unblockOrder.datatable;

    const hideStatusColumn = user.role === 'operator';
    const showValidationStatusColumn = user.role === 'operator';

    return (
        <>
            <ObjectDisplayModal
                header={viewUnblockOrderDialogOptions.data?.name}
                visible={viewUnblockOrderDialogOptions.mode === 'detail'}
                displayData={viewUnblockOrderDialogOptions.data}
                onHide={onUnblockOrderViewModalHide}
                sortKeys={false}
            />
            <UpsertDialog />
            <CMDataTable
                lazy
                emptyMessage={datatableLocale.empty}
                columnConfigName='unblockOrder'
                filterOptions={{
                    [unblockOrderFields['_cm_name_id']]: {
                        type: 'string',
                        placeholder: datatableLocale.selectName,
                    },
                    [unblockOrderFields['status']]: {
                        type: 'dropdown',
                        placeholder: datatableLocale.selectStatus,
                        label: localization.fields.unblockOrder.status,
                        selectOptions: [
                            {
                                label: localization.components.models.unblockOrder.badge
                                    .status.done,
                                value: 'done',
                            },
                            {
                                label: localization.components.models.unblockOrder.badge
                                    .status.draft,
                                value: 'draft',
                            },
                        ],
                    },
                    [unblockOrderFields['created_at']]: {
                        type: 'date',
                        label: localization.fields.unblockOrder.created,
                    },
                }}
                {...props}
            >
                <Column
                    field={unblockOrderFields['_cm_name_id']}
                    body={(rowData: Ether.CaseManager.UnblockOrder) => (
                        <NameIdAggregator
                            name={rowData.name}
                            id={rowData._id}
                            navigateTo={`/unblock-orders/${rowData._id}`}
                        />
                    )}
                />
                {!hideStatusColumn && (
                    <Column
                        field={unblockOrderFields['status']}
                        header={localization.fields.unblockOrder.status}
                        body={(
                            rowData: Ether.CaseManager.UnblockOrder.Detailed
                        ) => <UnblockOrderStatusBadge unblockOrder={rowData} />}
                    />
                )}
                {showValidationStatusColumn && (
                    <Column
                        field={unblockOrderFields['validation_status']}
                        header={localization.fields.blockOrder.validationStatus}
                        body={(
                            rowData: Ether.CaseManager.UnblockOrder.Detailed
                        ) => (
                            <UnblockOrderValidationStatusBadge
                                unblockOrder={rowData}
                            />
                        )}
                    />
                )}
                <Column
                    field={unblockOrderFields['targets_count']}
                    header={localization.models.target.plural}
                    body={(rowData: Ether.CaseManager.UnblockOrder.Detailed) =>
                        rowData.total_targets ?? rowData.targets_data?.length
                    }
                />
                <Column
                    field={unblockOrderFields['created_at']}
                    header={localization.fields.unblockOrder.created}
                    dataType='date'
                    body={(
                        rowData: Ether.CaseManager.UnblockOrder.Detailed
                    ) => <DateBadge value={rowData.created_at} />}
                    sortable={sortable}
                />
                <Column
                    field={unblockOrderFields['created_by']}
                    header={localization.fields.unblockOrder.createdBy}
                    body={(rowData: Ether.CaseManager.UnblockOrder.Detailed) =>
                        getUserDisplayName(rowData.created_by_data?.[0])
                    }
                />
                <Column
                    field='actions'
                    body={(
                        rowData: Ether.CaseManager.UnblockOrder.Detailed
                    ) => (
                        <UnblockOrderMenu
                            unblockOrder={rowData}
                            onShowDetail={onUnblockOrderViewModalShow}
                            onShowEdit={(id) => showEdit(id)}
                        />
                    )}
                />
            </CMDataTable>
        </>
    );
};

export default UnblockOrderDataTable;
