import api from 'services/ether/api';
import { fileDownloadBlobBase, listBase } from 'services/ether/base';
import {
    mapDevFilters,
    parseDataTableFilterMetaForAPI,
} from 'services/ether/utils';
import { DetailDocumentEP, UpsertDocumentEP } from './types';
import { DataTableSortMeta } from 'primereact/datatable';
import { ParamFiltersMeta } from 'services/ether/types';
import { checkFileSizeForEndpoint } from 'utils/errorHandler';
import _ from 'lodash';

const handleDataTableFilterMeta = ({
    filters,
    sort,
}: {
    filters?: ParamFiltersMeta;
    sort?: DataTableSortMeta | null;
}) => {
    return parseDataTableFilterMetaForAPI({
        filters,
        sort,
        nameField: 'file.name',
    });
};

const _detailDocuments = ({
    project_id,
    options,
    signal,
}: DetailDocumentEP.Params.Restricted) => {
    const devFilters = options?.devFilters ?? {};
    const devKeys: DetailDocumentEP.Filters.Map = {
        _id: ['_id', devFilters._id],
        authorization_id: ['authorization_id', devFilters.authorization_id],
        authorization_config_id: [
            'authorization_config_id',
            devFilters.authorization_config_id,
        ],
        block_order_id: ['block_order_id', devFilters.block_order_id],
        unblock_order_id: ['unblock_order_id', devFilters.unblock_order_id],
        additional_fields: [
            'additional_fields',
            devFilters.additional_fields?.join(','),
        ],
        category: ['category', devFilters.category],
    };
    const mappedFilters = mapDevFilters(devKeys);
    const filters = _.merge(
        {
            project_id,
        },
        options?.rawFilters,
        mappedFilters
    );
    return listBase<Ether.CaseManager.Document.Detailed[]>({
        endpoint: '/detail-document',
        handleParams: handleDataTableFilterMeta,
        options: {
            ...options,
            filters,
        },
        signal,
    });
};

export const detailOneDocument = async ({
    _id,
    project_id,
    signal,
}: DetailDocumentEP.Params.One) => {
    const { payload } = await _detailDocuments({
        project_id,
        options: {
            devFilters: {
                _id: _id,
                additional_fields: [
                    'created_by_data',
                    'authorization_configs_data',
                    'authorizations_data',
                    'block_orders_data',
                ],
            },
        },
        signal,
    });
    return payload[0] ?? null;
};

export const detailManyDocuments = ({
    project_id,
    options,
    signal,
}: DetailDocumentEP.Params.Many) => {
    return _detailDocuments({
        project_id,
        options: {
            ...options,
            devFilters: {
                ...options?.devFilters,
                additional_fields: ['created_by_data'],
            },
        },
        signal,
    });
};

export const downloadDocumentFile = (
    file_id: string,
    options?: {
        signal?: AbortSignal;
        token?: string;
    }
) => {
    return fileDownloadBlobBase({
        endpoint: '/download-file',
        params: {
            file_id,
            model: 'document',
            token: options?.token,
        },
        signal: options?.signal,
    });
};

export const upsertDocument = async (data: UpsertDocumentEP.UpsertData) => {
    const {
        project_id,
        authorization_flow_id,
        authorization_config_id,
        authorization_id,
        block_order_id,
        category,
        meta,
        status,
        files,
        signal,
    } = data;

    files.forEach((f) => {
        if ('size' in f) return;
        checkFileSizeForEndpoint({
            endpoint: 'upsert-document',
            size: f.file.size,
        });
    });

    const formData = new FormData();
    formData.append('project_id', project_id);
    formData.append('authorization_config_id', authorization_config_id);

    const document_files: {
        [key: string]:
            | {
                  name: string;
                  size: number;
              }
            | Ether.CaseManager.FileDetails;
    } = {};

    const custom_identifiers: { [key: string]: string } = {};
    files.forEach((fileItem, index) => {
        const key = `doc${index}`;
        const name = 'file' in fileItem ? fileItem.file.name : fileItem.name;
        const size = 'file' in fileItem ? fileItem.file.size : fileItem.size;
        if ('file' in fileItem) {
            formData.append(key, fileItem.file);
            custom_identifiers[key] = fileItem.custom_identifier;
        } else {
            formData.append(key, JSON.stringify(fileItem));
        }
        document_files[key] = { name, size };
    });
    formData.append('custom_identifiers', JSON.stringify(custom_identifiers));
    formData.append('document_files', JSON.stringify(document_files));

    if (authorization_flow_id)
        formData.append('authorization_flow_id', authorization_flow_id);
    if (authorization_id) formData.append('authorization_id', authorization_id);
    if (block_order_id) formData.append('block_order_id', block_order_id);
    if (category) formData.append('category', category);
    if (status) formData.append('status', status);
    if (meta) formData.append('meta', JSON.stringify(meta));

    if ('_id' in data) formData.append('_id', data._id);

    const result = await api.post<
        Ether.ApiResponse<UpsertDocumentEP.ApiResponse>
    >('/upsert-document', formData, { signal });

    return result.data.payload;
};
