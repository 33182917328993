import { useMemo, useState } from 'react';
import { useAuth } from 'hooks/context/useAuth';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import AuthorizationStatusBadge from '../AuthorizationStatusBadge';
import { DateBadge } from 'components/ethercity-primereact';
import { Link } from 'react-router-dom';
import {
    isAuthorizationFilesExportable,
    isAuthorizationRespondable,
} from 'utils/authorization';
import { useProject } from 'hooks/context/project/useProject';
import TargetsDataTable, {
    targetsFilters,
} from 'components/models/Targets/TargetsDataTable';
import { useLocalization } from 'hooks/context/useLocalization';
import { getUserDisplayName } from 'utils/user';
import CellGroup from 'components/display/CellGroup';
import useExportTarget from 'hooks/queries/target/useExportTarget';
import useExportAuthorizationFiles from 'hooks/queries/authorization/useExportAuthorizationFiles';
import { useAuthorizationRespond } from 'hooks/models/authorization/useAuthorizationRespond';
import { ChangeAuthorizationStatus } from 'services/ether/case-manager/authorizations/types';
import ViewTablesWithTabs from 'components/display/ViewTablesWithTabs';
import { detailManyTargets } from 'services/ether/case-manager/targets';
import LoadingMessage from 'components/misc/LoadingMessage';
import { detailManyEvidences } from 'services/ether/case-manager/evidences';
import EvidencesDatatable from 'components/models/Evidences/EvidencesDataTable';
import { detailManyDocuments } from 'services/ether/case-manager/documents';
import DocumentDataTable from 'components/models/Document/DocumentDataTable';
import GoBackButton from 'components/misc/GoBackButton';
import useInitDataTableState from 'hooks/helpers/useInitDataTableState';
import useDownloadEvidencesFromAuthorization from 'hooks/queries/evidence/useDownloadEvidencesFromAuthorization';
import useChangeAuthorizationStatus from 'hooks/mutations/authorization/useChangeAuthorizationStatus';
import { ProgressBar } from 'primereact/progressbar';
import useUpsertAuthorizationDialog from 'hooks/models/authorization/useUpsertAuthorizationDialog';
import { TableWithTabView } from 'components/display/ViewTablesWithTabs/types';
import InsertOfficialDocumentSimple from 'components/models/Document/InsertOfficialDocumentSimple';
import ShowDocumentsToSign from '../ShowDocumentsToSign';
import useDuplicateAuthorizationDialog from 'hooks/dialogs/authorization/useDuplicateAuthorizationDialog';
import useSendAuthorizationToAuthorityDialog from 'hooks/dialogs/authorization/useSendAuthorizationToAuthorityDialog';
import useInsertDocumentDialog from 'hooks/dialogs/document/useInsertDocumentDialog';
import useShowAuthorizationStats from 'hooks/dialogs/authorization/useShowAuthorizationStats';
import useUpsertBlockOrderDialog from 'hooks/models/blockOrder/useUpsertBlockOrderDialog';
import useChangeAuthorizationFlowDialog from 'hooks/dialogs/authorization/useChangeAuthorizationFlowDialog';
import useUpdateOperationDialog from 'hooks/dialogs/operation/useUpdateOperationDialog';
import ShowOperation from 'components/models/Operation/ShowOperation';
import { FilterMatchMode } from 'primereact/api';
import { getFilterData } from 'utils/datatable';

type AuthExpanded = {
    _id: string;
    name: string;
    evidences: Ether.CaseManager.Evidence.Detailed[];
    targets: Ether.CaseManager.Target.Detailed[];
    tags: Ether.CaseManager.Tag[];
    documents: Ether.CaseManager.Document[];
};

const DetailedAuthorizationView: React.FC<{
    authorization?: Ether.CaseManager.Authorization.Detailed | null;
    isLoading: boolean;
    error: Error | null;
    hideBackButton?: boolean;
    hideViewAllAuthorizationButton?: boolean;
}> = ({
    authorization,
    isLoading,
    error,

    hideBackButton,
    hideViewAllAuthorizationButton,
}) => {
    const { user, permissions, temporaryToken } = useAuth();
    const [localization] = useLocalization();

    const project = useProject();

    const {
        filters: targetFilters,
        setFilters: setTargetFilters,
        sort: targetSort,
        setSort: setTargetSort,
    } = useInitDataTableState();

    const { element: UpsertDialog, showEdit } = useUpsertAuthorizationDialog();
    const { dialog: authorizationStatsDialog, show: showAuthorizationStats } =
        useShowAuthorizationStats();
    const {
        UpsertDialog: UpsertBlockOrderDialog,
        showCreate: showCreateBlockOrder,
    } = useUpsertBlockOrderDialog();

    const {
        exportFile: exportTargets,
        isExporting: isExportingTargets,
        params: exportingTargetsParams,
    } = useExportTarget();
    const { exportFile: exportAuthFiles, isExporting: isExportingAuthFiles } =
        useExportAuthorizationFiles();
    const {
        downloadFile: downloadEvidences,
        isDownloading: isDownloadingEvidences,
    } = useDownloadEvidencesFromAuthorization({
        authorization_id: authorization?._id ?? '',
    });
    const {
        RespondDialog,
        respondAuthorization,
        isLoading: isRespondLoading,
    } = useAuthorizationRespond();
    const { dialog: duplicateDialog, show: showDuplicateDialog } =
        useDuplicateAuthorizationDialog();
    const { dialog: sendToSignDialog, show: showSendToSignDialog } =
        useSendAuthorizationToAuthorityDialog();
    const [showSendOfDocDialog, setShowSendOfDocDialog] = useState(false);
    const { dialog: insertDocumentDialog, showInsert: showInsertDocument } =
        useInsertDocumentDialog({
            type: 'document',
            props: {
                hideBlockOrder: true,
                requiredAuthorization: true,
            },
        });
    const {
        show: showChangeAuthorizationFlow,
        dialog: changeAuthorizationFlowDialog,
    } = useChangeAuthorizationFlowDialog();
    const { dialog: updateOperationDialog, show: showUpdateOperation } =
        useUpdateOperationDialog();

    const [updateStatus, { isLoading: isStatusUpdating }] =
        useChangeAuthorizationStatus();

    const handleSendForApproval = () => {
        if (!authorization) return;
        const status = 'pending';
        const finalData: ChangeAuthorizationStatus.Data = {
            authorization_id: authorization._id,
            status: status,
        };
        if (finalData.authorization_id !== null) updateStatus(finalData);
    };

    const userRole = user.role;
    const userId = user.data?._id;

    const canViewRejected = userRole !== 'authorizer-strict';
    const canViewPreRejected = userRole !== 'authorizer-strict';
    const canViewEvidences = true;
    const canChangeOperation =
        (userRole === 'authorizer-user'
            ? authorization?.status === 'pending' ||
              authorization?.status === 'draft'
            : true) && permissions.updateOperationAuthorization;

    const authViews: TableWithTabView[] = useMemo(
        () =>
            authorization
                ? [
                      {
                          label: localization.models.target.plural,
                          fetchData: (params) =>
                              detailManyTargets({
                                  project_id: project._id,
                                  options: {
                                      ...params.options,
                                      devFilters: {
                                          get_evidence_parsed: false,
                                          authorization_ids: authorization._id,
                                      },
                                  },
                                  signal: params.signal,
                              }),
                          totalCount: authorization.count_data.targets_count,
                          tableElement: (props) => (
                              <TargetsDataTable
                                  projectId={project._id}
                                  showTags
                                  refAuthorization={authorization}
                                  {...props}
                                  setFilters={(f) => {
                                      setTargetFilters(f);
                                      if (props.setFilters) props.setFilters(f);
                                  }}
                                  onSort={(s) => {
                                      setTargetSort({
                                          field: s.sortField,
                                          order: s.sortOrder,
                                      });
                                      if (props.onSort) props.onSort(s);
                                  }}
                              />
                          ),
                          model: 'targets',
                      },
                      ...(canViewRejected
                          ? [
                                {
                                    label: localization.models.target
                                        .manuallyRejectedTargets,
                                    fetchData: (params) =>
                                        detailManyTargets({
                                            project_id: project._id,
                                            options: {
                                                ...params.options,
                                                devFilters: {
                                                    removed_authorization_id:
                                                        authorization._id,
                                                    pre_reprove: false,
                                                    get_evidence_parsed: false,
                                                },
                                            },
                                            signal: params.signal,
                                        }),
                                    totalCount:
                                        authorization.count_data
                                            .manual_rejected_targets_count,
                                    tableElement: (props) => (
                                        <TargetsDataTable
                                            projectId={project._id}
                                            showTags
                                            refAuthorization={authorization}
                                            {...props}
                                        />
                                    ),
                                    model: 'targets',
                                } as TableWithTabView,
                            ]
                          : []),
                      ...(canViewPreRejected
                          ? [
                                {
                                    label: localization.models.target
                                        .autoRejectedTargets,
                                    fetchData: (params) =>
                                        detailManyTargets({
                                            project_id: project._id,
                                            options: {
                                                ...params.options,
                                                devFilters: {
                                                    removed_authorization_id:
                                                        authorization._id,
                                                    pre_reprove: true,
                                                    get_evidence_parsed: false,
                                                },
                                            },
                                            signal: params.signal,
                                        }),
                                    totalCount:
                                        authorization.count_data
                                            .pre_rejected_targets_count,
                                    tableElement: (props) => (
                                        <TargetsDataTable
                                            projectId={project._id}
                                            showTags
                                            refAuthorization={authorization}
                                            {...props}
                                        />
                                    ),
                                    model: 'targets',
                                    overrideFilters: {
                                        ...targetsFilters,
                                        omit_pre_reprove: getFilterData(
                                            FilterMatchMode.NOT_CONTAINS,
                                            [
                                                'same_operation',
                                                'recently_notified',
                                                'unreversible',
                                            ]
                                        ),
                                    },
                                } as TableWithTabView,
                            ]
                          : []),
                      ...(canViewEvidences
                          ? [
                                {
                                    label: localization.models.evidence.plural,
                                    fetchData: (params) =>
                                        detailManyEvidences({
                                            project_id: project._id,
                                            options: {
                                                ...params.options,
                                                devFilters: {
                                                    authorization_id:
                                                        authorization._id,
                                                },
                                            },
                                            signal: params.signal,
                                        }),
                                    totalCount:
                                        authorization.count_data
                                            .evidences_count,
                                    tableElement: (props) => (
                                        <EvidencesDatatable {...props} />
                                    ),
                                    model: 'evidences',
                                } as TableWithTabView,
                            ]
                          : []),
                      {
                          label: localization.models.document.plural,
                          fetchData: (params) =>
                              detailManyDocuments({
                                  project_id: project._id,
                                  options: {
                                      ...params.options,
                                      devFilters: {
                                          authorization_id: authorization._id,
                                      },
                                  },
                                  signal: params.signal,
                              }),
                          totalCount: authorization.count_data.documents_count,
                          tableElement: (props) => (
                              <DocumentDataTable {...props} />
                          ),
                          model: 'document',
                      },
                  ]
                : [],
        [
            authorization,
            localization,
            canViewRejected,
            canViewPreRejected,
            canViewEvidences,
            project._id,
            setTargetFilters,
            setTargetSort,
        ]
    );

    const tabViews = useMemo(
        () => (
            <ViewTablesWithTabs
                mainModel='authorization'
                onTabChange={() => setTargetFilters({})}
                uniqueQueryKey={authorization?._id ?? ''}
                views={authViews}
            />
        ),
        [authViews, authorization?._id, setTargetFilters]
    );

    if (isLoading)
        return <LoadingMessage>{localization.common.loading}</LoadingMessage>;
    if (error) return <h2>{localization.validations.generic.unhandled}</h2>;
    if (!authorization)
        return <h2>{localization.validations.generic.notFound}</h2>;

    const expandedAuthorization: AuthExpanded = {
        _id: authorization._id,
        name: authorization.name,
        evidences: [],
        targets: [],
        tags: [],
        documents: [],
    };
    const map: {
        evidences: { [key: string]: true };
        targets: { [key: string]: true };
        tags: { [key: string]: true };
    } = {
        evidences: {},
        targets: {},
        tags: {},
    };
    authorization.targets_data?.forEach((target) => {
        const detailedTarget = {
            ...target,
            authorizations_data: [authorization],
        } as Ether.CaseManager.Target.Detailed;
        if (!(target._id in map.targets)) {
            map.targets[target._id] = true;
            expandedAuthorization.targets.push(detailedTarget);
        }
        target.evidences_data?.forEach((evidence) => {
            if (!(evidence._id in map.evidences)) {
                map.evidences[evidence._id] = true;
                expandedAuthorization.evidences.push({
                    ...evidence,
                    targets_data: [detailedTarget],
                });
            }
        });
    });
    expandedAuthorization.documents = authorization.documents_data ?? [];
    const authorizationFlow = authorization.authorization_flows_data?.[0];

    const isRespondable = isAuthorizationRespondable({
        permissions: permissions,
        authorization: authorization,
        userId,
        userRole,
    });
    const canSendForApproval =
        permissions.insertAuthorizations && authorization.status === 'draft';
    const isEditable =
        authorization.status === 'draft' && permissions.editAuthorizations;
    const isFilesExportable = isAuthorizationFilesExportable({
        authorization: authorization,
        permissions: permissions,
        token: temporaryToken,
    });
    const isFinish = authorizationFlow?.user_approve_authorization?.find(
        (t) => t === user.role
    );
    const isSignable = !!authorization._user_fields?.can_send_document;

    const targetEnrichmentComplete =
        authorization.count_data.targets_processed_count != null &&
        authorization.count_data.targets_count != null &&
        authorization.count_data.targets_processed_count ===
            authorization.count_data.targets_count;

    const targetsLeftToValidate =
        authorization.count_data.targets_count != null &&
        authorization.count_data.targets_approved_count != null
            ? authorization.count_data.targets_count -
              authorization.count_data.targets_approved_count
            : undefined;
    const canAuthorizationProceed =
        targetsLeftToValidate != null && targetsLeftToValidate <= 0;

    const canSendToAuthority =
        permissions.sendAuthorizationToAuthoritySign &&
        authorization.authority_data?.status === 'available';

    const canInsertBlockOrder =
        permissions.insertBlockOrders &&
        authorization.status === 'approved' &&
        authorization.block_orders.length <= 0;

    const canChangeFlow = permissions.changeAuthorizationFlow;

    return (
        <section className='w-full'>
            <RespondDialog />
            <UpsertDialog />
            <UpsertBlockOrderDialog />
            {duplicateDialog}
            {sendToSignDialog}
            {insertDocumentDialog}
            {authorizationStatsDialog}
            {changeAuthorizationFlowDialog}
            {updateOperationDialog}
            {showSendOfDocDialog && (
                <InsertOfficialDocumentSimple
                    visible={true}
                    onHide={() => setShowSendOfDocDialog(false)}
                    authorization={authorization}
                />
            )}
            <div className='flex mt-2 items-start'>
                {!hideBackButton && <GoBackButton />}
                <div className='inline-flex ml-auto gap-2 max-w-[70%] flex-wrap justify-end'>
                    {canSendForApproval && (
                        <Button
                            label={
                                isFinish
                                    ? localization.components.models
                                          .authorization.button.finish
                                    : localization.components.models
                                          .authorization.button.sendForApproval
                            }
                            severity='success'
                            disabled={!canAuthorizationProceed}
                            onClick={handleSendForApproval}
                            loading={isStatusUpdating}
                        />
                    )}
                    {isRespondable && (
                        <Button
                            icon='pi pi-check'
                            onClick={() =>
                                respondAuthorization(authorization, true)
                            }
                            severity='success'
                            label={
                                !userRole?.startsWith('operator')
                                    ? localization.components.common.button
                                          .approve
                                    : localization.components.common.button
                                          .confirm
                            }
                            disabled={
                                isRespondLoading || !canAuthorizationProceed
                            }
                        />
                    )}
                    {isSignable && (
                        <Button
                            onClick={() => setShowSendOfDocDialog(true)}
                            label={
                                localization.components.models.authorization
                                    .button.sendSignedDoc
                            }
                            severity='danger'
                            disabled={!canAuthorizationProceed}
                        />
                    )}
                    {canSendToAuthority && (
                        <Button
                            label={
                                localization.components.models.authorization
                                    .button.sendToAuthority
                            }
                            onClick={() =>
                                showSendToSignDialog({
                                    authorization: authorization,
                                })
                            }
                            icon='pi pi-envelope'
                        />
                    )}
                    {canChangeFlow && authorization && (
                        <Button
                            label={
                                localization.components.models.authorization
                                    .button.changeFlow
                            }
                            onClick={() =>
                                showChangeAuthorizationFlow({
                                    authorizationConfigId:
                                        authorization.authorization_config_id,
                                    authorizationId: authorization._id,
                                    authorizationName: authorization.name,
                                    startingFlow:
                                        authorization.authorization_flow_id,
                                })
                            }
                            icon='pi pi-pencil'
                        />
                    )}
                    {canChangeOperation && (
                        <Button
                            label={
                                localization.components.models.operation.views
                                    .updateOrder.title
                            }
                            onClick={() =>
                                showUpdateOperation({
                                    data: {
                                        authorization_id: authorization._id,
                                    },
                                    startingOperationId:
                                        authorization.operation_id ?? null,
                                })
                            }
                        />
                    )}
                    {canInsertBlockOrder && (
                        <Button
                            label={
                                localization.components.models.blockOrder.button
                                    .new
                            }
                            onClick={() => showCreateBlockOrder(authorization)}
                            icon='pi pi-plus'
                        />
                    )}
                    {permissions.duplicateAuthorization && (
                        <Button
                            label={
                                localization.components.models.authorization
                                    .button.duplicateAuthorization
                            }
                            onClick={() =>
                                showDuplicateDialog({
                                    authorization: authorization,
                                })
                            }
                            icon='pi pi-clone'
                        />
                    )}
                    {isEditable && (
                        <Button
                            onClick={() => showEdit(authorization)}
                            icon='pi pi-pencil'
                            label={localization.components.common.button.edit}
                        />
                    )}
                    {permissions.exportTargets && (
                        <>
                            <Button
                                icon='pi pi-download'
                                onClick={() =>
                                    exportTargets({
                                        params: {
                                            fields: {
                                                authorizationId:
                                                    authorization._id,
                                                isRemoved: false,
                                            },
                                            options: {
                                                rawFilters: targetFilters,
                                                sort: targetSort,
                                            },
                                        },
                                    })
                                }
                                label={
                                    localization.components.models.target.button
                                        .export
                                }
                                loading={
                                    !!(
                                        isExportingTargets &&
                                        exportingTargetsParams &&
                                        'isRemoved' in exportingTargetsParams &&
                                        !exportingTargetsParams.isRemoved
                                    )
                                }
                                disabled={isExportingTargets}
                            />
                            <Button
                                icon='pi pi-download'
                                onClick={() =>
                                    exportTargets({
                                        params: {
                                            fields: {
                                                authorizationId:
                                                    authorization._id,
                                                isRemoved: true,
                                            },
                                            options: {
                                                rawFilters: targetFilters,
                                                sort: targetSort,
                                            },
                                        },
                                    })
                                }
                                label={
                                    localization.components.models.target.button
                                        .exportRejected
                                }
                                loading={
                                    !!(
                                        isExportingTargets &&
                                        exportingTargetsParams &&
                                        'isRemoved' in exportingTargetsParams &&
                                        exportingTargetsParams.isRemoved
                                    )
                                }
                                disabled={isExportingTargets}
                            />
                        </>
                    )}
                    {isFilesExportable && (
                        <Button
                            icon='pi pi-download'
                            onClick={() =>
                                exportAuthFiles({
                                    filename: null,
                                    authorization_id: authorization?._id ?? '',
                                })
                            }
                            label={
                                localization.components.common.button
                                    .exportFiles
                            }
                            loading={isExportingAuthFiles}
                        />
                    )}
                    {permissions.downloadEvidencesAuthorization &&
                        (authorization.count_data.evidences_count ?? 0) > 0 && (
                            <Button
                                icon='pi pi-download'
                                onClick={downloadEvidences}
                                label={
                                    localization.components.models.evidence
                                        .button.downloadMany
                                }
                                loading={isDownloadingEvidences}
                            />
                        )}
                    {permissions.getAuthorizationStats && (
                        <Button
                            icon='pi pi-chart-bar'
                            label={localization.components.common.stats.header}
                            onClick={() =>
                                showAuthorizationStats({
                                    id: authorization._id,
                                    name: authorization.name,
                                })
                            }
                        />
                    )}
                    {!hideViewAllAuthorizationButton &&
                        userRole !== 'authorizer-strict' && (
                            <Link to='/authorizations'>
                                <Button
                                    label={
                                        localization.components.models
                                            .authorization.button.viewAll
                                    }
                                    icon='pi pi-list'
                                />
                            </Link>
                        )}
                    {userRole === 'admin' && (
                        <Button
                            label={
                                localization.components.models.document.button
                                    .add
                            }
                            onClick={() =>
                                showInsertDocument({
                                    authorizationConfigId:
                                        authorization.authorization_config_id,
                                    authorizationId: authorization._id,
                                })
                            }
                            icon='pi pi-file-plus'
                        />
                    )}
                    {(userRole === 'admin' || userRole === 'analyst') &&
                        authorization.created_by === user.data?._id &&
                        authorization.status !== 'draft' && (
                            <Button
                                label={
                                    localization.components.models.authorization
                                        .button.revertToDraft
                                }
                                onClick={() =>
                                    updateStatus({
                                        authorization_id: authorization._id,
                                        status: 'draft',
                                    })
                                }
                                loading={isStatusUpdating}
                                icon='pi pi-undo'
                            />
                        )}
                </div>
            </div>
            <section className='grid grid-cols-2 gap-16 items-start my-5'>
                <div>
                    <div className='flex flex-row gap-8'>
                        <div>
                            <span>{project.name}</span>
                            <h2 className='mt-1'>
                                {expandedAuthorization.name}
                            </h2>
                            <ShowOperation
                                operation={authorization.operation_data}
                            />
                        </div>
                    </div>
                    <div className='flex flex-row gap-8'>
                        <div>
                            <span>
                                {
                                    localization.models.authorizationConfig
                                        .singular
                                }
                            </span>
                            <h3 className='mt-1'>
                                {
                                    authorization
                                        .authorization_configs_data?.[0]?.name
                                }
                            </h3>
                        </div>
                        <div>
                            <span>
                                {localization.models.authorizationFlow.singular}
                            </span>
                            <h3 className='mt-1'>{authorizationFlow?.name}</h3>
                        </div>
                    </div>
                    <div className='flex gap-4'>
                        {expandedAuthorization.tags.map((t) => (
                            <Badge
                                key={`${t.category}/${t.name}`}
                                value={
                                    t.name
                                        ? `${t.category}/${t.name}`
                                        : t.category
                                }
                            />
                        ))}
                    </div>
                    <div
                        style={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(4, 1fr)',
                            gap: '16px',
                            fontSize: '14px',
                            fontWeight: 'bold',
                            marginTop: '16px',
                        }}
                    >
                        <CellGroup
                            element1={
                                <span>
                                    {localization.fields.authorization.status}:
                                </span>
                            }
                            element2={
                                <AuthorizationStatusBadge
                                    authorization={authorization}
                                />
                            }
                            span={2}
                        />
                        <CellGroup
                            element1={
                                <span>
                                    {
                                        localization.fields.authorization
                                            .registeredAt
                                    }
                                    :
                                </span>
                            }
                            element2={
                                authorization.registered_at ? (
                                    <DateBadge
                                        value={authorization.registered_at}
                                    />
                                ) : (
                                    <span>-</span>
                                )
                            }
                        />
                        <CellGroup
                            element1={
                                <span>
                                    {localization.fields.authorization.created}:
                                </span>
                            }
                            element2={
                                <DateBadge value={authorization.created_at} />
                            }
                        />
                        <CellGroup
                            element1={
                                <span>
                                    {
                                        localization.fields.authorization
                                            .createdBy
                                    }
                                    :
                                </span>
                            }
                            element2={
                                <span>
                                    {getUserDisplayName(
                                        authorization.created_by_data?.[0]
                                    )}
                                </span>
                            }
                        />
                        <CellGroup
                            element1={
                                <span>
                                    {localization.fields.authorization.approval}
                                    :
                                </span>
                            }
                            element2={
                                authorization.approved_at ? (
                                    <DateBadge
                                        value={authorization.approved_at}
                                    />
                                ) : (
                                    <span>-</span>
                                )
                            }
                        />
                        <CellGroup
                            element1={
                                <span>
                                    {
                                        localization.fields.authorization
                                            .approvedBy
                                    }
                                    :
                                </span>
                            }
                            element2={
                                <span>
                                    {(() => {
                                        const approvedBy =
                                            authorization.authorizer_associations_data?.find(
                                                (aa) => !!aa.response?.accepted
                                            );
                                        if (!approvedBy) return '-';
                                        return getUserDisplayName(
                                            approvedBy?.users_data?.[0]
                                        );
                                    })()}
                                </span>
                            }
                        />
                        <CellGroup
                            element1={
                                <span>
                                    {
                                        localization.fields.authorization
                                            .submitted
                                    }
                                    :
                                </span>
                            }
                            element2={
                                authorization.last_sent_to_authorizers_at ? (
                                    <DateBadge
                                        value={
                                            authorization.last_sent_to_authorizers_at
                                        }
                                    />
                                ) : (
                                    <span>-</span>
                                )
                            }
                        />
                    </div>
                    <ShowDocumentsToSign authorization={authorization} />
                </div>
                {(isRespondable || canSendForApproval || isSignable) &&
                    !canAuthorizationProceed && (
                        <div className='flex flex-col gap-1'>
                            <strong className='ml-auto text-lg'>
                                {localization.components.models.authorization.views.detail.targetsLefToValidate.replace(
                                    '{count}',
                                    (targetsLeftToValidate ?? '-').toString()
                                )}
                            </strong>
                            <span className='ml-auto'>
                                {
                                    localization.components.models.authorization
                                        .views.detail.allTargetsValidated
                                }
                            </span>
                        </div>
                    )}
            </section>
            {!!authorizationFlow?.insert_targets_from_info && (
                <div className='w-[20%] mb-5'>
                    <strong>
                        {
                            localization.components.models.authorization
                                .targetCreationProcessProgress.title
                        }{' '}
                        (
                        {`${
                            authorization.count_data.targets_processed_count ??
                            '-'
                        } /
                            ${authorization.count_data.targets_count ?? '-'}`}
                        )
                    </strong>
                    <ProgressBar
                        value={
                            ((authorization.count_data
                                .targets_processed_count ?? 0) /
                                (authorization.count_data.targets_count ?? 1)) *
                            100
                        }
                        displayValueTemplate={() =>
                            localization.components.models.authorization
                                .targetCreationProcessProgress.complete
                        }
                        showValue={targetEnrichmentComplete}
                    />
                </div>
            )}
            {tabViews}
        </section>
    );
};

export default DetailedAuthorizationView;
