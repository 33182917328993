import { DataTableSortMeta } from 'primereact/datatable';
import {
    ListAuthorizationConfigEP,
    DetailAuthorizationConfigEP,
    AuthorizationSharedConfig,
} from './types';
import {
    mapDevFilters,
    parseDataTableFilterMetaForAPI,
} from 'services/ether/utils';
import { listBase } from 'services/ether/base';
import { ParamFiltersMeta } from 'services/ether/types';
import _ from 'lodash';

const handleDataTableFilterMeta = ({
    filters,
    sort,
}: {
    filters?: ParamFiltersMeta;
    sort?: DataTableSortMeta | null;
}) => {
    const parsed = parseDataTableFilterMetaForAPI({
        filters,
        sort,
        idFields: ['authorization_flow_ids', 'authorization_flow_ids|in'],
    });
    return parsed;
};

const _listAuthorizationConfig = ({
    project_id,
    options,
    signal,
}: ListAuthorizationConfigEP.Params.Many) => {
    const devFilters = options?.devFilters ?? {};
    const devKeys: ListAuthorizationConfigEP.Filters.Map = {
        _id: ['_id', devFilters._id],
        authorization_flow_ids: [
            'authorization_flow_ids',
            devFilters.authorization_flow_ids,
        ],
    };
    const mappedFilters = mapDevFilters(devKeys);
    const filters = _.merge(
        {
            project_id,
        },
        options?.rawFilters,
        mappedFilters
    );
    return listBase<Ether.CaseManager.AuthorizationConfig[]>({
        endpoint: '/list-authorization-config',
        handleParams: handleDataTableFilterMeta,
        options: {
            ...options,
            filters,
        },
        signal,
    });
};

export const getOneAuthorizationConfig = async ({
    _id,
    project_id,
    signal,
}: ListAuthorizationConfigEP.Params.One) => {
    const { payload } = await _listAuthorizationConfig({
        project_id,
        options: {
            devFilters: {
                _id,
            },
        },
        signal,
    });
    return payload[0] ?? null;
};

export const listManyAuthorizationConfig = async ({
    project_id,
    options,
    signal,
}: ListAuthorizationConfigEP.Params.Many) => {
    const data = await _listAuthorizationConfig({
        project_id,
        options,
        signal,
    });
    return data;
};

export const _detailAuthorizationConfig = ({
    project_id,
    options,
    signal,
}: DetailAuthorizationConfigEP.Params.Restricted) => {
    const devFilters = options?.devFilters ?? {};
    const devKeys: DetailAuthorizationConfigEP.Filters.Map = {
        _id: ['_id', devFilters._id],
        authorization_flow_ids: [
            'authorization_flow_ids',
            devFilters.authorization_flow_ids,
        ],
        additional_fields: [
            'additional_fields',
            devFilters.additional_fields?.join(','),
        ],
    };
    const mappedFilters = mapDevFilters(devKeys);
    const filters = _.merge({}, options?.rawFilters, mappedFilters, {
        project_id,
    });
    return listBase<Ether.CaseManager.AuthorizationConfig.Detailed[]>({
        endpoint: '/detail-authorization-config',
        handleParams: handleDataTableFilterMeta,
        options: {
            ...options,
            filters,
        },
        signal,
    });
};

export const detailManyAuthorizationConfig = async ({
    project_id,
    options,
    signal,
}: DetailAuthorizationConfigEP.Params.Many) => {
    const additionalFields: AuthorizationSharedConfig.AdditionalFields[] = [
        'total_documents',
        'total_targets',
    ];
    const authConfigs = await _detailAuthorizationConfig({
        project_id,
        options: {
            ...options,
            devFilters: {
                ...options?.devFilters,
                additional_fields: additionalFields,
            },
        },
        signal,
    });
    return authConfigs;
};

export const detailOneAuthorizationConfig = async ({
    _id,
    options,
    project_id,
    signal,
}: DetailAuthorizationConfigEP.Params.One) => {
    const additionalFields: AuthorizationSharedConfig.AdditionalFields[] = [
        'total_documents',
        'total_targets',
        'total_official_documents',
        'total_operators',
    ];
    if (options?.includeFlows)
        additionalFields.push('authorization_flows_data');
    const { payload } = await _detailAuthorizationConfig({
        project_id,
        options: {
            devFilters: {
                _id,
                additional_fields: additionalFields,
            },
        },
        signal,
    });
    return payload[0] ?? null;
};
