import { useProject } from 'hooks/context/project/useProject';
import TargetsDataTable, {
    targetsFilters,
} from 'components/models/Targets/TargetsDataTable';
import CMBreadCrumb from 'components/page/CMBreadCrumb';
import { useLocalization } from 'hooks/context/useLocalization';
import useDetailTarget from 'hooks/queries/target/useDetailTarget';
import GoBackButton from 'components/misc/GoBackButton';
import useInitDataTableState from 'hooks/helpers/useInitDataTableState';
import CacheControl from 'controller/cache/cacheController';

const ListTargets = () => {
    const project = useProject();
    const [localization] = useLocalization();

    const { filters, setFilters, pageOptions, setPageOptions, sort, setSort } =
        useInitDataTableState({
            filters: targetsFilters,
            pageOptions: {
                startingRow:
                    CacheControl.UserConfig.get()['paginatorRows']?.targets,
            },
        });

    const targetsQuery = useDetailTarget({
        project_id: project._id,
        options: {
            rawFilters: filters,
            sort,
            limit: pageOptions.rows,
            offset: (pageOptions.page - 1) * pageOptions.rows,
        },
    });

    return (
        <section>
            <CMBreadCrumb projectLabel={project.name} />
            <GoBackButton />
            <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                <h2>
                    {project.name} - {localization.models.target.plural}
                </h2>
            </div>
            <TargetsDataTable
                projectId={project._id}
                showTags={false}
                loading={targetsQuery.isFetching}
                value={targetsQuery.data?.payload}
                filters={filters}
                setFilters={(e) => setFilters(e)}
                paginatorProps={{
                    page: pageOptions.page,
                    rows: pageOptions.rows,
                    onPageChange: (options) => setPageOptions(options),
                    disableNext:
                        (targetsQuery.data?.payload.length ?? 9999) < pageOptions.rows,
                    onRefresh: targetsQuery.refresh,
                }}
                sortField={sort?.field}
                sortOrder={sort?.order}
                onSort={(e) =>
                    setSort({
                        field: e.sortField,
                        order: e.sortOrder,
                    })
                }
            />
        </section>
    );
};

export default ListTargets;
